// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.primary {
  background: var(--ion-color-primary-shade) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/entity_reference/seat_reservation/entity_reference_seat_reservation.component.scss"],"names":[],"mappings":"AAAA;EACI,qDAAA;AACJ","sourcesContent":["tr.primary {\n    background: var(--ion-color-primary-shade) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
