import { Injectable } from "@angular/core";
import { getAnalytics } from "firebase/analytics";
import { collectionData, collection, addDoc, updateDoc, Firestore, setDoc, doc, deleteDoc, getDocs, CollectionReference } from '@angular/fire/firestore';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ToastController } from "@ionic/angular";
import { indexedDBLocalPersistence, initializeAuth, getAuth, Auth } from "@angular/fire/auth";
import { Capacitor } from "@capacitor/core";
import { getApp } from "@angular/fire/app";

@Injectable({
    providedIn: 'root'
  })
  export class FirebaseService {

    auth: Auth;
    collection: CollectionReference;
    tables: CollectionReference;

    constructor(private aFirestore: AngularFirestore, private toastCtrl: ToastController, private firestore: Firestore) {
      this.init();
    }

    init() {        
        const app = getApp();
        try {
          this.auth = getAuth(app);
          this.collection = collection(this.firestore, 'table_assignments');
          this.tables = collection(this.firestore, 'tables');
          console.log(this.auth);
        } catch (error) {
          if (Capacitor.isNativePlatform()) {
            this.auth = initializeAuth(app, {
              persistence: indexedDBLocalPersistence
            });
          }
        }
        const analytics = getAnalytics(app);
    }

    getData() {
      return collectionData(this.collection);
    }

    getTableData() {
      return collectionData(this.tables);
    }

    getTable(id: string) {
      const docRef = doc(this.firestore, `tables/${id}`);
      return docRef;
    }

    async updateTableDocument(id: string, tableNumber: number, country: string, region: string) {
      try {
        const docRef = this.getTable(id);
        await updateDoc(docRef, { tableNumber, country, region });
        console.log(`Updated ${id} to Country: ${country}, Region: ${region} Table: ${tableNumber}`);
        await this.showToast('Updated', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    }

    async setTableDocument(data: {id: string, country: string, region: string, tableNumber: number}, id: string) {
      try {
        const docRef = this.getTable(id);
        await setDoc(docRef, data);
        console.log("Document created with ID:", id);
        await this.showToast('Success', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    }

    getDoc(id: string) {
      const docRef = doc(this.firestore, `table_assignments/${id}`);
      return docRef;
    }

    async deleteDocument(id: string) {
      try {
        const docRef = this.getDoc(id);
        await deleteDoc(docRef);
        console.log("Document deleted with ID:", id);
        await this.showToast('Deleted', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    } 

    async addDocument(formData: any) {
      try {
        await addDoc(this.collection, formData);
        console.log('Form data submitted successfully');
        await this.showToast('Success', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    }

    async updateCheckedIn(id: string, checked_in: string) {
      try {
        const docRef = this.getDoc(id);
        await updateDoc(docRef, { checked_in });
        console.log(`Updated ${id} to Checked In: ${checked_in}`);
        await this.showToast('Updated', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    }

    async updateDocument(id: string, table: string, seat: string) {
      try {
        const docRef = this.getDoc(id);
        await updateDoc(docRef, { table: table, seat_number: seat });
        console.log(`Updated ${id} to Table: ${table}, Seat: ${seat}`);
        await this.showToast('Updated', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    }

    async setDocument(data: any, id: string) {
      try {
        const docRef = this.getDoc(id);
        await setDoc(docRef, data);
        console.log("Document created with ID:", id);
        await this.showToast('Success', 'primary');
        return true;
      } catch (error) {
        console.error('Error submitting form data:', error);
        await this.showToast('Error', 'danger');
        return false;
      }
    }

    private async showToast(header: string, color: string) {
      const toast = await this.toastCtrl.create({
        header,
        color,
        duration: 1200,
        position: 'middle'
      });
      await toast.present();
    }

    async onSubmit(formData: any) {
      // Submit the form data to Firebase Firestore
      return this.aFirestore.collection('table_assignments').add(formData)
        .then(async () => {
          console.log('Form data submitted successfully');
          const toast = await this.toastCtrl.create({
            header: 'Success',
            color: 'primary',
            duration: 1200,
            position: 'middle'
          });
          await toast.present();
          return true;
        })
        .catch(async (error) => {
          console.error('Error submitting form data:', error);
          const toast = await this.toastCtrl.create({
            header: 'Error',
            color: 'danger',
            duration: 1200,
            position: 'middle'
          });
          await toast.present();
          return false;
        });
    }
  

  }