import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: 'expand',
  standalone: true
})
export class ExpandPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(entity: {key: any, value: any}): SafeHtml {
    if (entity.key === 'events' && Object.prototype.toString.call(entity.value) === '[object Array]') {
      let titles = '';
      for (let i = 0; i < entity.value.length; i++) {
        if (i === entity.value.length) {
          titles += `${entity.value[i].title}`;
        } else {
          titles += `${entity.value[i].title}<br>`;
        }
      }
      return titles;
    }
    if (typeof entity.value === 'object' && entity !== null && 'src' in entity.value) {
      return this.sanitizer.bypassSecurityTrustHtml(`<ion-img src="${entity.value.src}"></ion-img>`);
    } else {
      return entity.value;
    }
  }

}