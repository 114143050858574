import { Injectable } from '@angular/core';
import { QRCode } from './models';
// import { BarcodeScanner as BarcodeScanning, LensFacing, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { BarcodeScanner, CameraDirection } from '@capacitor-community/barcode-scanner';
import { BarcodeScanner as BScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Platform } from '@ionic/angular';

// const mlkitScanner = BarcodeScanning;
const communityScanner = BarcodeScanner;

@Injectable({
  providedIn: 'root'
})
export class QrCodeScanService {
    swipeHandler: any;
    scanBuffer = '';
    barcodeScanner = new BScanner();
    defaultFrontCamera: boolean = true;

    constructor(
      private platform: Platform
    ) {}

    addListeners() {
        document.addEventListener('keypress', (evt: KeyboardEvent) => {
            this.scan(evt);
        });
    }

    deleteListeners() {
        document.removeEventListener('keypress', this.swipeHandler, true);
        console.log('Done!');
    }

    async simpleScan(event) {
        console.log(event);
        this.scanBuffer += String.fromCharCode(event.keyCode);

        await new Promise<void>(resolve => setTimeout(()=>resolve(), 300));
        if (this.scanBuffer !== null && this.scanBuffer.length > 8) {
            const barcodeData = this.scanBuffer;
            this.scanBuffer = '';
            return barcodeData;
        }
    }

async scan(event) {
    // Setup the patterns to look for in the scan
    const entity_full_regex = /(?:\%id=(\d+)\^lineitemid=(\d+)\^orderid=(\d+)\^uid=(\d+))\^group=(\d+)\?/;
    const entity_regex = /(?:\%id=(\d+)\^)/;

    // Add the key to the buffer
    this.scanBuffer += String.fromCharCode(event.keyCode);
    // Set timeout to make sure we have all the data from the buffer
    await new Promise<void>(resolve => setTimeout(()=>resolve(), 600));
    if (this.scanBuffer !== null && this.scanBuffer.match(entity_regex)) {

        // Setup the return
        const res = await this.parseScan(this.scanBuffer);
        this.scanBuffer = '';
        return res;
    } else {
        this.scanBuffer = '';
    }
  }

  async parseScan(data: string) {
    // Setup the patterns to look for in the scan
    const entity_full_regex = /(?:\%id=(\d+)\^lineitemid=(\d+)\^orderid=(\d+)\^uid=(\d+))\^group=(\d+)\?/;
    const entity_regex = /(?:\%id=(\d+)\^)/;
    const line_item_regex = /(?:\^lineitemid=(\d+)\^)/;
    const order_id_regex = /(?:\^orderid=(\d+)\^)/;
    const uid_regex = /(?:\^uid=(\d+)\^)/;
    const group_regex = /(?:\^group=(\d+)\?)/;

    if (data !== null && data.match(entity_regex)) {
        const entityMatch = entity_regex.exec(data);
        const lineItemMatch = line_item_regex.exec(data);
        const orderIDMatch = order_id_regex.exec(data);
        const uidMatch = uid_regex.exec(data);
        const groupMatch = group_regex.exec(data);

        // Setup the return
        const qrCode: QRCode = {id: +entityMatch[1]};
        if (lineItemMatch) {
            qrCode.line_item_id = +lineItemMatch[1];
        }
        if (orderIDMatch) {
            qrCode.order_id = +orderIDMatch[1];
        }
        if (uidMatch) {
            qrCode.uid = +uidMatch[1];
        }
        if (groupMatch) {
            qrCode.group_manager = +groupMatch[1];
        } else {
            qrCode.group_manager = +'0000';
        }
        qrCode.qr_code_value = data;
        return qrCode;
    } else {
        return;
    }
  }

  // async startScanNew() {
  //   document.querySelector('body')?.classList.add('barcode-scanner-active');
  //   await mlkitScanner.addListener('barcodeScanned', async result => {
  //     if (result.barcode) {
  //       return result.barcode;
  //     }
  //   });
  // }

  // async stopScanNew() {
  //   document.querySelector('body')?.classList.remove('barcode-scanner-active');
  //   await mlkitScanner.removeAllListeners();
  //   await mlkitScanner.stopScan();
  // }

  // async startBarcodeScanning() {
  //   // Check camera permission
  //   // This is just a simple example, check out the better checks below
  //   await mlkitScanner.checkPermissions();
    
  //   document.querySelector('body')?.classList.add('barcode-scanner-active');
    
  //   await mlkitScanner.startScan({lensFacing: LensFacing.Front}); // start scanning and wait for a result
  
  //   return await mlkitScanner.addListener('barcodeScanned', async result => {
  //     if (result.barcode) {
  //       return result.barcode;
  //     }
  //   });
  // }

  // async scanQRCodes() {
  //   const { barcodes } = await mlkitScanner.scan({
  //     formats: [BarcodeFormat.QrCode],
  //   });
  //   return barcodes;
  // }

  checkScan() {
    if (this.platform.is('capacitor')) {
      this.scanQRCode();
    } else {
      this.startBarcodeScan();
    }
  }

  async startBarcodeScan(cameraDirection: CameraDirection = 'front') {
    console.log(cameraDirection);
    // Check camera permission
    // This is just a simple example, check out the better checks below
    await communityScanner.checkPermission({ force: true });
  
    // make background of WebView transparent
    // note: if you are using ionic this might not be enough, check below
    communityScanner.hideBackground();
  
    const {hasContent, content} = await communityScanner.startScan({cameraDirection}); // start scanning and wait for a result
  
    // if the result has content
    if (hasContent) {
      console.log(content); // log the raw scanned content
      return content;
    }
  }

  async scanQRCode() {
    return this.barcodeScanner.scan({showFlipCameraButton: true, preferFrontCamera: this.defaultFrontCamera, orientation: 'landscape'}).then(async (barcodeData) => {
      if (barcodeData.text && !barcodeData.cancelled) {
        return barcodeData.text;
    } else {
        return false;
    }
    }).catch(err => {
        console.log('Error', err);
        return err;
    });
  }

}


