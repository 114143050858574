import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserServiceCustom } from '../../services/user.service';
import { MessagesService } from '../../services/messages.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule, KeyValue } from '@angular/common';
import { CommerceOrderStatus, FlagIonic, FormFromJSON, SystemConnection } from '../../services/drupal7/models';
import { FlagServiceCustom } from '../../services/flag.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { DelegateRegistration } from 'src/app/models/models';
import { FormsService } from 'src/app/services/drupal-forms/forms.service';
import { environment } from 'src/environments/environment.prod';
import { FormFieldOptions } from 'src/app/services/drupal7/models/form-controls';
import { SeatingComponent } from 'src/app/seating/seating.component';
import { SeatingChartComponent } from 'src/app/seating/seating-chart/seating-chart.component';
import { EntityServiceCustom } from 'src/app/services/entity.service';
@Component({
    selector: 'app-registration-card',
    templateUrl: './registration-card.component.html',
    styleUrls: ['./registration-card.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, QRCodeModule, SeatingComponent]
})
export class RegistrationCardComponent implements OnInit {
    @Input() row: DelegateRegistration;
    @Input() disabled: boolean = false;
    @Output() loadOrder: EventEmitter<any> = new EventEmitter();
    @Output() pay: EventEmitter<any> = new EventEmitter();
    userSession: SystemConnection;
  
    flags: FlagIonic[];
    checkInOutFlags: FlagIonic[];
    badgeFlags: FlagIonic[];
    formSchema: FormFromJSON;
    orderStatus = CommerceOrderStatus;

    constructor(
        public flagService: FlagServiceCustom,
        public router: Router,
        private user: UserServiceCustom,
        private entity: EntityServiceCustom,
        public message: MessagesService,
        private modalCtrl: ModalController,
        private forms: FormsService
    ) {
        this.user.currentSession.subscribe(user => {
          if (user) {
            this.userSession = user;
          }
        });
        this.flagService.currentFlags.subscribe(flags => {
          this.flags = flags;
        });
        this.flagService.currentFlagTypes.subscribe(flagTypes => {
          if (flagTypes) {
            this.checkInOutFlags = flagTypes.checkInOutFlags;
            this.badgeFlags = flagTypes.badgeFlags
          }
        })
     }

     async ngOnInit(): Promise<void> {
      console.log(this.row);
      const opts: FormFieldOptions = {
        entityRef: {
          excludeOptions: ['linked_church', 'seat_reservation_id', 'linked_spouse', 'linked_bulk_registration',
        'field_childcare_information', 'linked_payment_transaction']
        }
      }
      this.formSchema = await this.forms.getInternalForm(this.userSession, environment.checkInType.entityType, environment.checkInType.bundle, 'form', opts);
     }

     originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;

    loadCommerceOrder(orderID: number) {
      this.loadOrder.emit(orderID);
    }

    payCommerceOrder(orderID: number) {
      this.pay.emit(orderID);
    }
    
      flagEntity(item: DelegateRegistration, flag: FlagIonic, flagValue: boolean) {
        const firstName = item.contact_first_name ? item.contact_first_name : item.contact_first_name;
        const flagMessage = `Flagged ${flag.label} for ${firstName} ${item.contact_last_name}`;
        const unflagMessage = `Unflagged ${flag.label} for ${firstName} ${item.contact_last_name}`;
        this.flagService.flagEntity(item, flag, flagValue, this.userSession, flagMessage, unflagMessage);
        console.log(item);
      }

      canUpdate(entity: any, key: string) {
        if (this.formSchema) {
          if (entity.author[0].rid === 'church member' && key === 'selected_seat') {
            return false;
          } else {
            return this.formSchema.formGroup.controls[key] ? true : false;
          }
        }
      }

      async openSeating(entity: DelegateRegistration) {
        const modal = await this.modalCtrl.create({
          component: SeatingChartComponent,
          cssClass: 'halfscreen',
          componentProps: {
            seat: entity.seat_reservation[0],
            seats: entity.seat_reservation,
          }
        });
        await modal.present();
      }

      async updateField(entity: any, key: string) {

        entity.first_name = entity.contact_first_name;
        entity.last_name = entity.contact_last_name;

        const formField = await this.forms.getInternalForm(this.userSession, entity.entity_type, entity.bundle, 'form', {}).then(res => this.forms.findObjectWithKey(res.form_fields, 'field_name', key));
        const inputs = [];
        const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
        if (formField.options) {
          for (const option of formField.options) {
            inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
          }
        } else if (formField.ionic_type === 'boolean') {
          inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
        } else {
          inputs.push(
            {
              name: key,
              type: formField.type,
              id: key,
              placeholder: '',
              value: entity[key]
            }
          );
        }
        this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs, 'nid').then(val => {
          if (val) {
            return entity[key] = val;
          }
        });
      }

      getDelegateType(entity: any): string {
        return this.entity.getDelegateType(entity);
      }
    
      getGroupLabel(entity: any): string {
        return this.entity.getGroupLabel(entity);
      }
    
    
}