import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }
  async getActiveMenuItems(file: string) {
    const menuItems = [];
    await import('../../assets/menu/' + file + '.json').then((items: any) => {
      for (const item of items.default) {
        if (item.status) {
          menuItems.push(item);
        }
      }
    });
    return menuItems;
  }

}

export interface MenuItems {
  entity: string;
  bundle: string;
  view_mode: string;
  label: string;
  icon?: string;
  src?: string;
  submit_label?: string;
  preview_label?: string;
  status: boolean;
  path?: string;
}
