import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

export enum NetworkStatus {
  online,
  offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private status: BehaviorSubject<any> = new BehaviorSubject(NetworkStatus.offline);

  constructor(private toastController: ToastController) {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      console.log(this.status.getValue());
      const state = status.connectionType !== 'none' ? NetworkStatus.online : NetworkStatus.offline;
      this.updateNetworkStatus(state);
    });
  }

  public initializeNetworkEvents() {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
    });
  }

  public onNetworkChange(): Observable<NetworkStatus> {
    return this.status.asObservable();
  }

  async getCurrentNetworkStatus(): Promise<NetworkStatus> {
    const status = await Network.getStatus();
    const state = status.connectionType !== 'none' ? NetworkStatus.online : NetworkStatus.offline;
    return state;
  }

  private async updateNetworkStatus(status: NetworkStatus) {
    this.status.next(status);

    const connection = status === NetworkStatus.offline ? 'offline' : 'online';
    const toast = this.toastController.create({
      message: `You are now ${connection}`,
      duration: 3000,
      position: 'bottom'
    });
    toast.then(res => res.present());
  }


}
