// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid.line-items,
ion-grid.payment-transactions {
  --ion-grid-column-padding: 10px;
  border-collapse: collapse;
  border-style: hidden;
}
ion-grid.line-items ion-row:first-child,
ion-grid.payment-transactions ion-row:first-child {
  font-weight: bold;
}
ion-grid.line-items ion-col,
ion-grid.payment-transactions ion-col {
  border-bottom: 1px solid rgb(128, 128, 128);
}
ion-grid.line-items ion-row:last-child ion-col,
ion-grid.payment-transactions ion-row:last-child ion-col {
  border-bottom: 0;
}

.hide-for-swipe {
  position: fixed;
  top: -500rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBheW1lbnQucGFnZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOztFQUVJLCtCQUFBO0VBQ0EseUJBQUE7RUFDQSxvQkFBQTtBQUNKO0FBQUk7O0VBQ0ksaUJBQUE7QUFHUjtBQURJOztFQUNJLDJDQUFBO0FBSVI7QUFGSTs7RUFDSSxnQkFBQTtBQUtSOztBQURBO0VBQ0ksZUFBQTtFQUNBLFlBQUE7QUFJSiIsImZpbGUiOiJwYXltZW50LnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1ncmlkLmxpbmUtaXRlbXMsXG5pb24tZ3JpZC5wYXltZW50LXRyYW5zYWN0aW9ucyB7XG4gICAgLS1pb24tZ3JpZC1jb2x1bW4tcGFkZGluZzogMTBweDtcbiAgICBib3JkZXItY29sbGFwc2U6IGNvbGxhcHNlO1xuICAgIGJvcmRlci1zdHlsZTogaGlkZGVuO1xuICAgIGlvbi1yb3c6Zmlyc3QtY2hpbGQge1xuICAgICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICB9XG4gICAgaW9uLWNvbCB7XG4gICAgICAgIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCByZ2IoMTI4LCAxMjgsIDEyOCk7XG4gICAgfVxuICAgIGlvbi1yb3c6bGFzdC1jaGlsZCBpb24tY29sIHtcbiAgICAgICAgYm9yZGVyLWJvdHRvbTogMDtcbiAgICB9XG59XG5cbi5oaWRlLWZvci1zd2lwZSB7XG4gICAgcG9zaXRpb246IGZpeGVkO1xuICAgIHRvcDogLTUwMHJlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/payment/payment.page.scss"],"names":[],"mappings":"AAAA;;EAEI,+BAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AAAI;;EACI,iBAAA;AAGR;AADI;;EACI,2CAAA;AAIR;AAFI;;EACI,gBAAA;AAKR;;AADA;EACI,eAAA;EACA,YAAA;AAIJ;AACA,o8BAAo8B","sourcesContent":["ion-grid.line-items,\nion-grid.payment-transactions {\n    --ion-grid-column-padding: 10px;\n    border-collapse: collapse;\n    border-style: hidden;\n    ion-row:first-child {\n        font-weight: bold;\n    }\n    ion-col {\n        border-bottom: 1px solid rgb(128, 128, 128);\n    }\n    ion-row:last-child ion-col {\n        border-bottom: 0;\n    }\n}\n\n.hide-for-swipe {\n    position: fixed;\n    top: -500rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
