import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../../../shared/authentication-service';
import { CommonModule } from '@angular/common';
import { IonicModule, Platform } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { environment } from 'src/environments/environment.prod';
import { HeaderComponent } from 'src/app/system/header/header.component';
import { FirebaseTableAssignmentsComponent } from 'src/app/table-assignments/firebase-table-assignments/firebase-table-assignments.component';

@Component({
  selector: 'app-firebase-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, HeaderComponent, FirebaseTableAssignmentsComponent]
})
export class FirebaseMyAccountComponent {

  private authService = inject(AuthenticationService);
  public platform = inject(Platform);
  public router = inject(Router);

  version = environment.version;
  
  loggedIn = false;
  isAuthorized = true;
  user: any = null;

  constructor() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
    if (user) {
        this.loggedIn = true;
        this.user = user;
        this.isAuthorized = true;
    }
    });
  }

  signOut() {
    console.log('signing out');
    this.authService.SignOut();
  }

}