
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';

/**
 * groupMember service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class GroupMemberService extends MainService {
  override entityType = 'jsonapi/group_member/';

  /**
   * implement retrieve resource
   *
   * @param id groupMember id to retrieve
   * @return groupMember object
   */
  getGroupMemberById(id: number): Promise<any> {
    return this.get(id);
  }

  /**
   * implement index resource
   *
   * @return array of groupMembers
   * Please notice that the groupMember must have the required permission to do this action
   */
  getGroupMembersList(): Promise<any[]> {
    return this.get();
  }

  /**
   * implement create resource
   *
   * @param groupMember groupMember to create
   * @return created groupMember details
   */
  createGroupMember(groupMember: any, type: string): Promise<any> {
    return this.post(groupMember);
  }

  /**
   * implement update resource
   *
   * @param groupMember groupMember object to update
   * @return updated groupMember object
   */
  updateGroupMember(groupMember: any, type: string): Promise<any> {
    const body = {
        data: {
          type,
          id: groupMember.id,
          attributes: groupMember.attributes
        }
      };
    return this.patch(body, `${type}/${groupMember.id}`);
  }

  /**
   * implement delete
   *
   * @param id groupMember id to delete
   * @return array of boolean, if groupMember deleted will be true, otherwise will be false
   */
  deleteGroupMember(id: number): Promise<boolean[]> {
    return this.delete(id);
  }

}
