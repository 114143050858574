import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrupalFormControlObject, ElavonCreditCardTransaction, FormFromJSON, PostalAddress, SystemConnection } from 'src/app/services/drupal7/models';
import { ConditionalFields, DrupalEntityBundleInfoWrapper, FieldGroupOptions, FormFieldOptions } from 'src/app/services/drupal7/models/form-controls';
import { UserServiceCustom } from 'src/app/services/user.service';
import { FormsService } from 'src/app/services/drupal-forms/forms.service';
import { CommonService } from 'src/app/services/common.service';
import { IonicModule, LoadingController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from 'src/app/system/form-field/form-field.component';
import { FieldGroup } from 'src/app/services/drupal-forms/forms-model';

@Component({
  selector: 'app-manual-payment-method',
  templateUrl: './manual-payment-method.component.html',
  styleUrls: ['./manual-payment-method.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, FormFieldComponent]
})
export class ManualPaymentMethodComponent implements OnInit {

  @Input() entityType: string;
  @Input() entityBundle: string;
  @Input() pageTitle = 'Payment';
  @Input() orderTotal: string;
  @Input() formCallback: 'getExternalForm' | 'getInternalForm' = 'getInternalForm';
  @Input() loadedEntity: any;
  @Output() checkout: EventEmitter<any> = new EventEmitter();
  userSession: SystemConnection;

  form: FormGroup;
  bundleInfo: DrupalEntityBundleInfoWrapper;
  formFields: DrupalFormControlObject[] = [];
  nestedFieldGroups: DrupalFormControlObject[] = [];
  conditionalFields: ConditionalFields = {};
  isFieldGroup = false;

  administrativeAreas: any;
  states: object;
  minDate = '1900';
  maxDate = (new Date().getFullYear() + 3).toString();
  order = null;

  loading: HTMLIonLoadingElement;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private userService: UserServiceCustom,
    private loadingCtrl: LoadingController,
    private formsService: FormsService,
  ) {
  }

  get errorControl() {
    return this.form.controls;
  }

  async ngOnInit() {
    console.log(this.entityType);
    this.userService.currentSession.subscribe(session => {
      if (session) {
        this.userSession = session;
        this.getCheckoutForm();
      }
    });
  }

  async getForm(entityType: string, entityBundle: string, fieldOpts: FormFieldOptions, formCallback: 'getExternalForm' | 'getInternalForm') {
    await this.showLoading('Loading form...');

    if (entityType === 'commerce_customer_profile') {
      const parentFieldName = 'commerce_customer_address__';
      const excluded = [`${parentFieldName}name_line`, `${parentFieldName}sub_premise`, `${parentFieldName}sub_administrative_area`, `${parentFieldName}dependent_locality`, `${parentFieldName}organisation_name`];
      const newFieldGroup: FieldGroup = {
        field_name: 'group_billing',
        label: 'Billing Details',
        description: 'Verify and complete your billing information',
        type: 'field_group',
        ionic_type: 'field_group',
        status: true,
        access: true,
        visible: true
      };
      fieldOpts.excluded = excluded;
      const formOpts: FieldGroupOptions = {
        wrap: true, group: newFieldGroup
      }
      const form = await this.formsService[formCallback](this.userSession, entityType, entityBundle, 'form', fieldOpts, formOpts);
      return form;
    } else {
      const form = await this.formsService[formCallback](this.userSession, entityType, entityBundle, 'form', fieldOpts);
      return form;
    }
  }

  async getCheckoutForm() {
    const cardForm = await this.getCardForm();
    const billingForm = await this.getBillingForm();

    const combinedForm = [...billingForm.form_fields, ...cardForm.form_fields];
    console.log(combinedForm);
    const formBuilder = new FormBuilder();
    const entityForm = this.fb.group({});
    entityForm.addControl('card_details', new FormGroup(cardForm.formGroup.controls));

    const combinedFormGroup = formBuilder.group(Object.assign({}, billingForm.formGroup.controls, cardForm.formGroup.controls));
    console.log(combinedFormGroup);
    const form: FormFromJSON = {
      form_fields: combinedForm,
      formGroup: combinedFormGroup,
      bundle_info: billingForm.bundle_info,
      conditional_fields: billingForm.conditional_fields,
      isFieldGroup: billingForm.isFieldGroup,
      administrativeAreas: billingForm.administrativeAreas,
      nestedFieldGroups: billingForm.nestedFieldGroups
    };
    this.setupForm(form);
  }

  async getBillingForm() {
    return await this.getForm('commerce_customer_profile', 'billing', {}, 'getExternalForm');
  }

  async getCardForm() {
    return await this.getForm('elavon', 'card', {}, 'getInternalForm');
  }

  async showLoading(message: string) {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        message,
        backdropDismiss: false,
      });
      await this.loading.present();
    }
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  async setupForm(form: FormFromJSON) {
    if (form) {
      console.log(form);

      this.formFields = form.form_fields;
      // Add the year options to the card
      this.commonService.addOptions(this.formFields, ['exp_year'], this.commonService.generateArrayOfYears(0, 10));
      this.form = form.formGroup;
      this.nestedFieldGroups = form.nestedFieldGroups;
      this.entityBundle = form.entityBundle;
      this.bundleInfo = form.bundle_info;
      this.isFieldGroup = form.isFieldGroup;
      this.administrativeAreas = form.administrativeAreas;
      
      if (this.loadedEntity) {
        this.formsService.loadEntity(this.loadedEntity, this.form, this.formFields, this.nestedFieldGroups, this.administrativeAreas);
      }
      console.log(this.form);
    }
    await this.dismissLoading();
  }

  pushCheckout() {
    const cardDetails: ElavonCreditCardTransaction = {
      card_number: this.form.value.card_number,
      exp_date: `${this.form.value.exp_month}${this.form.value.exp_year.slice(-2)}`,
      exp_month: this.form.value.exp_month,
      exp_year: this.form.value.exp_year,
      code: this.form.value.cvc
    }
    const billingDetails: PostalAddress = this.form.value.commerce_customer_address;
    billingDetails.name_line = `${billingDetails.first_name} ${billingDetails.last_name}`;
    const values = {
      card_details: cardDetails,
      commerce_customer_address: billingDetails
    }
    console.log(values);
    this.checkout.emit(values);
    this.resetForm();
  }

  resetForm() {
    this.form = this.fb.group({});
    this.formFields = null;
  }

}
