
import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { SystemConnection } from '../models';

/**
 * user service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class UserService extends MainService {
  override entityType = '';

  /**
   * implement retrieve resource
   *
   * @param uid user id to retrieve
   * @return user object
   */
  getUserById(uid: number): Promise<any> {
    return this.get(uid);
  }

  /**
   * implement index resource
   *
   * @return array of users
   * Please notice that the user must have the required permission to do this action
   */
  getUsersList(): Promise<any[]> {
    return this.get();
  }

  /**
   * implement create resource
   *
   * @param user user to create
   * @return created user details
   */
  createUser(user: any): Promise<any> {
    return this.post(user);
  }

  /**
   * implement update resource
   *
   * @param user user object to update
   * @return updated user object
   */
  updateUser(user: any): Promise<any> {
    return this.put(user, user.uid);
  }

  /**
   * implement delete
   *
   * @param uid user id to delete
   * @return array of boolean, if user deleted will be true, otherwise will be false
   */
  deleteUser(uid: number): Promise<boolean[]> {
    return this.delete(uid);
  }

  /**
   * implement login resource
   *
   * @param user credentials to login "username and password"
   * @return system connection that contains the session details and the user object
   * notice that the user login version must be of version 1.0 at the resources settions at the back-end
   */
  async login(user: any): Promise<SystemConnection> {
    const observer = this.post(user, 'user/login');
    return observer.then((connection: SystemConnection) => {
      this.saveSessionToken(connection);
      return connection;
    });
  }

  /**
   * implement logout resource
   *
   * @return new token after logging out
   */
  async logout(): Promise<any> {
    const observer = this.post({}, 'logout');
    return observer.then((loggedOut: boolean[]) => {
      if (loggedOut[0]) {
        this.removeSession();
        return this.getToken();
      }
    });
  }

  /**
   * implement request_new_password resource
   *
   * @param username the username of the user to request a new password
   * @return array of boolean, if the mail sent successfully will be true, otherwhise will be false.
   * if the server is not a mail server or if there is not mail server configuration this will always return false
   */
  requestNewPassword(username: string): Promise<boolean[]> {
    const user = {
      name: username
    };
    return this.post(user, 'request_new_password');
  }

  /**
   * implement user_pass_reset resource
   *
   * @param passwordReset Object of the sent details to the email that contains the timestamp and the hashed password
   * @return response of the password reset request
   * this request will automatically logging the user in for only one time and will be expired after the first use.
   * the hashed password can be found at the drupal reset password email and you can customize it if you have token module installed
   */
  userPasswordReset(passwordReset: any): Promise<any> {
    return this.post(passwordReset, 'user_pass_reset');
  }

  /**
   * implement
   *
   * @param uid the user id
   * @param field the data to update
   * @param token the password reset token
   * @return system connection that contains the session details and the user object
   * notice that the user login version must be of version 1.0 at the resources settions at the back-end
   */
  userPasswordResetToken(uid: number, field: any, token: string): Promise<SystemConnection> {
    return this.put(field, `${uid}?pass-reset-token=${token}`);

  }

  /**
   * implement register resource
   *
   * @param user object to register new account
   * some properties is required if it is set at the account settings inside the drupal admin config
   * @see http://BACKEND/admin/config/people/accounts
   * @return created user details
   */
  registerAccount(user: any): Promise<any> {
    return this.post(user, 'register');
  }

  /**
   * implement cancel resource
   *
   * @param uid the user id to cancel
   * @return array of boolean, true if canceled, false otherwhise
   * Please notice the cancelation settings is managed at the backend
   * @see http://BACKEND/admin/config/people/accounts
   */
  cancelUser(uid: number): Promise<boolean[]> {
    return this.post({}, `${uid}/cancel`);
  }

  /**
   * implement password_reset resource
   *
   * @param uid user id to reset his password
   * @return array of boolean, true if user password has been resetted, false otherwhise
   */
  passwordReset(uid: number): Promise<boolean[]> {
    return this.post({}, `${uid}/password_reset`);
  }

  /**
   * implement resend_welcome_email
   *
   * @param uid user id to send the email for his account
   * @return array of boolean, if the mail sent successfully will be true, otherwhise will be false.
   * if the server is not a mail server or if there is not mail server configuration this will always return false
   */
  resendWelcomeEmail(uid: number): Promise<boolean[]> {
    return this.post({}, `${uid}/resend_welcome_email`);
  }

  /**
   * implement token
   */
  token(): Promise<string> {
    return this.getToken();
  }

  userToken(): Promise<any> {
    return this.post({}, 'token');
  }

}
