import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IonicModule, ModalController } from "@ionic/angular";
import { SeatingComponent } from "../seating.component";
import { SeatingCardComponent } from "../seating-card/seating-card.component";
import { SeatData } from "src/app/models/models";

@Component({
    selector: 'app-seating-chart',
    template: `<ion-header><ion-toolbar><ion-buttons slot="start"><ion-button (click)="close()">Close</ion-button></ion-buttons></ion-toolbar></ion-header>
    <app-seating-card *ngIf="seat" [seat]="seat"></app-seating-card>
    <app-seating-card *ngIf="viewSeat" [viewSeat]="viewSeat"></app-seating-card>
    <app-seating [seats]="seats"></app-seating>`,
    styleUrls: ['./seating-chart.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, SeatingComponent, SeatingCardComponent]
})
export class SeatingChartComponent {
    @Input() seat: SeatData;
    @Input() viewSeat: SeatData;
    @Input() seats: SeatData[];

    constructor(private modalCtrl: ModalController) {}

    close() {
        this.modalCtrl.dismiss();
    }
}