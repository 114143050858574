import { Component, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../../../services/user.service';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { MessagesService } from '../../../services/messages.service';
import { IonSearchbar, IonicModule, ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { CommonModule, KeyValue } from '@angular/common';
import { FlagServiceCustom } from '../../../services/flag.service';
import { CodeScanService } from '../../../services/codescan.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrupalFormControlObject, FlagIonic, Pager, ViewOptions, SystemConnection } from '../../../services/drupal7/models';
import { FormInlineComponent } from '../../../system/form-inline/form-inline.component';
import { FormsService } from '../../../services/drupal-forms/forms.service';
import { EntityServiceCustom, EntitySetupOptions } from '../../../services/entity.service';
import { HeaderComponent } from '../../../system/header/header.component';
import { QRCodeComponent } from 'angularx-qrcode';
import { DelegateRegistration, FoundRegistrations } from '../../../models/models';
import { EntityReferenceLinkedChurchComponent } from '../../../entity_reference/linked_church/entity_reference_linked_church.component';
import { EntityReferenceSeatReservationComponent } from '../../../entity_reference/seat_reservation/entity_reference_seat_reservation.component';
import { PagerSummaryComponent } from 'src/app/system/pager/pager-summary.component';
import { PagerComponent } from 'src/app/system/pager/pager.component';
import { DrupalConstants } from 'src/app/services/drupal7/public_api';
import { PrintBadgeService } from '../../styles/print-badge.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, HeaderComponent, QRCodeComponent, PagerSummaryComponent, PagerComponent]
})
export class A6BadgeComponent implements OnInit, OnDestroy {

  private flagService = inject(FlagServiceCustom);
  private forms = inject(FormsService);
  private entityService = inject(EntityServiceCustom);
  private codeScan = inject(CodeScanService);
  private platform = inject(Platform);
  public router = inject(Router);
  public storage = inject(Storage);
  private barcodeScanner = inject(BarcodeScanner);
  private modalController = inject(ModalController);
  private user = inject(UserServiceCustom);
  public messagesService = inject(MessagesService);
  private printBadgeService = inject(PrintBadgeService);

  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  userSession: SystemConnection;

  flags: FlagIonic[];
  data: DelegateRegistration[];

  noResultsData = true;
  inputData: string | number;
  scannedInput: string | number;
  pager: Pager;
  resultsCount = 10;
  itemsPerPage = [5, 10, 20, 40, 60, 100];
  focusInput = false;
  leftPage: boolean = false;

  checkInLocationList = environment.checkInLocationList;
  delegateTypesList = environment.delegateTypesList;

  badgeFilters = {};
  
  constructor() {
      console.log('listening for badge scan');
      document.addEventListener('keypress', (evt: KeyboardEvent) => {
          this.scan(evt);
      }, true);
  
      this.resetSearch(false);
   }

   async ngOnInit() {
    this.user.currentSession.subscribe(user => {
      this.userSession = user;
    });
    this.flagService.currentFlags.subscribe(flags => {
      this.flags = flags;
    });
   }

   @HostListener("window:beforeunload")
      ngOnDestroy() {
        console.log("badges page destroyed");
        this.leftPage = true;
        document.removeEventListener('keypress', (evt: KeyboardEvent) => {
      }, true);
    }

   async scan(event) {
    if (!this.leftPage) {
      const res = await this.codeScan.scan(event);
      console.log(res);

      if (res?.id) {
          console.log('this is the delegate id');
          console.log(res.id);
          this.loadDelegateAndFlagAsPrinted(res.id);
      }
    }
  }

  async loadDelegateAndFlagAsPrinted(id: string = '') {
    const options: ViewOptions = {
      display_id: 'api_registration_badges',
      filters: {
        id,
        items_per_page: this.resultsCount
      }
    };
    const setupOptions: EntitySetupOptions = {
      qrCodes: true,
      uniqueOrders: true,
      badgeControls: true
    }
    const foundRegistrations: FoundRegistrations = await this.entityService.getViewWithOptions(`registration/badges`, options, setupOptions);

        const flags: FlagIonic[] = await this.storage.get('flags_by_type').then(res => res?.badgeFlags);
        for (const flag of flags) {
          if (this.userSession.user.flag_access.flag[flag.name]) {
            for (const entity of foundRegistrations.results) {
              if (!entity['flag_'+flag.name] && flag.name === 'badge_printed') {
                this.flagEntity(entity, flag, entity['flag_'+flag.name]);
              }
            }
          }
        }

      this.data = foundRegistrations.results;
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;
  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value.localeCompare(b.value);

  sessionChange(e) {
    this.userSession = e;
  }
  async flagChange() {
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
  }

  async segmentChanged(val: any) {
    this.router.navigate([val], { replaceUrl: true });
  }

  scanQRCode() {
    if (this.platform.is('capacitor')) {
      this.barcodeScanner.scan({showFlipCameraButton: true, preferFrontCamera: true}).then(barcodeData => {
        if (barcodeData.text && !barcodeData.cancelled) {
          this.messagesService.showLoading('Searching for ' + barcodeData.text, false, 1500);
          this.get(barcodeData.text, '', true);
        }
       }).catch(err => {
           console.log('Error', err);
       });
    } else {
      this.scannedInput = '';
      this.searchInput.setFocus();
    }
  }

  async updateField(entity: any, key: string) {
    const formField = await this.forms.getInternalForm(this.userSession, entity.entity_type, entity.bundle, 'form', {}).then(res => this.forms.findObjectWithKey(res.form_fields, 'field_name', key));
    console.log(formField);
    const inputs = [];
    const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
    if (formField.options) {
      for (const option of formField.options) {
        inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
      }
    } else if (formField.ionic_type === 'boolean') {
      inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
    } else {
      inputs.push(
        {
          name: key,
          type: formField.type,
          id: key,
          placeholder: '',
          value: entity[key]
        }
      );
    }
    this.entityService.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs, 'nid').then(val => {
      if (val) {
        return entity[key] = val;
      }
    });
  }

  async updateSeatReservation(entity: DelegateRegistration, fieldName: string, index: number) {
    const form = await this.forms.getInternalForm(this.userSession, entity.entity_type, entity.bundle, 'form', {});
    const formField: DrupalFormControlObject = this.forms.findObjectWithKey(form.form_fields, 'field_name', fieldName);
    console.log(formField);
    const modal = await this.modalController.create({
      component: EntityReferenceSeatReservationComponent,
      cssClass: 'fullscreen',
      componentProps: {
        field: formField,
        entity
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    console.log(res);
    if (res.role === 'submit') {
      this.updateCurrentEntity(entity, index);
    }
  }


  async updateLinkedChurch(entity: DelegateRegistration, fieldName: string, index: number) {
    const form = await this.forms.getInternalForm(this.userSession, entity.entity_type, entity.bundle, 'form', {});
    const formField: DrupalFormControlObject = this.forms.findObjectWithKey(form.form_fields, 'field_name', fieldName);
    console.log(formField);
    await this.forms.getEntityRefOptions(formField, entity);
    const modal = await this.modalController.create({
      component: EntityReferenceLinkedChurchComponent,
      cssClass: 'fullscreen',
      componentProps: {
        field: formField,
        entity,
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    console.log(res);
    if (res.role === 'submit') {
      this.updateCurrentEntity(entity, index);
    }
  }

  async updateCurrentEntity(entity: DelegateRegistration, index: number) {
    const options: ViewOptions = {
      display_id: 'api_registration_badges',
      args: [entity.nid]
    };
    const setupOptions: EntitySetupOptions = {
      qrCodes: true,
      uniqueOrders: true,
      badgeControls: true,
    }
    const foundRegistration = await this.entityService.getViewWithOptions(`registration/badges`, options, setupOptions).then(res => res.results.find(o => o.nid === entity.nid));
    if (foundRegistration) {
      this.data[index] = foundRegistration;
    }
  }

  async editField(entity: DelegateRegistration, key: string) {
    console.log(key);
    const modal = await this.modalController.create({
      component: FormInlineComponent,
      cssClass: 'edit-entity',
      componentProps: {
        entityType: entity.entity_type,
        entityBundle: entity.bundle,
        viewMode: 'form',
        loadedEntity: entity,
        formTitle: `Edit ${entity.bundle}`,
        userSession: this.userSession,
        formCallback: 'getInternalForm'
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data?.values[key]) {
      entity[key] = res.data.values[key];
      if (key === 'linked_church') {
        entity.church_title = res.data.values.entity.title;
      }
      if (key === 'contact_first_name' ||
          key === 'contact_last_name' ||
          key === 'linked_church' ||
          key === 'country' ||
          key === 'delegate_type' ||
          key === 'selected_seat') {
            const flag: FlagIonic = {
              name: 'badge_printed',
              label: 'Badge Printed',
              fid: 4,
              global: 1,
              entity_type: 'node',
              title: 'Badge Printed'
            };
            if (entity['flag_'+flag.name]) {
              this.messagesService.presentToast('This change requires the badge to be reprinted. Unflagging as printed.', 3000, 0, 'top', 'danger');
              this.flagEntity(entity, flag, entity['flag_'+flag.name]);
            }
      }
    }
  }

  async flagEntity(item: DelegateRegistration, flag: FlagIonic, flagValue: boolean) {
      const flagMessage = 'Flagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
      const unflagMessage = 'Unflagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
      const session = await this.storage.get(DrupalConstants.storageKey);
      const newFlagStatus = await this.flagService.flagEntity(item, flag, flagValue, session, flagMessage, unflagMessage);
      item['flag_'+flag.name] = newFlagStatus;
  }

  checkFlagDisabled(flag: any, entity: DelegateRegistration) {
    return this.flagService.checkFlagDisabled(flag, entity);
  }

  async get(id: string = '', page: string = '', autoFlag: boolean = false) {
    const options: ViewOptions = {
      display_id: 'api_registration_badges',
      filters: {
        id,
        page,
        items_per_page: this.resultsCount
      }
    };
    Object.keys(this.badgeFilters).map(k => {
      if (this.badgeFilters[k] !== 'All') {
        options.filters[k] = this.badgeFilters[k];
      }
    });
    const setupOptions: EntitySetupOptions = {
      qrCodes: true,
      uniqueOrders: true,
      badgeControls: true,
      autoFlag
    }
    const foundRegistrations = await this.entityService.getViewWithOptions(`registration/badges`, options, setupOptions);
    console.log(foundRegistrations);
    if (foundRegistrations) {
      this.pager = foundRegistrations.pager;
      this.focusInput = false;
      this.noResultsData = false;
      this.data = foundRegistrations.results;
      console.log(this.data);
    } else {
      this.noResultsData = true;
    }
  }

  changeBadgeValue(entity: any, key: string) {
    if (entity[key] === '' && entity[key] !== entity[key+'_original']) {
      entity[key] = entity[key+'_original'];
    }
  }

  getDelegateType(entity: any): string {
    return this.entityService.getDelegateType(entity);
  }

  getGroupLabel(entity: any): string {
    return this.entityService.getGroupLabel(entity);
  }  

  search(input: any, showLoading: boolean = true, autoflag: boolean = false) {
    if (input !== '' && input !== undefined) {
      if (showLoading) {
        this.messagesService.showLoading('Searching for ' + input, false, 1500);
      }
      this.get(input, '', autoflag);
    } else {
      this.get();
    }
  }

  async printPage() {
    const styles = this.printBadgeService.a6Styles();
    this.printBadgeService.printPage(document.title, document.getElementById('printArea').innerHTML, styles);
  }

  resetSearch(showLoading: boolean = true) {
    this.inputData = '';
    this.scannedInput = '';
    this.data = null;
    this.badgeFilters = {
      paid: '1',
      checked_in: '0',
      badge_printed: '0',
      badge_pre_printed: 'All',
      field_meeting_location_target_id: '',
      group_manager_target_id: '',
      delegate_type_value: '',
    };
    if (showLoading) {
      this.messagesService.showLoading('Resetting', false, 300);
    }
  }
}