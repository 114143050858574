import { Component, OnInit } from '@angular/core';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { ScanSettingsComponent } from '../../scan-settings/scan-settings.component';
import { SelfCheckInComponent } from '../../self-check-in/self-check-in.component';
import { MenuItems, MenuService } from '../../services/menu.service';
import { MessagesService } from '../../services/messages.service';
import { UserServiceCustom } from '../../services/user.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SystemConnection } from '../../services/drupal7/models';
import { Router, RouterModule } from '@angular/router';
import { FiltersService } from '../../services/filters.service';
import { KioskService } from 'src/app/services/kiosk.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, RouterModule]
})
export class MenuComponent implements OnInit {
  userSession: SystemConnection;
  loggedIn = false;

  menuNodeTypes: MenuItems[];
  registrationMenu: MenuItems[];
  kioskStatus: boolean;
  filters: any;
  defaultFrontCamera: boolean;
  defaultScanType: boolean;

  constructor(private menu: MenuService,
    private user: UserServiceCustom,
    private router: Router,
    private filtersService: FiltersService,
    public message: MessagesService,
    private kioskService: KioskService,
    private storageService: StorageService,
    private modalController: ModalController,
    ) {
      this.user.currentSession.subscribe(user => {
        this.userSession = user;
        if (user && user.user.uid) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      });
    }

  async ngOnInit() {
    this.menuNodeTypes = await this.menu.getActiveMenuItems('main_menu');
    this.registrationMenu = await this.menu.getActiveMenuItems(environment.checkInType.bundle);
    this.defaultFrontCamera = await this.storageService.get('defaultFrontCamera');
    if (this.defaultFrontCamera === null) {
      this.defaultFrontCamera = false;
    }
    this.defaultScanType = await this.storageService.get('defaultScanType');
    if (this.defaultScanType === null) {
      this.defaultScanType = false;
    }
  }

  async kioskMode() {
    this.kioskService.setKioskPasscode();
  }

  navigate(path: string) {
    this.router.navigate([path], {replaceUrl: true});
  }

  async defaultCamera() {
    const defaultFrontCamera = await this.storageService.get('defaultFrontCamera');
    if (defaultFrontCamera) {
      this.storageService.set('defaultFrontCamera', !defaultFrontCamera);
      this.defaultFrontCamera = !defaultFrontCamera;
    } else {
      this.storageService.set('defaultFrontCamera', !defaultFrontCamera);
      this.defaultFrontCamera = !defaultFrontCamera;
    }
  }

  async setDefaultScanType() {
    const defaultScanType = await this.storageService.get('defaultScanType');
    if (defaultScanType) {
      this.storageService.set('defaultScanType', !defaultScanType);
      this.defaultScanType = !defaultScanType;
    } else {
      this.storageService.set('defaultScanType', !defaultScanType);
      this.defaultScanType = !defaultScanType;
    }
  }

  async scanSettings() {
    const modal = await this.modalController.create({
      component: ScanSettingsComponent,
      cssClass: 'scan-settings',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.message.presentToast('Scan settings saved.', 2000);
    }
  }

  async selfCheckIn() {
      const modal = await this.modalController.create({
        component: SelfCheckInComponent,
        cssClass: 'fullscreen',
        mode: 'ios',
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.message.presentToast('Exited self check in mode.', 2000);
      }
  }


  appLogout() {
    this.user.logout();
  }


}
