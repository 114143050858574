import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { DelegateRegistration } from "src/app/models/models";

@Component({
    selector: 'app-check-in-card',
    templateUrl: './check-in-card.component.html',
    standalone: true,
    imports: [IonicModule, CommonModule]
})
export class CheckInCardComponent {
    @Input() row: DelegateRegistration;

    constructor() {}

    log() {
        console.log(this.row);
    }
}