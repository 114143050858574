import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { FormsService } from "src/app/services/drupal-forms/forms.service";
import { DrupalFormControlObject } from "src/app/services/drupal7/models";
import { format, toZonedTime } from 'date-fns-tz';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule]
})
export class FormFieldComponent implements OnInit {
    @Input() field: DrupalFormControlObject;
    @Input() form: FormGroup;
    @Input() fields: DrupalFormControlObject[];
    @Input() maxDate: any;
    @Input() administrativeAreas: any;

    constructor(public formsService: FormsService) {}

    ngOnInit(): void {
        console.log('field');
    }

    logValue() {
        console.log(this.form);
    }

}