import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SVGZoomService {

    scale = 1;
    viewBox: any;

    isDragging = false;
    touchZoom = false;
    lastX = 0;
    lastY = 0;

    lastTouchDistance = 0;
    lastTouchCenterX = 0;
    lastTouchCenterY = 0;

    constructor() {}

    /**
     * @template T
     * @param {Iterable<T>} iterable
     * @param {{(el:T) :boolean}=} filter
     * @returns {T}
     */
    last(iterable, filter) {
        if (!iterable) { return null; }

        let last;
        for (const el of iterable) {
            if (!filter || filter(el)) {
                last = el;
            }
        }
        return last;
    }

    /**
     * @template T
     * @param {Iterable<T>} iterable
     * @param {{(el:T) :boolean}=} filter
     * @returns {T | null}
     */
    first(iterable, filter) {
        for (const el of iterable) {
            if (!filter || filter(el)) { return el; }
        }
        return null;
    }

    /**
     * @template T
     * @param {Iterable<T>} iterable
     * @param {{(el:T) :boolean}=} filter
     * @returns {boolean}
     */
    any(iterable, filter) {
        if (!iterable) { return false; }

        for (const el of iterable) {
            if (!filter || filter(el)) {
                return true;
            }
        }
        return false;
    }

    /**
     * @template T
     * @param {Set<T>} set
     * @param {T} el
     * @returns {Set<T>}
     */
    setDel(set, el) {
        set.delete(el);
        return set;
    }


    /**
     * @param {SVGGraphicsElement} svgEl
     * @param {number} transform
     * @param {SVGSVGElement=} svg pass if svgEl not yet in DOM
     * @returns {SVGTransform}
     */
    ensureTransform(svgEl, transform, svg) {
        let tr = this.first(svgEl.transform.baseVal, tt => tt.type === transform);
        if (!tr) {
            tr = (svgEl.ownerSVGElement || svg).createSVGTransform();
            svgEl.transform.baseVal.appendItem(tr);
        }
        return tr;
    }

    /**
    * @param {SVGGraphicsElement} svgEl
    * @param { {x: number, y: number} } position
    * @param {SVGSVGElement=} svg pass if svgEl not yet in DOM
    * @returns {void}
    */
    svgPositionSet(svgEl, position, svg) {
        this.ensureTransform(svgEl, SVGTransform.SVG_TRANSFORM_TRANSLATE, svg).setTranslate(position.x, position.y);
    }

    /**
     * @param {SVGGraphicsElement} svgEl
     * @returns { {x: number, y: number} }
     */
    svgPositionGet(svgEl) {
        const tr = this.first(svgEl.transform.baseVal, tt => tt.type === SVGTransform.SVG_TRANSFORM_TRANSLATE);
        return tr ? { x: tr.matrix.e, y: tr.matrix.f } : { x: 0, y: 0 };
    }

    /**
     * @param {SVGGraphicsElement} svgEl
     * @param {number} angle
     * @param {SVGSVGElement=} svg pass if svgEl not yet in DOM
     * @returns {void}
     */
    svgRotate(svgEl, angle, svg) {
        this.ensureTransform(svgEl, SVGTransform.SVG_TRANSFORM_ROTATE, svg).setRotate(angle, 0, 0);
    }

    /**
     * @param {SVGGraphicsElement} svgEl
     * @param {Point} cursorPoint this point will not chage position while scale
     * @param {number} scale
     * @param {number} nextScale
     * @param {SVGSVGElement=} svg pass if svgEl not yet in DOM
     */
    svgScale(svgEl, cursorPoint, scale, nextScale, svg) {
        this.ensureTransform(svgEl, SVGTransform.SVG_TRANSFORM_SCALE, svg).setScale(nextScale, nextScale);
    }
    
    enableInteraction(element: any, parentEl: any, innerEl: any) {
        this.viewBox = element.viewBox.baseVal;
        this.addWheelListener(element, parentEl, innerEl);
        this.addMouseListener(element, parentEl);
        this.addTouchListener(element, parentEl, innerEl);
        this.addKeyListener();
      }

      addWheelListener(element: any, parentEl: any, innerEl: any) {
        parentEl.addEventListener('wheel', /** @param {WheelEvent} evt */ evt => {
            evt.preventDefault();
        
            const delta = evt.deltaY || evt.deltaX;
            const scaleStep = Math.abs(delta) < 50
                ? 0.05  // touchpad pitch
                : 0.25; // mouse wheel
        
            const scaleDelta = delta < 0 ? scaleStep : -scaleStep;
            const nextScale = this.scale + scaleDelta; // 'scale' is prev scale
        
            const fixedPoint = { x: evt.clientX, y: evt.clientY };
        
            this.svgScale(innerEl, fixedPoint, this.scale, nextScale, element);
            this.scale = nextScale;
        });
      }

      addMouseListener(element: any, parentEl: any) {
        parentEl.addEventListener('mousedown', event => {
            element.style.userSelect = 'none';
            this.isDragging = true;
            this.lastX = event.clientX;
            this.lastY = event.clientY;
          });
          parentEl.addEventListener('mousemove', event => {
            if (this.isDragging) {
              requestAnimationFrame(() => {
                const deltaX = event.clientX - this.lastX;
                const deltaY = event.clientY - this.lastY;
                this.viewBox.x -= deltaX;
                this.viewBox.y -= deltaY;
                element.setAttribute('viewBox', `${this.viewBox.x} ${this.viewBox.y} ${this.viewBox.width} ${this.viewBox.height}`);
                this.lastX = event.clientX;
                this.lastY = event.clientY;
              });
            }
          });
          parentEl.addEventListener('mouseup', () => {
            element.style.userSelect = 'auto';
            this.isDragging = false;
          });
      }

      addKeyListener() {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
              this.viewBox.x += 10;
            } else if (event.key === 'ArrowRight') {
              this.viewBox.x -= 10;
            } else if (event.key === 'ArrowUp') {
              this.viewBox.y += 10;
            } else if (event.key === 'ArrowDown') {
              this.viewBox.y -= 10;
            }
          });
      }

      addTouchListener(element: any, parentEl: any, innerEl: any) {
        element.style.touchAction = 'none';

        parentEl.addEventListener('touchstart', (event) => {
            if (event.touches.length === 1) {
              this.isDragging = true;
              this.lastX = event.touches[0].clientX;
              this.lastY = event.touches[0].clientY;
            }
            if (event.touches.length === 2) {
              event.preventDefault();
              this.isDragging = false;
              this.touchZoom = true;
          
              const touch1 = event.touches[0];
              const touch2 = event.touches[1];
          
              this.lastTouchDistance = Math.sqrt(
                Math.pow(touch1.clientX - touch2.clientX, 2) +
                Math.pow(touch1.clientY - touch2.clientY, 2)
              );
          
              this.lastTouchCenterX = (touch1.clientX + touch2.clientX) / 2;
              this.lastTouchCenterY = (touch1.clientY + touch2.clientY) / 2;
            }
          });
          
          parentEl.addEventListener('touchmove', (event) => {
            if (this.isDragging && event.touches.length === 1) {
              const deltaX = event.touches[0].clientX - this.lastX;
              const deltaY = event.touches[0].clientY - this.lastY;
              this.viewBox.x -= deltaX;
              this.viewBox.y -= deltaY;
              innerEl.setAttribute('viewBox', `${this.viewBox.x} ${this.viewBox.y} ${this.viewBox.width} ${this.viewBox.height}`);
              this.lastX = event.touches[0].clientX;
              this.lastY = event.touches[0].clientY;
            }
      
            if (this.touchZoom && event.touches.length === 2) {
              event.preventDefault();
              this.isDragging = false;
          
              const touch1 = event.touches[0];
              const touch2 = event.touches[1];
          
              const touchDistance = Math.sqrt(
                Math.pow(touch1.clientX - touch2.clientX, 2) +
                Math.pow(touch1.clientY - touch2.clientY, 2)
              );
              const deltaDistance = touchDistance - this.lastTouchDistance;
              
              const touchCenterX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
              const touchCenterY = (event.touches[0].clientY + event.touches[1].clientY) / 2;
              const containerWidth = parentEl.clientWidth;
      
              const currentScale = this.viewBox.width / containerWidth;
              const newScale = currentScale * this.scale;
              const scaleFactor = Math.pow(2, deltaDistance * 0.0005);
      
      
              this.viewBox.width /= scaleFactor;
              this.viewBox.height /= scaleFactor;
      
              const pt = element.createSVGPoint();
              pt.x = touchCenterX;
              pt.y = touchCenterY;
          
              const newPt = pt.matrixTransform(element.getCTM().inverse());
          
              this.viewBox.x += (this.viewBox.width - this.viewBox.width * this.scale) * (newPt.x / this.viewBox.width);
              this.viewBox.y += (this.viewBox.height - this.viewBox.height * this.scale) * (newPt.y / this.viewBox.height);
      
              const delta = touchDistance - this.lastTouchDistance;
              const scaleStep = this.scale < 1
              ? Math.abs(delta) < 50 ? 0.05 : 0.25 / this.scale
              : Math.abs(delta) < 50 ? 0.05 * this.scale : 0.25;
      
          
              const scaleDelta = this.scale < 1 ? -scaleStep : scaleStep;
              const nextScale = this.scale + scaleDelta;
      
              const fixedPoint = { x: touchCenterX, y: touchCenterY };
      
              this.svgScale(innerEl, fixedPoint, this.scale, nextScale, element);
      
            }
          });
          
          parentEl.addEventListener('touchend', (event) => {
            this.isDragging = false;
            this.touchZoom = false;
          });
      }
    
    
    
}