import { Component } from '@angular/core';
import { AuthenticationService } from "../../../shared/authentication-service";
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-firebase-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule]
})
export class FirebaseVerifyEmailComponent {
  constructor(
    public authService: AuthenticationService
  ) { }
}