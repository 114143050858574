import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { Pager } from "src/app/services/drupal7/public_api";

@Component({
    selector: 'app-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule]
})
export class PagerComponent {

    @Input() pager: Pager;
    @Output() setPage: EventEmitter<any> = new EventEmitter();

    constructor() {}

    getPage(page: number) {
        this.setPage.emit(page);
    }
}