import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { IonModal, IonicModule, LoadingController, ModalController, Platform } from '@ionic/angular';
import { SeatData } from 'src/app/models/models';
import { Pager, SystemConnection, ViewOptions, ViewService } from 'src/app/services/drupal7/public_api';
import { PagerSummaryComponent } from 'src/app/system/pager/pager-summary.component';
import { PagerComponent } from 'src/app/system/pager/pager.component';
import { SeatingChartComponent } from '../seating-chart/seating-chart.component';
import { SeatingCardComponent } from '../seating-card/seating-card.component';
import { UserServiceCustom } from 'src/app/services/user.service';
import { QrCodeScanService } from 'src/app/services/codescan/codescan.service';
import { MessagesService } from 'src/app/services/messages.service';
import { environment } from 'src/environments/environment.prod';
@Component({
    selector: 'app-seating-list',
    templateUrl: './seating-list.component.html',
    styleUrls: ['./seating-list.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, PagerComponent, PagerSummaryComponent, SeatingCardComponent]
})
export class SeatingListComponent {

    @ViewChild(IonModal) modal: IonModal;

    version = environment.version;
    userSession: SystemConnection;
    seats: Seat[] = [];
    pager: Pager;
    showFilters: boolean = true;

    seating_section = [101, 102, 103, 104, 105, 106, 107, 202, 203, 204, 205, 206, 302, 303, 304, 305, 306];
    seat_row = Array.from({ length: 26 }, (_, i) => String.fromCharCode('A'.charCodeAt(0) + i));
    seat_number = Array.from({ length: 25 }, (_, index) => index + 1);
    temporary_seat_number = Array.from({ length: 25 }, (_, index) => index + 1);
    seat_type = [
        {
          key: 'general',
          value: 'General Seating'
        },
        {
          key: 'korean',
          value: 'Korean Ministry'
        },
        {
          key: 'deaf',
          value: 'Deaf Ministry'
        },
        {
          key: 'speaker',
          value: 'Speaker Section'
        },
        {
          key: 'staff',
          value: 'Staff'
        },
        {
          key: 'senior_saints',
          value: 'Senior Saints'
        },
        {
          key: 'servants_for_christ',
          value: 'Servants for Christ'
        },
        {
          key: 'photographer',
          value: 'Photographer'
        }
      ]

    filters = {
        seating_section: '',
        seat_row: '',
        seat_number: '',
        temporary_seat_number: '',
        seat_assignable: null,
        contact_first_name: '',
        contact_last_name: '',
        delegate_nid: null,
        seat_type: '',
        empty_seat: null,
        page: null
    };

    selectedSeat: Seat;
    loading: HTMLIonLoadingElement;

    constructor(
      private user: UserServiceCustom,
      private platform: Platform,
      private codeScan: QrCodeScanService,
      public message: MessagesService,
      public storage: Storage,
      private viewService: ViewService,
      private modalCtrl: ModalController,
      private loadingCtrl: LoadingController) {
        this.user.currentSession.subscribe(user => {
            if (user) {
              this.userSession = user;
            }
          });
    }

    logout() {
        this.user.logout();
    }

    async scanQRCode() {
      if (this.platform.is('capacitor')) {
        if (!await this.storage.get('defaultScanType')) {
          const result = await this.codeScan.scanQRCode();
          if (result) {
            const qrCode = await this.codeScan.parseScan(result);
            this.filters.delegate_nid = qrCode.id;
            this.get();
          }
        }
      } else if (!await this.storage.get('defaultScanType')) {
        const result = await this.codeScan.startBarcodeScan('back');
        if (result) {
          const qrCode = await this.codeScan.parseScan(result);
          console.log(qrCode);
          this.filters.delegate_nid = qrCode.id;
          this.get();
        }
      }
    }

    convertSeatType(seat: Seat) {
        if (seat.seat_type) {
        const res = seat.seat_type.replace(/_/g, ' ');
        return `Category: ${res}`;
        }
    }

      filtersArray() {
        const filters = Object.entries(this.filters).map(f => {
            const obj = {
                key: f[0].replace(/_/g, ' '),
                value: f[1]
            };
            return obj;
        });
        return filters;
      }

    reset() {
       this.filters = {
            seating_section: '',
            seat_row: '',
            seat_number: '',
            temporary_seat_number: '',
            seat_assignable: '',
            contact_first_name: '',
            contact_last_name: '',
            delegate_nid: null,
            seat_type: '',
            empty_seat: null,
            page: null
        };
        this.pager = null;
        this.seats = [];
    }

    async get(page?: string) {
        const options: ViewOptions = {
            display_id: 'app_seat_assignments',
            filters: {}
        }
        console.log(this.filters);
        Object.keys(this.filters).map(k => {
            if (this.filters[k] !== 'all') {
                options.filters[k] = this.filters[k];
            }
        });
        if (page) {
            options.filters.page = page;
        }
        this.loading = await this.loadingCtrl.create({
            message: 'Searching...'
        });
        await this.loading.present();
        const res = await this.viewService.getView('seating', options);
        if (res) {
            console.log(res);
            this.pager = res.pager;
            this.seats = res.results;
        }
        this.loading.dismiss();
    }

    async openSeats() {
      const test = [];
      for (const entity of this.seats) {
        const seatData: SeatData = {
          bundle: 'seat_reservation',
          entity_type: 'node',
          nid: entity.nid,
          title: entity.seat_reservation_nid,
          nid_1: entity.nid,
          seating_section: entity.seating_section,
          seat_row: entity.seat_row,
          seat_number: entity.seat_number,
          temporary_seat_number: entity.temporary_seat_number,
          seating_order: entity.seating_order,
          temporary_seat: entity.temporary_seat,
          status: entity.status,
          delegate_nid: entity.delegate_nid,
          seating_notes: entity.seating_notes,
          seat_type: entity.seat_type,
          contact_first_name: entity.contact_first_name,
          contact_last_name: entity.contact_last_name,
          seat_reservation_nid: entity.seat_reservation_nid
      };
      if (entity.contact_last_name) {
          seatData.delegate = `${entity.contact_first_name} ${entity.contact_last_name}`;
      }
      test.push(seatData);

      }
    const modal = await this.modalCtrl.create({
      component: SeatingChartComponent,
      cssClass: 'fullscreen',
      componentProps: {
        viewSeat: test[0],
        seats: test,
      }
    });
    await modal.present();
    }

    async openSeating(entity: Seat) {
        const seatData: SeatData = {
            bundle: 'seat_reservation',
            entity_type: 'node',
            nid: entity.nid,
            title: entity.seat_reservation_nid,
            nid_1: entity.nid,
            seating_section: entity.seating_section,
            seat_row: entity.seat_row,
            seat_number: entity.seat_number,
            temporary_seat_number: entity.temporary_seat_number,
            seating_order: entity.seating_order,
            temporary_seat: entity.temporary_seat,
            status: entity.status,
            delegate_nid: entity.delegate_nid,
            seating_notes: entity.seating_notes,
            seat_type: entity.seat_type,
            contact_first_name: entity.contact_first_name,
            contact_last_name: entity.contact_last_name,
            seat_reservation_nid: entity.seat_reservation_nid
        };
        if (entity.contact_last_name) {
            seatData.delegate = `${entity.contact_first_name} ${entity.contact_last_name}`;
        }
        const modal = await this.modalCtrl.create({
          component: SeatingChartComponent,
          cssClass: 'fullscreen',
          componentProps: {
            seats: [seatData],
            viewSeat: seatData
          }
        });
        await modal.present();
      }
}

export interface Seat {
    title?: string
    seating_section: number;
    seat_row: string;
    seat_number: number;
    temporary_seat_number: number;
    seat_type?: string;
    seat_assignable?: number;
    temporary_seat?: number;
    seat_removed?: number;
    field_edge_seat?: string;
    field_seat_region?: string;
    status?: boolean;
    seating_notes?: string;
    flag_delegate_paid?: boolean;
    name?: string;
    nid: number;
    selected_delegate?: string;
    contact_first_name?: string;
    contact_last_name?: string;
    seat_reservation_nid?: number;
    delegate_nid?: number;
    uid?: number;

    // To Add
    bundle?: string;
    delegate?: string;
    entity_type?: string;
    seat?: string;
    seat_auto_assigned?: string;
    nid_1?: number;
    seating_order?: number;
    isSelecting?: boolean;
  }
  