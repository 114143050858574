import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SeatData } from "../models/models";

@Injectable({
    providedIn: 'root'
})
export class SeatingService {

    seat = new BehaviorSubject<SeatData>(null);
    currentSeat = this.seat.asObservable();

    proposedSeat = new BehaviorSubject<SeatData>(null);
    currentProposedSeat = this.proposedSeat.asObservable();
  
    constructor() {}

    changeSeat(seat: SeatData) {
        this.seat.next(seat);
    }

    changeProposedSeat(seat: SeatData) {
        this.proposedSeat.next(seat);
    }

}