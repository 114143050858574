import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuItems } from '../../services/menu.service';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { LogoHeaderComponent } from 'src/app/system/logo-header/logo-header.component';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, LogoHeaderComponent]
})
export class HeaderComponent{
  version = environment.version;
  menuNodeTypes: Array<MenuItems>;
  constructor() {}

}

