import { Component, OnInit } from '@angular/core';
import { TwilioService } from '../services/twilio.service';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-sms-inbox',
  templateUrl: './sms-inbox.component.html',
  styleUrls: ['./sms-inbox.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SmsInboxComponent implements OnInit {

  constructor(private twilio: TwilioService) { }

  ngOnInit() {}

  test() {
    this.twilio.getMessages().subscribe(res => {
      console.log(res);
    });
  }

  // test() {
  //   this.twilio.get().then(res => {
  //     console.log(res);
  //   });
  // }

}
