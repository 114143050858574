// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAV7ZgShe-_pBopsEaSRQSE0cUYYA5QmzQ',
    authDomain: 'slc-asia-registration.firebaseapp.com',
    projectId: 'slc-asia-registration',
    storageBucket: 'slc-asia-registration.appspot.com',
    messagingSenderId: '442198804404',
    appId: '1:442198804404:web:7b72f4768fdc4887df54e1',
    measurementId: 'G-010C4DV22D'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
