import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { catchError, timeout, map } from 'rxjs/operators';
import { DrupalConstants } from '../application/drupal-constants';
import { SystemConnection } from '../models';
import { isPlatformServer } from '@angular/common';
import { StorageService } from '../../storage/storage.service';
import { AlertController } from '@ionic/angular';

/**
 * the main service is the basic http service of all other services "parent" that implements all the required request.
 * this service will add the headers automatically for each request it self
 */
@Injectable()
export class MainService {
  /**
   * entity type of the current service, the main service dont have anything but the other services must use it
   * for example "node, comment, user, system"
   */
  protected readonly entityType: string;

  /**
   * the main constractor of this service will inject the required services dynamically.
   *
   * @param http basic http service
   * @param cookieService ngx-cookie service provider to save the cookies
   * @see https://angular.io/guide/dependency-injection
   * @see https://www.npmjs.com/package/ngx-cookie
   */
  constructor(protected alertCtrl: AlertController, protected httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: object,
  private storageService: StorageService) { }

    /**
     * a getter to return the required headers for drupal
     * X-CSRF-Token - application token from services/session/connect
     * Content-Type - the type of the request content.
     * Accept - forcing drupal to return the response as a json object
     *
     * @return object of the headers
     */
    async getOptions(): Promise<any> {
      const headers: any = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      const session = await this.storageService.get(DrupalConstants.storageKey);
      if (session) {
        const token = session?.token;
        if (token) {
          headers['X-CSRF-Token'] = token;
        }
        headers.Authorization = `${session.session_name}=${session.sessid}`;
        if (DrupalConstants.Settings.cookieHeader) {
          headers['X-Cookie'] = `${session.session_name}=${session.sessid}`;
        }
      }
      const options = {
        headers,
        withCredentials: true,
        reportProgress: true,
      };
      return options;
    }

  /**
   * parse an entity that comes back from a request
   * to make sure it is ready to use in the app
   *
   * @param entity the entity to be structured
   */
    cleanObject(entity: any) {
      if (entity) {
        Object.entries(entity).map(([k, v], i) => {
          let newVal = entity[k];
          if (typeof(entity[k]) === 'string') {
            newVal = entity[k].trim();
          try {
              newVal = JSON.parse(newVal);
              entity[k] = newVal;
              this.cleanObject(newVal);
            } catch (err) {
            }
            if (typeof(newVal) === 'object' && !Array.isArray(newVal) && newVal !== null) {
              Object.entries(newVal).map(([nestedKey, nestedValue], x) => {
                if (k === nestedKey) {
                  entity[k] = this.cleanObject(nestedValue);
                }
              });
            }
            if (typeof(newVal) === 'object' && Array.isArray(newVal) && newVal !== null && newVal.length === 1) {
              entity[k] = this.cleanObject(newVal);
            }
          } else if (typeof(v) === 'object' && Array.isArray(v) && v !== null && v.length === 1) {
            entity[k] = this.cleanObject(v);
          } else if (typeof(v) === 'object' && !Array.isArray(entity[k]) && entity[k] !== null) {
            this.cleanObject(entity[k]);
          } else if (typeof(v) === 'object' && Array.isArray(entity[k]) && entity[k] !== null && entity[k].length > 1) {
            for (const innerObj of entity[k]) {
              this.cleanObject(innerObj);
            }
          } else {
            this.cleanObject(newVal);
          }
        });
        return entity;
      }
    }

  /**
   * Convert an entity to an array
   *
   * @param entity the entity to be structured
   * @param keys fields to be check
   */
    convertObjToArray(entity: any, keys: Array<string>) {
      Object.getOwnPropertyNames(entity).forEach(key => {
        if (keys.indexOf(key) !== -1) {
          const arr: any = [];
          Object.keys(entity[key]).map(k => {
            if (typeof(JSON.parse(k)) === 'number') {
              const v = entity[key][k];
              arr.push(v);
            }
          });
          entity[key] = arr;
        }
      });
    }

  /**
   *
   * @param options an array of options to convert
   * @returns array of options
   */
    convertSelectOptions(options) {
      for (const option of options) {
        const dataToArray = option.value.split('|').map(item => item.trim());
        option.value = dataToArray.join('<br>');
      }
      return options;
    }

  /**
   * Remove properties from an entity
   *
   * @param entity the entity to be structured
   * @param keys fields to be removed from the entity
   */
    removeProps(entity: any, keys: Array<string>){
      if (Array.isArray(entity)) {
        entity.forEach(item => {
          this.removeProps(item, keys);
        });
      }
      else if (typeof entity === 'object' && entity !== null) {
        Object.getOwnPropertyNames(entity).forEach((key) => {
          if (keys.indexOf(key) !== -1) {
            delete entity[key];
          } else {
            this.removeProps(entity[key], keys);
          }
        });
      }
    }

  /**
   * structure a full entity for drupal request
   *
   * @param entity the entity to be structured
   * @param ignoredFields fields to be ignored just like nid or uid or title
   * @param fieldLabels: the label for each field just like "{field_custom_field: 'value'}"
   * @param language language of the entity
   */
  structureEntity(entity: any, ignoredFields: string[], fieldLabels?: any[], language?: string): any {
    Object.keys(entity).forEach((key: string, index: number) => {
      if (ignoredFields.indexOf(key) === -1) {
        let fieldLabel;
        if (fieldLabels && fieldLabels[key]) {
          fieldLabel = fieldLabels[key];
        }
        entity[key] = this.structureField(entity[key], fieldLabel, language);
      }
    });
    return entity;
  }

  /**
   * structure the field for drupal services request
   *
   * @param value the field value
   * @param label field label name
   * @param language language of the field
   */
  structureField(value: any, label: string = 'value', language?: string) {
    if (!language) {
      language = DrupalConstants.Settings.language;
    }
    const item = {};
    if (this.isArray(value)) {
      const field_array: any = [];
      for (let i = 0, l = value.length; i < l; i++) {
        item[label] = value[i];
        field_array.push(item);
      }
      return {
        [language]: field_array
      };
    }
    if (value instanceof Date) {
      const obj = {
        value: {
          date: `${value.getFullYear()}-${value.getMonth() + 1}-` +
            `${value.getDate()} ${value.getHours()}:${value.getMinutes()}:${value.getSeconds()}`
        }
      };
      return {
        [language]: [
          obj
        ]
      };
    }
    // field value given with label(s) already built
    if (typeof value === 'object') {
      return {
        [language]: [
          value
        ]
      };
    }
    item[label] = value;
    return {
      [language]: [
        item
      ]
    };
  }

  /**
   * getting token from drupal services module
   *
   * @return http text token response
   */
  protected async getToken(): Promise<string> {
    const connection = await this.storageService.get(DrupalConstants.storageKey).then((res: SystemConnection) => res);
    const options = await this.getOptions();
    options.responseType = 'text';
    return await this.httpRequestWithConfig(
      this.httpClient.get(`${DrupalConstants.backEndUrl}services/session/token`, options)
    ).then(res => {
      if (!isPlatformServer(this.platformId)) {
        connection.token = (connection.token !== undefined) ? res : '';
        this.storageService.set(DrupalConstants.storageKey, connection);
      }
      return res;
    });
  }


  /**
   * getting the session token from local storage
   * 
   * @returns the session token from local storage
   */
  protected async getSessionToken(): Promise<string> {
    return await this.storageService.get(DrupalConstants.storageKey).then(res => res.token).catch(err => '');
  }

  /**
   * Saving drupal session and token in cookies using ngx-cookie service
   *
   * @param connection drupal connection
   */
  protected async saveSessionToken(connection: SystemConnection): Promise<void> {
    if (!connection.token) {
      connection.token = await this.getSessionToken();
    }
    this.removeSession();
    DrupalConstants.Connection = connection;
    if (!isPlatformServer(this.platformId)) {
      this.storageService.set(DrupalConstants.storageKey, connection);
      if (connection.user && connection.user.timestamp) {
        this.storageService.set(DrupalConstants.timestampKey, connection.user.timestamp.toString() + '000');
      } else {
        this.storageService.set(DrupalConstants.timestampKey, Math.floor(Date.now()).toString());
      }
    }
  }

  /**
   * building up the full url path for each resource and / or params
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return full request path after adding the entity type and resource param
   */
  protected fullRequestURL(resource?: string | number): string {
    
    let request_url = DrupalConstants.restUrl;

    if (this.entityType) {
      request_url += this.entityType;
    }

    if (resource) {
      if (request_url[request_url.length - 1] === '/') {
        request_url += resource;
      } else {
        request_url += '/' + resource;
      }
    }

    return request_url;
  }

  /**
   * handle status errors if the response doesn't return as a catch error
   * 
   * @param res the http response
   * @returns if the response contains an error code, return an alert for the error code, otherwise return the response
   */
  private async handleErrors(res: any): Promise<any> {
    if (res.status && res?.ok === false) {
      switch (res.status) {
        case 401:
          const unauthorized = await this.alertCtrl.create({
            header: '401 Error',
            message: res?.error ? res?.error[0] : 'Unauthorized',
            buttons: ['OK']
          });
          await unauthorized.present();
          return false;
        case 403:
          const accessDenied = await this.alertCtrl.create({
            header: '403 Error',
            message: 'Access Denied',
            buttons: ['OK']
          });
          await accessDenied.present();
          return false;
        case 404:
          const notFound = await this.alertCtrl.create({
            header: '404 Error',
            message: 'Not Found',
            buttons: ['OK']
          });
          await notFound.present();
          return false;
        case 500:
          const serverError = await this.alertCtrl.create({
            header: '500 Error',
            message: 'Internal Server Error',
            buttons: ['OK']
          });
          await serverError.present();
          return false;
        default:
          return res;
      }
    } else if (res.name === 'TimeoutError') {
      const timeoutError = await this.alertCtrl.create({
        header: 'Timeout Error',
        message: 'Request timed out',
        buttons: ['OK']
      });
      await timeoutError.present();
      return false;
    } else {
      return res;
    }
  }

  /**
   * adding http request configs: request timeout, error handler
   *
   * @param httpObservableRequest the http Observable to request
   * @return Observable of the request after adding required configs
   */
  protected async httpRequestWithConfig(httpObservableRequest: Observable<any>): Promise<any> {
    return firstValueFrom(httpObservableRequest.pipe(
      map(res => this.cleanObject(res)),
      timeout(DrupalConstants.Settings.requestTimeout),
      catchError(err => DrupalConstants.Instance.handleOffline(err))
    )).then(res => {
      return this.handleErrors(res);
    }).catch(err => err);
  }

  /**
   * basic http get request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return http json response
   */
  protected async get(resource?: string | number): Promise<any> {
    const options = await this.getOptions();
    return this.httpRequestWithConfig(
      this.httpClient.get(this.fullRequestURL(resource), options)
    );
  }

  /**
   * basic http post request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  protected async post(body: any = {}, resource?: string | number): Promise<any> {
    return this.httpRequestWithConfig(
      this.httpClient.post(this.fullRequestURL(resource), body, await this.getOptions()),
    );
  }

  /**
   * basic http put request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @param body the contenct of the request
   * @return http json response
   */
  protected async put(body: any = {}, resource?: string | number): Promise<any> {
    return this.httpRequestWithConfig(
      this.httpClient.put(this.fullRequestURL(resource), body, await this.getOptions()),
    );
  }

  /**
   * basic http delete request with headers.
   *
   * @param resource the entity resource param. ex: system/'connect', user/'login'
   * @return http json response
   */
  protected async delete(resource?: string | number): Promise<any> {
    return this.httpRequestWithConfig(
      this.httpClient.delete(this.fullRequestURL(resource), await this.getOptions()),
    );
  }

  /**
   * Clearing drupal session after logging out
   */
  protected removeSession(): void {
    if (!isPlatformServer(this.platformId)) {
      this.storageService.remove(DrupalConstants.storageKey);
      this.storageService.remove(DrupalConstants.timestampKey);
      if (DrupalConstants.Connection && DrupalConstants.Connection.session_name) {
        this.storageService.remove(DrupalConstants.Connection.session_name);
      }
    }
  }

  /**
   * Checking the current connection if the connection is init and valid
   *
   * @return if connection is valid
   */
  protected async isConnected(): Promise<boolean> {
    return await this.storageService.get(DrupalConstants.storageKey) &&
      !this.isConnectionExpired() ?
      true : false;
  }

  /**
   * Check if the drupal session is timedout.
   *
   * @return true if the current date is greater than the login date by 24 day "drupal default session timeout is 24 day".
   */
  protected async isConnectionExpired(): Promise<boolean> {
    const nowTS: number = Math.floor(Date.now());
    let sessionDays = 23;
    if (DrupalConstants.Settings.sessionDays) {
      sessionDays = DrupalConstants.Settings.sessionDays;
    }
    const dayMS = 86400000; // 1 day to ms
    const expirationTS: number = sessionDays * dayMS;
    const currentTimestamp = await this.storageService.get(DrupalConstants.timestampKey);
    const lastActivityTS: number = +currentTimestamp;
    const isExpired = nowTS - lastActivityTS > expirationTS;
    return isExpired;
  } 
  

  /**
   * Serializing arguments as a string
   *
   * @param options object of drupal parameters to serialize
   * @return string of parameters
   */
  protected getArgs(options: any): string {
    if (!options) {
      return '';
    }
    let args = '?';
    Object.keys(options).forEach((key, index) => {
      args += this.optionToString(key, options[key]);
    });
    return args;
  }


  /**
   * Serializing contextual filters as a string
   *
   * @param options object of drupal parameters to serialize
   * @return string of contextual filters;
   */
  protected getContextualFilters(options: any): string {
    if (!options) {
      return '';
    }
    let str = '/';
    str += options.join('/');
    return str;
  }

    /**
     * serializing eatch option
     *
     * @param key option key
     * @param value option value
     * @return single option serilization
     */
     protected optionToString(key: string, value: any): string {
      if (!value) {
        return '';
      }
      let str = '';
      if (value instanceof Array) {
        value.forEach((element, index) => {
          str += `${key}[${index}]=${element}&`;
        });
      } else if (value instanceof Object) {
        Object.keys(value).forEach((element: string, index) => {
          if (value[element] instanceof Object) {
            str += this.serializeObject(value[element], `${key}[${element}]`);
          } else {
            str += `${key}[${element}]=${value[element]}&`;
          }
        });
      } else {
        str += `${key}=${value}&`;
      }
      return str;
    }

  /**
   * Check if variable is array of objects
   *
   * @param value array to check
   */
  private isArray(value) {
    return Object.prototype.toString.call(value) === '[object Array]';
  }

  /**
   * retrieve the variable from the connection object or if there is no connection yet it will return them from the cookies
   * this will will allow you to implement your custom connection storage, just like ionic.
   * because the cookies is not always available on webview apps, you may need to save the connection in sqllite
   * and restore them directly.
   *
   * @param variableName the name of the saved variable
   */
  private async getSavedVariable(variableName: string): Promise<any> {

    if (DrupalConstants.Connection) {
      if (DrupalConstants.Connection[variableName]) {
        return DrupalConstants.Connection[variableName];
      } else if (DrupalConstants.Connection.user && DrupalConstants.Connection.user[variableName]) {
        return DrupalConstants.Connection.user[variableName];
      }
    }
    if (!isPlatformServer(this.platformId)) {
      const val = await this.storageService.get(variableName);
      return val;
    }
    return;
  }

  /**
   * serializing the object keys
   *
   * @param obj object to serialize
   */
  private serializeObject(obj: any, parentSerialized: string): string {
    let str = '';
    Object.keys(obj).forEach((key, index) => {
      const value = obj[key];
      if (value instanceof Object) {
        str += `${this.serializeObject(value, `${parentSerialized}[${key}]`)}`;
      } else {
        str += `${parentSerialized}[${key}]=${value}&`;
      }
    });
    return str;
  }

}
