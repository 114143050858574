import { Injectable } from "@angular/core";
import { DrupalConstants, Options } from "./drupal7/public_api";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor() {}
    
    deepCopy(obj: any) {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
        
        if (Array.isArray(obj)) {
          return obj.map(item => this.deepCopy(item));
        }
        
        const newObj = {};
        for (const key in obj) {
          newObj[key] = this.deepCopy(obj[key]);
        }
        
        return newObj;
      }

        /**
   * Generates a select list range of years based on user input
   *
   * @param startYear the start year which can be a relative year
   * @param endYear the ending year which can be a relative year
   * @param reverseOrder a boolean value to reverse the order of the returned options
   * @param startRelative a boolean value to determine if the startYear is a relative date or not.
   * Defaults to true because most often you aren't setting the startYear to a specific year.
   * @param endRelative a boolean value to determin if the endYear is a relative date or not.
   * Defaults to true because most often you aren't setting the endYear to a specific year.
   * @returns Returns an array of key/value pairs of select list options
   */
  generateArrayOfYears(startYear: number, endYear: number, reverseOrder?: boolean, startRelative: boolean = true, endRelative: boolean = true): Options[] {
    const years = [];
    const minDate = startRelative ? (new Date().getFullYear() - startYear) : startYear;
    const maxDate = endRelative ? (new Date().getFullYear() + endYear) : endYear;

    if (reverseOrder) {
      for (let i = maxDate; i >= minDate; i--) {
        years.push({key: i.toString(), value: i.toString()});
      }
    } else {
      for (let i = minDate; i <= maxDate; i++) {
        years.push({key: i.toString(), value: i.toString()});
      }
    }
    return years;
  }

  addOptions(obj, keys, options) {
    if (Array.isArray(obj)) {
      obj.forEach(item => {
        this.addOptions(item, keys, options);
      });
    }
    else if (typeof obj === 'object' && obj !== null) {
      Object.getOwnPropertyNames(obj).forEach((key) => {
        if (keys.indexOf(obj[key]) !== -1) {
          obj.options = options;
        } else {
          this.addOptions(obj[key], keys, options);
        }
      });
    }
  }

    /**
   * parse an entity that comes back from a request
   * to make sure it is ready to use in the app
   *
   * @param entity the entity to be structured
   */
    cleanObject(entity: any) {
      if (entity) {
        Object.entries(entity).map(([k, v], i) => {
          let newVal = entity[k];
          if (typeof(entity[k]) === 'string') {
            newVal = entity[k].trim();
          try {
              newVal = JSON.parse(newVal);
              entity[k] = newVal;
              this.cleanObject(newVal);
            } catch (err) {
            }
            if (typeof(newVal) === 'object' && !Array.isArray(newVal) && newVal !== null) {
              Object.entries(newVal).map(([nestedKey, nestedValue], x) => {
                if (k === nestedKey) {
                  entity[k] = this.cleanObject(nestedValue);
                }
              });
            }
            if (typeof(newVal) === 'object' && Array.isArray(newVal) && newVal !== null && newVal.length === 1) {
              entity[k] = this.cleanObject(newVal);
            }
          } else if (typeof(v) === 'object' && Array.isArray(v) && v !== null && v.length === 1) {
            entity[k] = this.cleanObject(v);
          } else if (typeof(v) === 'object' && !Array.isArray(entity[k]) && entity[k] !== null) {
            this.cleanObject(entity[k]);
          } else if (typeof(v) === 'object' && Array.isArray(entity[k]) && entity[k] !== null && entity[k].length > 1) {
            for (const innerObj of entity[k]) {
              this.cleanObject(innerObj);
            }
          } else {
            this.cleanObject(newVal);
          }
        });
        return entity;
      }
    }

  /**
   * Convert an entity to an array
   *
   * @param entity the entity to be structured
   * @param keys fields to be check
   */
    convertObjToArray(entity: any, keys: Array<string>) {
      Object.getOwnPropertyNames(entity).forEach(key => {
        if (keys.indexOf(key) !== -1) {
          const arr: any = [];
          Object.keys(entity[key]).map(k => {
            if (typeof(JSON.parse(k)) === 'number') {
              const v = entity[key][k];
              arr.push(v);
            }
          });
          entity[key] = arr;
        }
      });
    }

  /**
   *
   * @param options an array of options to convert
   * @returns array of options
   */
    convertSelectOptions(options) {
      for (const option of options) {
        const dataToArray = option.value.split('|').map(item => item.trim());
        option.value = dataToArray.join('<br>');
      }
      return options;
    }

  /**
   * Remove properties from an entity
   *
   * @param entity the entity to be structured
   * @param keys fields to be removed from the entity
   */
    removeProps(entity: any, keys: Array<string>){
      if (Array.isArray(entity)) {
        entity.forEach(item => {
          this.removeProps(item, keys);
        });
      }
      else if (typeof entity === 'object' && entity !== null) {
        Object.getOwnPropertyNames(entity).forEach((key) => {
          if (keys.indexOf(key) !== -1) {
            delete entity[key];
          } else {
            this.removeProps(entity[key], keys);
          }
        });
      }
    }

  /**
   * structure a full entity for drupal request
   *
   * @param entity the entity to be structured
   * @param ignoredFields fields to be ignored just like nid or uid or title
   * @param fieldLabels: the label for each field just like "{field_custom_field: 'value'}"
   * @param language language of the entity
   */
  structureEntity(entity: any, ignoredFields: string[], fieldLabels?: any[], language?: string): any {
    Object.keys(entity).forEach((key: string, index: number) => {
      if (ignoredFields.indexOf(key) === -1) {
        let fieldLabel;
        if (fieldLabels && fieldLabels[key]) {
          fieldLabel = fieldLabels[key];
        }
        entity[key] = this.structureField(entity[key], fieldLabel, language);
      }
    });
    return entity;
  }

  /**
   * structure the field for drupal services request
   *
   * @param value the field value
   * @param label field label name
   * @param language language of the field
   */
  structureField(value: any, label: string = 'value', language?: string) {
    if (!language) {
      language = DrupalConstants.Settings.language;
    }
    const item = {};
    if (this.isArray(value)) {
      const field_array: any = [];
      for (let i = 0, l = value.length; i < l; i++) {
        item[label] = value[i];
        field_array.push(item);
      }
      return {
        [language]: field_array
      };
    }
    if (value instanceof Date) {
      const obj = {
        value: {
          date: `${value.getFullYear()}-${value.getMonth() + 1}-` +
            `${value.getDate()} ${value.getHours()}:${value.getMinutes()}:${value.getSeconds()}`
        }
      };
      return {
        [language]: [
          obj
        ]
      };
    }
    // field value given with label(s) already built
    if (typeof value === 'object') {
      return {
        [language]: [
          value
        ]
      };
    }
    item[label] = value;
    return {
      [language]: [
        item
      ]
    };
  }

    /**
   * Serializing arguments as a string
   *
   * @param options object of drupal parameters to serialize
   * @return string of parameters
   */
    protected getArgs(options: any): string {
      if (!options) {
        return '';
      }
      let args = '?';
      Object.keys(options).forEach((key, index) => {
        args += this.optionToString(key, options[key]);
      });
      return args;
    }
  
  
    /**
     * Serializing contextual filters as a string
     *
     * @param options object of drupal parameters to serialize
     * @return string of contextual filters;
     */
    protected getContextualFilters(options: any): string {
      if (!options) {
        return '';
      }
      let str = '/';
      str += options.join('/');
      return str;
    }
  
      /**
       * serializing eatch option
       *
       * @param key option key
       * @param value option value
       * @return single option serilization
       */
       protected optionToString(key: string, value: any): string {
        if (!value) {
          return '';
        }
        let str = '';
        if (value instanceof Array) {
          value.forEach((element, index) => {
            str += `${key}[${index}]=${element}&`;
          });
        } else if (value instanceof Object) {
          Object.keys(value).forEach((element: string, index) => {
            if (value[element] instanceof Object) {
              str += this.serializeObject(value[element], `${key}[${element}]`);
            } else {
              str += `${key}[${element}]=${value[element]}&`;
            }
          });
        } else {
          str += `${key}=${value}&`;
        }
        return str;
      }
  
    /**
     * Check if variable is array of objects
     *
     * @param value array to check
     */
    private isArray(value) {
      return Object.prototype.toString.call(value) === '[object Array]';
    }

    
      /**
   * serializing the object keys
   *
   * @param obj object to serialize
   */
  private serializeObject(obj: any, parentSerialized: string): string {
    let str = '';
    Object.keys(obj).forEach((key, index) => {
      const value = obj[key];
      if (value instanceof Object) {
        str += `${this.serializeObject(value, `${parentSerialized}[${key}]`)}`;
      } else {
        str += `${parentSerialized}[${key}]=${value}&`;
      }
    });
    return str;
  }

}