// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item-group.item-separate ion-item::part(native), ion-list.item-separate ion-item::part(native) {
  padding: 13px;
}

.table-striped tbody tr:nth-child(odd) {
  background-color: var(--ion-color-tint);
}

td p {
  margin: 0;
  font-weight: 500;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhYmxlLWFzc2lnbm1lbnRzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksYUFBQTtBQUNKOztBQUVBO0VBQ0ksdUNBQUE7QUFDSjs7QUFDQTtFQUNJLFNBQUE7RUFDQSxnQkFBQTtBQUVKIiwiZmlsZSI6InRhYmxlLWFzc2lnbm1lbnRzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWl0ZW0tZ3JvdXAuaXRlbS1zZXBhcmF0ZSBpb24taXRlbTo6cGFydChuYXRpdmUpLCBpb24tbGlzdC5pdGVtLXNlcGFyYXRlIGlvbi1pdGVtOjpwYXJ0KG5hdGl2ZSkge1xuICAgIHBhZGRpbmc6IDEzcHg7XG59XG5cbi50YWJsZS1zdHJpcGVkIHRib2R5IHRyOm50aC1jaGlsZChvZGQpIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1pb24tY29sb3ItdGludCk7XG59XG50ZCBwIHtcbiAgICBtYXJnaW46IDA7XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/table-assignments/table-assignments.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,uCAAA;AACJ;;AACA;EACI,SAAA;EACA,gBAAA;AAEJ;AACA,gsBAAgsB","sourcesContent":["ion-item-group.item-separate ion-item::part(native), ion-list.item-separate ion-item::part(native) {\n    padding: 13px;\n}\n\n.table-striped tbody tr:nth-child(odd) {\n    background-color: var(--ion-color-tint);\n}\ntd p {\n    margin: 0;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
