import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { ViewService } from './drupal7/drupal7-services.module';
import { ViewService as D10ViewService, UserService } from '../services/drupal10/drupal10-services.module';
import { CommonService } from './common.service';
import { ViewOptions } from './drupal7/public_api';
import { environment } from 'src/environments/environment.prod';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewResults } from './drupal10/models/view';


@Injectable({
  providedIn: 'root'
})
export class TableAssignmentsService {

    loading: HTMLIonLoadingElement;

    data: TableAssignment[] = [];
    duplicates: {[key: string]: {primary: TableAssignment, duplicates: {[key:string]: TableAssignment}}} = {};
    regions: Region[] = [];
    countries: Country[] = [];
    newNames = '';
    filters = {
      type: 'all',
      inputData: ''
    };

    tableCount = 40;
    reservedTables = 0;
    availableTables = this.tableCount - this.reservedTables;
    seatsPerTable = 10;
    currentTable = 1;
    assignMode = 'per_table';
    assignType: 'all' | 'per_region' = 'all';
    groupBySpouse = false;
    assignFailures = 0;

    showAllColumns = false;
    showRegions = false;
    showCountries = false;
    showSidebar = false;
    hasPeople = false;

    extra_business_leaders = [];
    ban_list = [];

    delegateTypesList = environment.delegateTypesList;

    groupManagerList = environment.groupManagerList;

    operatorsList = environment.operatorsList;

    missionsConnectTypes = environment.missionsConnectTypes;

    constructor(
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private viewService: ViewService,
        private d10ViewService: D10ViewService,
        private commonService: CommonService,
        private userService: UserService
    ) {
      this.reset();
    }

    async checkCheckedIn(viewOptions: ViewOptions, tables: Table[]) {
      const primaryOptions = this.commonService.deepCopy(viewOptions);
      primaryOptions.filters.checked_in = '1';
      const primaryDelegates = await this.getPrimaryDelegates(primaryOptions);
      const speakerDelegates = await this.getSpeakers(tables);

      const allDelegates = [...primaryDelegates.results, ...speakerDelegates.results];

      if (allDelegates) {
        this.data.map(v => {
          const delegate = allDelegates.find(d => d.id === v.id);
          if (delegate) {
            v.checked_in = delegate.checked_in;
          }
        });
      }
    }

    async showLoading(message: string) {
        if (!this.loading) {
          this.loading = await this.loadingCtrl.create({
            message,
            backdropDismiss: false,
          });
          await this.loading.present();
        }
      }
    
      async dismissLoading() {
        if (this.loading) {
          await this.loading.dismiss();
          this.loading = null;
        }
      }

    mapObject(obj: any) {
      return Object.keys(obj);
    }

    mapObjectValues(obj: any) {
      return Object.values(obj);
    }

    getGroupType(delegateTypes: string[]) {
      let groupType = 'Delegate';
      if (delegateTypes.includes('Missionary')) {
        groupType = 'Missionary';
      } else if (delegateTypes.includes('Professional/Business Leader')) {
        groupType = 'Business Leader';
      } else if (delegateTypes.includes('Conference Speaker')) {
        groupType = 'Conference Speaker';
      }
      return groupType;
    }

    getTableData(tableFromAssignment: Table, key: string, tables: Table[]) {
      const table = tables.find(t => t.tableNumber.toString() === tableFromAssignment.tableNumber.toString());
      if (table) {
        return table[key];
      }
      return '';
    }

    getTableColor(table: Table) {
      if (table.seats.length > this.seatsPerTable) {
        return 'danger';
      }
      return '';
    }

    async getRegions() {
      const regions = await this.viewService.getView('regions-for-table-assignments', {display_id: 'regions_for_table_assignments'});
      return regions;
    }

    async getPrimaryDelegates(viewOptions: ViewOptions) {
      const primaryDelegates = await this.viewService.getView('missions-connect', viewOptions);
      primaryDelegates?.results.map(d => d.foreign_key = 'slconferenceasia_primary');
      console.log(primaryDelegates);
      return primaryDelegates;
    }

    async getSpouseDelegates(viewOptions: ViewOptions) {
      const spouseDelegates = await this.viewService.getView('missions-connect', viewOptions);
      spouseDelegates?.results.map(d => d.foreign_key = 'slconferenceasia_spouse');
      console.log(spouseDelegates);
      return spouseDelegates;
    }

    async getPrimaryAndSpouseDelegates() {
      const primaryOptions: ViewOptions = {
        display_id: 'missions_connect_registrations_delegates',
        filters: {
          field_linked_conference_target_id: 18212,
          flagged_attending: '1',
          table_assigned: '0'
        }
      };
  
      const primary = await this.getPrimaryDelegates(primaryOptions);

      if (primary?.results) {
        const allDelegates = [...primary.results];
        console.log(allDelegates);
        return allDelegates;
      }

      return [];
    }

    async getSpeakers(tables: Table[]) {
      try {
        const res = await this.d10ViewService.getView('api/missions-connect', { display_id: 'api_missions_connect' }, false).then(res => res as ViewResults);
        res.results.map(d => d.foreign_key = 'slconference_speakers');
        return res;
      } catch (error) {
        if (error instanceof HttpErrorResponse) {
          await this.dismissLoading();

          const accessDenied = await this.alertCtrl.create({
            mode: 'ios',
            header: 'Connection Lost',
            message: 'The connection has expired. Click the button below to reset the connection.',
            buttons: [{
              text: 'Cancel',
              role: 'cancel'
              },{
              text: 'Reset Connection',
              role: 'submit',
              handler: async () => {
                await this.userService.login({name: 'volunteer1', pass: 'volunteer'});
                this.getDelegates(tables);
              },
            }],
          });
          await accessDenied.present();
        }
      }
    }

    async getDelegates(tables: Table[]) {

      await this.showLoading('Loading delegates...');

      this.reset();

      const slcAsiaDelegates = await this.getPrimaryAndSpouseDelegates();
      const speakerDelegates = await this.getSpeakers(tables);

      if (slcAsiaDelegates && speakerDelegates) {

        const allDelegates = [...slcAsiaDelegates, ...speakerDelegates.results].filter(x => x !== '');
        const data = await this.processDelegates(allDelegates, tables);

        console.log(data);
        return data;
      }
    }

    getOthersInGroup(delegate: TableAssignment) {
      const groups = {
        26685: [154], // Mike Gilbert
        23709: [202], // Rachael Gaye; 202 is Soren, may be grouped with Niranjan
        150: [151, 27531], // Tim Rabon
        201: [242, 243], // Doug Sisson
        140: [141, 261, 262], // Warren Bale
        189: [21762, 21761], // JD Howell
        179: [232, 234, 235, 236], // Clark Bosher
        168: [238, 239], // Niranjan Sundararaj
        185: [27309, 25623, 25622, 22837], // Pete Folger
        157: [263], // Gabe Ruhl
        139: [259, 260], // Clark Graham
        147: [258], // Dean Miller
        136: [264], // Carlos Navarrete
        183: [265, 266], // Will Cover
        20624: [20623], // Mannylen Elago
        181: [182, 237, 257, 160, 161, 145, 146, 162, 163] // Paul Chappell
      }

      if (groups[delegate.id]) {
        delegate.others_in_group = groups[delegate.id];
      }
    }

    async processDelegates(results: any[], tables: Table[]) {

      results.map(delegate => {
        // Fix the encoding for delegate type
        delegate.delegate_type = delegate.delegate_type.replace(/&#039;/g, "'");
        // Initially set the group type based on their delegate type
        delegate.group = this.getGroupType([delegate.delegate_type]);
        // Add the spouse group member ID from the group members array
        if (delegate.spouse_group_members?.length) {
          delegate.spouse_group_member_id = delegate.spouse_group_members[0].id;
          delegate.others_in_group.push(+delegate.spouse_group_members[0].id);
        }
      });

      // Add the other spouse group member id that didn't have a referenced spouse
      results.map(delegate => {
        const spouse = results.find(d => d.spouse_group_member_id === delegate.id);
        if (spouse && !delegate.spouse_group_member_id) {
          delegate.spouse_group_member_id = spouse.id;
        }
      });

      // Manually add the other group members
      results.map((delegate, i, arr) => {
        this.getOthersInGroup(delegate);
      });

      // Add other group members that are referenced as part of a group
      results.map((delegate, i, arr ) => {
        if (delegate.others_in_group.length) {
          const group = arr.filter(s => delegate.others_in_group.indexOf(s.id) > -1);
          if (group?.length) {
            group.forEach((p, j, g) => p.others_in_group = [delegate.id, ...g.filter(x => x.id !== p.id).map(a => a.id)]);
          }
        }
      });

      // Set the same group type for spouses that might have different delegate types
      // Ex. Husband is a Conference Speaker and the wife is a Pastor's Wife
      // This would assign them both to a Conference Speaker group to ensure they will be seated together
      if (this.assignType === 'per_region') {
        const banList = [];
        results.map((primary, i, arr) => {
          if (primary.spouse_group_member_id && primary.spouse_group_member_id !== null && this.groupBySpouse) {
            const spouse = arr.find(s => s.id === primary.spouse_group_member_id);
            if (!banList.includes(spouse?.id) && spouse !== undefined) {
              banList.push(primary.id, spouse.id);
              const groupType = this.getGroupType([primary.delegate_type, spouse.delegate_type]);
              primary.group = groupType;
              spouse.group = groupType;
            }
          }
  
          if (primary.others_in_group?.length >= 1 && !this.groupBySpouse) {
            const group = arr.filter(s => primary.others_in_group.indexOf(s.id.toString()) > -1);
            group.forEach(member => {
              if (!banList.includes(member?.id) && member !== undefined) {
                banList.push(member.id);
                const groupType = this.getGroupType([primary.delegate_type, member.delegate_type]);
                primary.group = groupType;
                member.group = groupType;
              }
            });
            banList.push(primary.id);
          }
        });
      }

      // Create the table assignments array that will set the region for each delegate based on their country
      const tableAssignments: TableAssignment[] = results.map(delegate => {
        const seat = new TableAssignment(+delegate.id, null, null);
        seat.first_name = delegate.first_name;
        seat.last_name = delegate.last_name;
        seat.country = delegate.country;
        seat.country_code = delegate.country_code;
        seat.delegate_type = delegate.delegate_type;
        seat.group = delegate.group;
        seat.gender = delegate.gender;
        seat.checked_in = delegate.checked_in;
        seat.spouse_id = delegate.spouse_group_member_id;
        seat.others_in_group = delegate.others_in_group;
        seat.foreign_key = delegate.foreign_key;
        seat.attending_missions_connect = delegate.attending_missions_connect;
        seat.table_assigned = delegate.table_assigned;
        seat.uuid = delegate.uuid;
        seat.setRegion(delegate.country_code);
        return seat;
      });

      await this.dismissLoading();

      const regions = await this.groupPeopleByRegionAndCountry(tableAssignments);

      this.assignRegions(regions.sortedRegions, tables);

      tables.forEach(table => {
        table.countries = Object.entries(table.countriesObj).map((v) => new Country(v[0], v[1]));
      });

      return {tables, regions};
    }

    splitCsv() {
      this.reset();

      const data = this.newNames.split('\n').map(nameLine => {
        const row = nameLine.trim().split('\t');
        return row;
      });

      const headers = data.shift();

      const people = data.map((personValues, i) => {
        const person = {};
        personValues.map((v, j) => {
          if (headers[j] === 'others_in_group') {
            const arr = v.split(', ').filter(x => x !== '');
            person[headers[j]] = arr;
          } else {
            person[headers[j]] = v; 
          }
        });
        return person;
      });

      const tables = this.createTables();

      return this.processDelegates(people, tables);
    }

    removeDuplicates(rows: TableAssignment[]) {
      // Filter out duplicates based on first and last name
      this.duplicates = {};

      const seen = new Set();
      const seenObj = new Set();
      const uniqueRows = rows.filter(obj => {
        const fullName = `${obj.first_name.trim().toLowerCase()} ${obj.last_name.trim().toLowerCase()}`;
        if (seen.has(fullName)) {
          const seenArray = [... seenObj];
          const primary: TableAssignment = seenArray.find((item: any) => {
            const primaryName = `${item.first_name.trim().toLowerCase()} ${item.last_name.trim().toLowerCase()}`;
            return primaryName === fullName;
          }) as TableAssignment;
          if (!this.duplicates[primary.id]) {
            this.duplicates[primary.id] = {primary: primary, duplicates: {[obj.id.toString()]: obj}};
          } else {
            this.duplicates[primary.id].duplicates[obj.id.toString()] = obj;
          }

          primary.others_in_group_aliases.push(...obj.others_in_group);

          return false;
        }
        seen.add(fullName);
        seenObj.add(obj);
        return true;
      });

      console.log(this.duplicates);

      console.log(uniqueRows);

      return uniqueRows;
    }

    async groupPeopleByRegionAndCountry(rows: TableAssignment[]) {

      const analytics = new TableAnalytics();

      const uniqueRows = this.removeDuplicates(rows);

      const finalRows = rows;

      // Adjust the number of tables based on how many are attending
    //   this.tableCount = Math.ceil(uniqueRows.length / this.seatsPerTable) + this.reservedTables;
    //   this.setAvailableTables();

      // Check if there are enough seats
      if ((this.availableTables * this.seatsPerTable) < finalRows.length) {
        const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: 'Not enough seats',
            message: "You don't have enough seats. Increase your table and seat count or check your delegate data.",
            buttons: [
                {
                    text: 'OK',
                    id: 'ok',
                    role: 'cancel'
                }
            ]
        });
        await alert.present();
      }

      // Organize delegates by country
      const regions = finalRows.reduce((acc, person) => {

        if (!acc[person.region]) {
          acc[person.region] = new Region(person.region, person.region);
        }

        if (!acc[person.region].delegateTypesObj[person.delegate_type]) {
          acc[person.region].delegateTypesObj[person.delegate_type] = new DelegateType(person.delegate_type, person.delegate_type);
        }

        if (!acc[person.region].countriesObj[person.country_code]) {
          acc[person.region].countriesObj[person.country_code] = new Country(person.country_code, person.country);
        }

        acc[person.region].tableAssignments.push(person);
        acc[person.region].delegateTypesObj[person.delegate_type].tableAssignments.push(person);
        acc[person.region].countriesObj[person.country_code].tableAssignments.push(person);

        switch (person.group) {
          case 'Business Leader':
            analytics.number_of_business_leaders++;
            acc[person.region].countriesObj[person.country_code].business_leaders.push(person);  
            break;
          case 'Missionary':
            analytics.number_of_missionaries++;
            acc[person.region].countriesObj[person.country_code].missionaries.push(person);  
            break;
          case 'Conference Speaker':
            analytics.number_of_speakers++;
            acc[person.region].countriesObj[person.country_code].speakers.push(person);  
            break;
          default:
            analytics.number_of_delegates++;
            acc[person.region].countriesObj[person.country_code].delegates.push(person);  
            break;
        }

        switch (person.delegate_type) {
          case 'Pastor':
          case "Pastor's Wife":
            analytics.number_of_pastors++;
            break;
        }

        switch (person.gender) {
          case 'Male':
            analytics.male++;
            break;
          case 'Female':
            analytics.female++;
            break;
        }

        if (person.group === 'Missionary' && person.country_code === 'US') {
          analytics.us_missionary++;
        }
        if (person.group === 'Missionary' && person.country_code !== 'US') {
          analytics.non_us_missionary++;
        }
        if (person.group === 'Business Leader' && person.country_code === 'US') {
          analytics.us_business_leader++;
        }

        analytics.total++;

        return acc;
      }, {});

      const sortedRegions: Region[] = Object.values(regions);
      for (const region of sortedRegions) {
        region.countries = Object.values(region.countriesObj);
        region.delegateTypes = Object.values(region.delegateTypesObj);

        region.countries.sort((a,b) => b.tableAssignments.length - a.tableAssignments.length);

        this.countries.push(...region.countries);
      }

      // this.countries.sort((a, b) => a.id.localeCompare(b.id));
      this.countries.sort((a, b) => b.tableAssignments.length - a.tableAssignments.length);

      analytics.countries_represented = this.countries.length;

      sortedRegions.sort((a, b) => b.tableAssignments.length - a.tableAssignments.length);

      return {sortedRegions, analytics};

    }

    async groupByTable(rows: TableAssignment[]) {
      const tablesObj = rows.reduce((acc, row, i) => {
          if (!acc[row.table]) {
              acc[row.table] = new Table((+row.table - 1).toString(), row.country, row.country_code, row.region, this.seatsPerTable, +row.table-1);
          }
          acc[row.table].delegates.push(row);
          return acc;
      }, {});
      const tables: Table[] = Object.values(tablesObj);
      
      tables.map((table, i) => {
        table.delegates.sort((a,b) => +a.seat_number - +b.seat_number);
        table.delegates.forEach((delegate, i) => {
          const seatIndex = table.seats.findIndex(seat => seat.table === delegate.table && seat.seat_number === delegate.seat_number);
          if (seatIndex > -1 && !table.seats[seatIndex].uuid) {
            table.seats[seatIndex] = delegate;
          } else {
            table.seats.push(delegate);
          }
        });
      });

      const regions =  await this.groupPeopleByRegionAndCountry(rows);

      tables.sort((a: Table, b: Table) => a.tableNumber - b.tableNumber);
      return {tables, regions};
    }

    assignRegions(regions: Region[], tables: Table[]) {
      this.currentTable = 1;
      const tableAssignments = [
        { type: 'business_leaders', mode: 'per_table' },
        { type: 'speakers', mode: 'per_table' },
        { type: 'missionaries', mode: 'missionaries' },
        { type: 'delegates', mode: 'delegates' }
      ];

      const allDelegates = regions.flatMap(region => {
        region.countries.sort((a: Country, b: Country) => b.tableAssignments.length - a.tableAssignments.length);
        return region.tableAssignments;
      });

      if (this.assignType === 'all') {
        allDelegates.forEach((person, i, arr) => {
          this.assignTable(person, arr, this.currentTable, 'per_seat', tables);
        });
      }
      
      if (this.assignType === 'per_region') {
        tableAssignments.forEach((assignment, index) => {
          if (index === 2) this.currentTable = 1; // Reset before `missionaries` & `delegates`
        
          regions.forEach(region => {
            this.assignTablesByRegion(region, assignment.type, false, assignment.mode, tables, allDelegates);
          });
        
          if (index === 0 || index === 1) this.currentTable = 1; // Reset after `business_leaders` & `speakers`
        });
      }


      this.regions = regions;

      tables.map((table, i) => {
        table.seats.forEach((v, i) => {
          if (table.delegates[i]) {
            table.seats[i] = table.delegates[i];
          }
        });
      });

      this.hasPeople = true;
    }

    assignTablesByRegion(region: Region, type: string, resetTable: boolean, assignMode: 'per_table' | string, tables: Table[], tableAssignments: TableAssignment[]) {
      
      if (resetTable) {
        this.currentTable = 1;
      }
      region.countries.sort((a: Country, b: Country) => b[type].length - a[type].length);
      region.countries.forEach((country) => {
        country[type].forEach(person => {
          this.assignTable(person, tableAssignments, this.currentTable, assignMode, tables);
        });
      });
    }

    assignTable(assignee: TableAssignment, countryTableAssignments: TableAssignment[], tableNumber: number,
      assignMode: string, tables: Table[], previousTable?: number, returnToPrevious: boolean = false) {

      if (previousTable && returnToPrevious) {

      }

      if (this.ban_list.includes(assignee.id)) {
        console.log(`SKIPPED, already added ID ${assignee.id} to a table.`);
        return;
      }
      let currentTableIndex = tableNumber - 1;

      // Set the region for the table
      if (!tables[currentTableIndex].region) {
        tables[currentTableIndex].region = assignee.region;
      }
      
      // Set the country for the table
      if (!tables[currentTableIndex].country) {
        tables[currentTableIndex].country = assignee.country;
      }

      // Add representation of the country to the table
      if (!tables[currentTableIndex].countriesObj[assignee.country_code]) {
        tables[currentTableIndex].countriesObj[assignee.country_code] = assignee.country;
      }

      // Add representation of the region to the table
      if (!tables[currentTableIndex].regions[assignee.region]) {
        tables[currentTableIndex].regions[assignee.region] = assignee.region;
      }

      // Check if the assignee has a group of people sitting with them
      let group: TableAssignment[] = [];
      if (assignee.others_in_group.length >= 1) {
        group = countryTableAssignments.filter(s => assignee.others_in_group.includes(s.id));
      }

      // Check if the assignee has a spouse
      const spouse = assignee.spouse_id 
      ? countryTableAssignments.find(person => person.id === assignee.spouse_id)
      : null;

      // Determine required seats (1 if no spouse, 2 if spouse exists)
      // const requiredSeats = spouse ? 2 : 1;

      const requiredSeats = group.length >= 1 ? (1 + group.length) : 1;
      const availableSeats = this.seatsPerTable - tables[currentTableIndex].delegates.length;

      if (requiredSeats > 2) {
        console.log(`need to find ${requiredSeats} seats for ${assignee.first_name} - ${assignee.id}`);
      }

      // If not enough space, find the next available table that has enough seats
      if (availableSeats < requiredSeats && !this.groupBySpouse) {
        console.log(`Table ${tableNumber} does not have ${requiredSeats} seats for ${assignee.first_name} - ${assignee.id}. Finding the next available table.`);

        // TODO: needs to get the next available table starting
        // with the current table and working forward instead of
        // starting back at table 1
        const availableTable = tables.find(t => {
          const availSeats = this.seatsPerTable - t.delegates.length;
          return requiredSeats <= availSeats;
        });
        if (availableTable) {
          console.log('current table', tableNumber);
          console.log('found a table:', availableTable.tableNumber);
          const newTableNumber = availableTable.tableNumber;
          this.assignTable(assignee, countryTableAssignments, newTableNumber, assignMode, tables, tableNumber, true);
          return;
        } else {
          console.error(`No table available for ${assignee.id} with ${requiredSeats}`);
        }
      }

      // If not enough space, check if the next table has enough space
      if (availableSeats < requiredSeats && this.groupBySpouse) {
        if (spouse) {
          console.log(`Table ${tableNumber} does not have ${requiredSeats} seats for ${assignee.first_name} and ${spouse.first_name}. Moving to the next table.`);
        } else {
          console.log(`Table ${tableNumber} does not have ${requiredSeats} seat for ${assignee.first_name}. Moving to the next table.`);
        }
        this.incrementTable();
        this.assignTable(assignee, countryTableAssignments, this.currentTable, assignMode, tables, tableNumber);
        return;
      }

      // Assign assignee to the current table
      tables[currentTableIndex].delegates.push(assignee);
      assignee.table = tableNumber.toString();
      assignee.seat_number = tables[currentTableIndex].delegates.length.toString();
      // console.log(`MODE: ${assignMode} Assigned Table ${this.currentTable} to ${assignee.first_name}`);
      this.ban_list.push(assignee.id);

      // Assign others in group only if they exist and have not been assigned
      if (requiredSeats > 1 && group.length >= 1 && !this.groupBySpouse) {
        group.forEach(person => {
          if (!this.ban_list.includes(person.id)) {
            console.log(`Assigning person ${person.first_name} ${person.last_name} with ${assignee.first_name} ${assignee.last_name} to table ${tableNumber}`);
            tables[currentTableIndex].delegates.push(person);
            person.table = tableNumber.toString();
            person.seat_number = tables[currentTableIndex].delegates.length.toString();
            this.ban_list.push(person.id);
          }
        });
      }

      // Assign spouse only if they exist and have not been assigned yet
      if (spouse && !this.ban_list.includes(spouse.id) && this.groupBySpouse) {
        console.log(`Assigning spouse ${spouse.first_name} ${spouse.last_name} with ${assignee.first_name} ${assignee.last_name} at table ${tableNumber}`);

        tables[currentTableIndex].delegates.push(spouse);
        spouse.table = tableNumber.toString();
        spouse.seat_number = tables[currentTableIndex].delegates.length.toString();
        this.ban_list.push(spouse.id);
      }

      // Move to the next table after assigning both
      if (assignMode === 'per_table') {
        this.incrementTable();
      }
      return;
    }

    incrementTable() {
      this.currentTable++;
      if (this.currentTable > this.availableTables) {
        this.currentTable = 1;
      }
    }

    goToTable(tableNumber: number) {
      this.currentTable = tableNumber;
      if (this.currentTable > this.availableTables) {
        this.currentTable = 1;
      }
    }

    totalFiltered(tables: Table[]) {
      return tables.reduce((t, v) => {
        return t + v.seats.filter(s => !s.hidden).length;
      }, 0);
    }

    filterResults(filters: any, tables: Table[]) {
      tables.forEach(table => {
        let tableHidden = false;
        table.seats.forEach(person => {
          let hidden = false;
      
          Object.keys(filters).forEach(k => {
            const value = filters[k];
      
            if (k === 'type') {
              if (value === '' && person.group !== value) {
                hidden = true;
              } else if (value !== 'all' && person.group.toLowerCase().indexOf(value.toLowerCase()) === -1) {
                hidden = true;
              }
            }

            if (k === 'table') {
              if (value !== '' && person.table !== value) {
                hidden = true;
              }
            }

            if (k === 'assigned' && value !== 'all' && person.table_assigned !== value) {
              hidden = true;
            }
      
            if (k === 'inputData' && value) {
              const fullName = `${person.first_name} ${person.last_name}`;
              if (fullName.toLowerCase().indexOf(value.toLowerCase()) === -1) {
                hidden = true;
              }
            }
          });
      
          person.hidden = hidden;
        });
        if (table.seats.every((v) => v.hidden === true)) {
          tableHidden = true;
        }
        table.hidden = tableHidden;
      });
    }
    
    createTables() {
      this.availableTables = this.tableCount - this.reservedTables;
      const tables = Array.from({ length: this.availableTables }, (v, k) => (new Table((1+ k).toString(), '', '', '', this.seatsPerTable, k)));
      
      return tables;
    }

    reset() {
      this.data = [];
      this.currentTable = 1;
      this.assignMode = 'per_table';
      this.ban_list = [];
      this.countries = [];
      this.regions = [];
      this.duplicates = {};
    }
}

export class TableAssignment {
    nid: number = 0;
    id: number = 0;
    first_name: string = '';
    last_name: string = '';
    country: string = '';
    country_code: string = '';
    delegate_type: string = '';
    gender: string = '';
    group: string = ''; // This is the grouping to help sort out delegates who have spouses that need to be kept together
    region: string = '';
    region_id?: string = '';
    spouse_id?: number = null;
    checked_in?: string = '';
    foreign_key?: string = '';
    attending_missions_connect: string = '';
    table_assigned?: string = '';
    uuid?: string = '';

    table?: string;
    seat_number?: string;
    hidden?: boolean = false;
    others_in_group?: number[] = [];

    others_in_group_aliases?: number[] = [];

    constructor(id:number, tableNumber: number, seatNumber: number) {
      this.nid = id;
      this.id = id;
      this.seat_number = (1 + seatNumber).toString();
      this.table = (1+tableNumber).toString();
    }

    setRegion(country: string) {
        regionsList.map(region => region.countries_array = region.countries.split(', '));
        const region = regionsList.find(region => region.countries_array.indexOf(country) > -1);
        if (region) {
          this.region_id = region.id;
          this.region = region.region;
        } else {
          this.region_id = '999';
          this.region = 'International';
        }
    }

}

export class TableAnalytics {
  total = 0; // 1. How may are registered for missions connect?
  number_of_delegates = 0; // Other Delegate types
  number_of_business_leaders = 0; // 6. How many round table businessmen and wives
  number_of_speakers = 0; // How many speakers and wives for missions connect
  number_of_missionaries = 0; // how many missionaries are bringing wives to missions connect
  number_of_pastors = 0; // 9. Pastors and wives
  male = 0; // 5. How many out of the total registration are male or female?
  female = 0; // 5. How many out of the total registration are male or female?
  us_missionary = 0; // 2. how many are registered missionaries form us
  non_us_missionary = 0; // 8. Missionaries and wives from outside us
  us_business_leader = 0; // 3. how many businessmen from us
  bible_college_students = 0; // 10. Bible college students
  countries_represented = 0;
  constructor() {}
}

export class Table {
  id: string;
  tableNumber: number;
  country: string;
  countriesObj?: {[key: string]: string} = {};
  countries?: Country[];
  region: string;
  regions?: {[key: string]: string} = {};
  seats?: TableAssignment[] = [];
  delegates?: TableAssignment[] = [];
  hidden?: boolean = false;

  constructor(id: string, country: string, countryCode: string, region: string, numberOfSeats: number, tableNumber: number) {
    this.id = id;
    this.country = country;
    this.region = region;
    this.tableNumber = (1 + tableNumber);
    this.seats = Array.from({length: numberOfSeats}, (v, seatNumber) => {
      const seat = new TableAssignment(0, tableNumber, seatNumber);
      seat.setRegion(countryCode);
      return seat;
    });
  }

  assignSeat(seat: TableAssignment) {
    const foundSeat = this.seats.find(s => s.id === seat.id);
    if (!foundSeat) {
      this.seats.push(seat);
    }
  }
}

export class Region {
    id: string;
    name: string;
    countriesObj?: {[key: string]: Country} = {};
    countries?: Country[];
    tableAssignments?: TableAssignment[] = [];
    delegateTypes?: DelegateType[] = [];
    delegateTypesObj?: {[key: string]: DelegateType} = {};
    
    constructor(id: string, name: string) {
      this.id = id;
      this.name = name;
    }
}

export class DelegateType {
  id: string;
  name: string;
  tableAssignments?: TableAssignment[] = [];

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class Country {
    id: string;
    name: string;
    tableAssignments?: TableAssignment[] = [];
    business_leaders?: TableAssignment[] = [];
    speakers?: TableAssignment[] = [];
    missionaries?: TableAssignment[] = [];
    delegates?: TableAssignment[] = [];

    region: string = '';
    region_id?: string = '';

    constructor(id: string, name: string) {
      this.id = id;
      this.name = name;
      this.setRegion();
    }

    setRegion() {
      regionsList.map(region => region.countries_array = region.countries.split(', '));
      const region = regionsList.find(region => region.countries_array.indexOf(this.id) > -1);
      if (region) {
        this.region_id = region.id;
        this.region = region.region;
      } else {
        this.region_id = '999';
        this.region = 'International';
      }
    }
}

export const regionsList = [
  {
      "id": '28678',
      "region": "Group 1 - Asian",
      "countries": "BN, KH, ID, LA, MY, MM, PG, SG, TH, VN",
      "countries_array": []
  },
  {
      "id": '28680',
      "region": "Group 2 - East Asia and Philippines",
      "countries": "CN, JP, MN, PH, KR, TW, TL",
      "countries_array": []
  },
  {
      "id": '28682',
      "region": "Group 3 - Middle East, Central, and South Asia",
      "countries": "AM, BH, BD, BT, IN, IR, IL, KZ, KW, PK, LK, AE",
      "countries_array": []
  },
  {
      "id": '28683',
      "region": "Group 4 - Africa",
      "countries": "DZ, CG, CD, ET, KE, MG, MW, MZ, RW, SL, TZ, ZM, ZW",
      "countries_array": []
  },
  {
      "id": '28684',
      "region": "Group 5 - Americas, Europe, AU, NZ",
      "countries": "AR, AU, BZ, BO, CA, CL, CR, GT, NZ, NO, CH, UA, US",
      "countries_array": []
  }
]