import { NgModule, APP_INITIALIZER } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { MainService } from './main/main.service';
import { ViewService } from './view/view.service';
import { SystemConnection } from './models/system';
import { UserService } from './user/user.service';
import { SystemService } from './system/system.service';
import { GroupMemberService } from './group_member/group_member.service';

/**
 * implement APP_INITIALIZER
 *
 * @param systemService system service to connect
 */
export function init(systemService: SystemService): () => Promise<SystemConnection> {
  return () => {
    const connectionObservable = systemService.connect(true);
    return connectionObservable;
  };
}

@NgModule({
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    MainService,
    SystemService,
    UserService,
    ViewService,
    GroupMemberService,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [SystemService],
      multi: true
    }
  ],
})
export class Drupal10ServicesModule { }

export {
  MainService,
  ViewService,
  UserService,
  GroupMemberService,
  SystemService
};
