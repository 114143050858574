import { DrupalFormControlObject } from "../drupal7/public_api";

export class FieldGroup {
    field_name: string;
    label: string;
    description: string;
    type: string;
    ionic_type: string;
    status: boolean;
    access: boolean;
    visible: boolean;
    fields?: DrupalFormControlObject[] = [];
    constructor(name, label, description, type, ionic_type, status, access, visible, fields) {
      this.field_name = name;
      this.label = label;
      this.description = description;
      this.type = type;
      this.ionic_type = ionic_type;
      this.status = status;
      this.access = access;
      this.visible = visible;
      this.fields = fields;
    }
  }