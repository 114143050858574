
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { DrupalConstants } from '../application/drupal-constants';
import { SystemConnection } from '../models';

/**
 * system service for drupal
 */
@Injectable()
export class SystemService extends MainService {
  override entityType = 'session';

  /**
   * if there is already a token in the browser cookies and it is not expired it will return it
   * this will fetch a new token before trying to connect
   *
   * @param refreshToken boolean to force the application to request new token
   * @return promise of the connect method
   * the promise data is an object of SystemConnection interface
   */
  async connect(refreshToken: boolean = false): Promise<SystemConnection> {
    if (await this.isConnectionExpired()) {
      this.removeSession();
    }
  
    if (!DrupalConstants.Connection || !DrupalConstants.Connection.csrf_token || refreshToken) {

      const csrf_token = await this.get('token', false);
      const connection = await this.getSession();
      if (connection?.csrf_token) {
        connection.csrf_token = csrf_token;
        this.saveSessionToken(connection);
        return connection;
      }
    }
  
  }
}
