import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { Pager, ViewOptions, ViewResults } from '../models/view';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * view service for drupal using services_views module
 *
 * @see https://www.drupal.org/project/services_views
 */
@Injectable()
export class ViewService extends MainService {
  override entityType = '';

  /**
   * impelemnt retrieve resource
   *
   * @param viewMachineName view machine name
   * @param options parameters of filteration for this view
   * @param presentAlert present an alert if there is an error. Defaults to true.
   * @return array of view rows OR view html code if the options format_output is 1
   */
  async getView(viewMachineName: string, options?: ViewOptions, presentAlert: boolean = true): Promise<ViewResults | HttpErrorResponse> {
    try {
      let results;
  
      // If no options, get the default results
      if (!options) {
        results = await this.get(viewMachineName, presentAlert);
        return this.processView(results);
      }
  
      // Process arguments & filters
      const args = this.getContextualFilters(options.args);
      const filters = this.getArgs(options.filters);
      results = await this.get(viewMachineName + args + filters, true, presentAlert);
  
      // Modify results if necessary
      if (options.remove_props) {
        this.removeProps(results, options.remove_props);
      }
      if (options.convert_options && results.length) {
        this.convertSelectOptions(results);
      }
  
      // Handle errors
      if (results.status && results.ok === false) {
        throw results as HttpErrorResponse;
      }
  
      // Process and return view results
      return this.processView(results, options);
    } catch (error) {
      throw error as HttpErrorResponse;
    }
  }
  
  processView(res: any, options?: ViewOptions) {
    if (res.status && res?.ok === false) {
      return res;
    }

    if (!res) return;

    const results: any[] = res.results ? res?.results : res;

    if (results.length === 0) {
      return;
    }
    
    const pager: Pager = res?.pager;
    if (pager) {
      pager.links = Array.from({ length: pager.total_pages }, (_, i) => i);
    }

    this.removeProps(results, ['pager']);
    if (options?.obj_to_arr_props?.length) {
      for (const r of results) {
        this.convertObjToArray(r, options.obj_to_arr_props);
      }
    }
    const headers = Object.keys(results[0]);

    const viewResults: ViewResults = {
      headers,
      pager,
      results,
      filters: res?.filters,
      sorters: res?.sorters,
      system: res?.system
    };
    
    return viewResults;

  }
  
}
