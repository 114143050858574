import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PrintBadgeService {

    constructor() {}

    a6Styles() {
        const page = BadgePaperSize.a6;

        const styles = `<style>
        .badge ion-col {
            flex: none;
            font-family: "avenir next", sans-serif;
            margin: 0rem 0.5rem 2rem;
            position: relative;
            width: ${page.width};
            height: ${page.height};
            background-image: url('${page.backgroundImage}') !important;
            background-repeat: no-repeat;
            background-size: 100% !important;
            padding: 0;
            border: 0.1rem solid #cacaca;
            overflow: hidden;
            display: flex;
            flex-flow: column;
            color: black;
        }
        .badge ion-col .badge-name {
            position: relative;
            top: ${page.badgeName.top};
            font-size: ${page.badgeName.fontSize};
            line-height: ${page.badgeName.lineHeight};
            font-weight: 700;
            margin-inline-start: 1cm;
            min-height: 0.85in;
            text-transform: uppercase;
            color: black;
        }
        .badge ion-col .badge-name .first-name {
            margin-bottom: 5px;
        }
        .badge ion-col .badge-name .first-name, .badge ion-col .badge-name .last-name {
            display: block;
        }
        .badge ion-col .seat-number {
            font-size: ${page.seatNumber.fontSize};
            font-weight: 700;
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            top: ${page.seatNumber.top};
            margin-bottom: 10px;
        }
        .badge ion-col .seat-number .seat-icon {
            max-width: 18pt !important;
            margin-right: 3pt;
        }
        .badge ion-col .church-name {
            font-size: ${page.churchName.fontSize};
            text-transform: uppercase;
            position: relative;
            top: ${page.churchName.top};
        }
        .badge ion-col .delegate-type {
          font-size: ${page.delegateType.fontSize};
          font-weight: 700;
          position: relative;
          top: ${page.delegateType.top};
          margin-bottom: 0px;
          margin-inline-start: 1cm;
        }
        .badge ion-col .delegate-type .seat-icon {
            max-width: 18pt !important;
            margin-right: 3pt;
        }
        .badge ion-col .country-name {
            font-size: ${page.countryName.fontSize};
            text-transform: uppercase;
            position: relative;
            top: ${page.countryName.top};
            margin-inline-start: 1cm;
        }
        .badge ion-col .qr-code {
            position: relative;
            top: ${page.qrCodeWrapper.top};
            display: flex;
            justify-content: space-between;
            margin-inline-start: 1cm;
            margin-inline-end: .5cm;
            align-items: baseline;
            margin-block-start: 1rem;
        }    
        .badge ion-col .entity-id {
            display: flex;
            ${page.qrCodeValue.rotateZ ? 'transform: rotateZ(180deg);' : ''}
            font-size: ${page.qrCodeValue.fontSize};
            font-weight: 700;
            position: relative;
            top: ${page.qrCodeValue.top};
            justify-content: center;
        }
        .badge ion-col .qrcodeImage,
        .badge ion-col ngx-qrcode {
              max-width: ${page.qrCodeImage.maxWidth} !important;
              margin-inline-start: -.2cm;
        }
        .badge ion-col .order-id {
              font-size: ${page.badgeIdentifier.fontSize};
              font-weight: 700;
              display: block;
              position: relative;
              bottom: ${page.badgeIdentifier.bottom};
        }
        .hidden-print, 
        .ion-hide-print {display:none !important;}
    
        @media print {
            .badge {
                display: block;
                flex-flow: column;
                margin: 0;
          }
            .badge ion-col {
                float: none;
                page-break-after: always;
                border: none;
                padding: 0rem;
                margin: 0 auto;
                display: block;
          }
            .badge ion-col:last-child {
                page-break-after: auto;
          }
          .hidden-print, 
          .ion-hide-print {display:none !important;}
        }</style>`;

        return styles;
    }

    async printPage(documentTitle: string, innerHTML: string, paperSizeStyles: string) {

          const popupWinWidth = 1000;
          const popupWinHeight = 1000;
          const pageTitle = 'PRINT';
          const left = (screen.width - popupWinWidth) / 2;
          const top = (screen.height - popupWinHeight) / 4;
          const printBadges = window.open('', pageTitle,
              'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left);
    
          printBadges.document.write('<html><head><title>' + documentTitle  + '</title>');
          printBadges.document.write(paperSizeStyles);
          printBadges.document.write('</head><body class="badge">');
          printBadges.document.write(innerHTML);
          printBadges.document.write('</body></html>');
    
          printBadges.document.close(); // necessary for IE >= 10
          printBadges.focus(); // necessary for IE >= 10*/
    
          await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
          printBadges.print();
          printBadges.close();
      }
}

export class BadgeTemplate {
    width: string;
    height: string;
    backgroundImage: string;

    badgeName: BadgeStyleAttributes;
    seatNumber?: BadgeStyleAttributes;
    churchName?: BadgeStyleAttributes;
    delegateType?: BadgeStyleAttributes;
    countryName?: BadgeStyleAttributes;
    qrCodeWrapper: BadgeStyleAttributes;
    qrCodeValue?: BadgeStyleAttributes;
    qrCodeImage: BadgeStyleAttributes;

    // This would be the unique identifier that helps with the sorting of this badge
    badgeIdentifier: BadgeStyleAttributes;

    constructor(width: string, height: string, backgroundImage: string) {
      this.width = width;
      this.height = height;
      this.backgroundImage = backgroundImage;
    }
}

export class BadgePaperSize {

  constructor() {}

  static get a6(): BadgeTemplate {
    const badge = new BadgeTemplate('10.5cm', '14.8cm', environment.badgeTemplate);

    const top = '2.4in';

    badge.badgeName = {
        fontSize: '24pt',
        lineHeight: '26pt',
        top
    },
    badge.seatNumber = {
        fontSize: '24pt',
        top
    },
    badge.churchName = {
        fontSize: '10pt',
        top: '2.05in',
    },
    badge.delegateType = {
        fontSize: '16pt',
        top
    },
    badge.countryName = {
        fontSize: '10pt',
        top
    },
    badge.qrCodeWrapper = {
        top: '2.3in'
    },
    badge.qrCodeValue = {
        rotateZ: false,
        fontSize: '10pt',
        top: '3.75in'
    },
    badge.qrCodeImage = {
        maxWidth: '.7in'
    },
    badge.badgeIdentifier = {
        fontSize: '11pt',
        bottom: '.2cm'
    }

    return badge;
  }

}

export interface BadgeStyleAttributes {
  fontSize?: string;
  lineHeight?: string;
  top?: string;
  bottom?: string;
  maxWidth?: string;
  rotateZ?: boolean;
}