import { Component, Input, OnInit } from '@angular/core';
import { PaymentService, PaymentMethodOptions } from '../services/payment.service';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { MessagesService } from '../services/messages.service';
import { CommerceServiceCustom } from '../services/commerce.service';
import { CommonModule, formatCurrency } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { CardSwipeService } from '../services/cardswipe.service';
import { FlagServiceCustom } from '../services/flag.service';
import { APICommerceOrder, CommerceCustomerProfile, CommerceOrderStatus, CommercePaymentTransactionStatus, CommerceProduct, ElavonCreditCardTransaction, ElavonTransaction, FlagIonic, Payment, PaymentMethod, PostalAddress, SystemConnection, User, ViewOptions } from '../services/drupal7/models';
import { StorageService } from '../services/storage/storage.service';
import { CommerceCustomerProfileService, CommerceOrderService, UserService } from '../services/drupal7/drupal7-services.module';
import { FormsService } from '../services/drupal-forms/forms.service';
import { ElavonService } from '../services/drupal7/commerce_elavon/elavon.service';
import { EntityServiceCustom } from '../services/entity.service';
import { UserServiceCustom } from '../services/user.service';
import { ManualPaymentMethodComponent } from '../manual-payment-method/manual-payment-method.component';
import { CommercePaymentTransactionsComponent } from './commerce-payment-transactions/commerce-payment-transactions.component';

@Component({
  selector: 'app-payment',
  templateUrl: 'payment.page.html',
  styleUrls: ['payment.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, ManualPaymentMethodComponent, CommercePaymentTransactionsComponent]
})
export class PaymentPage implements OnInit {

  paymentMethods: PaymentMethod[];
  maxDate = (new Date().getFullYear() + 7).toString();
  minDate = (new Date().getFullYear()).toString();

  payment: Payment;
  products: CommerceProduct[];
  showProducts: boolean;
  swipe_buffer = '';
  flags: FlagIonic[];
  userFlags: FlagIonic[];
  flagsByType: FlagIonic[];
  orderStatus = CommerceOrderStatus;
  remoteId: string = 'slc_app';

  @Input() order: APICommerceOrder = null;
  @Input() userSession: SystemConnection;

  constructor(
    private message: MessagesService,
    private elavonService: ElavonService,
    private cardSwipe: CardSwipeService,
    private flag: FlagServiceCustom,
    private entityService: EntityServiceCustom,
    private userService: UserService,
    private userServiceCustom: UserServiceCustom,
    private commerce: CommerceServiceCustom,
    private commerceOrderService: CommerceOrderService,
    private commerceCustomerProfileService: CommerceCustomerProfileService,
    private paymentService: PaymentService,
    private forms: FormsService,
    private alertCtrl: AlertController,
    private modalController: ModalController,
    public storageService: StorageService
    ) {
    this.paymentMethods = this.paymentService.getActivePaymentMethods('access');
    this.products = this.commerce.getActiveProducts();
    this.payment = new Payment('elavon_direct');
    console.log('listening for card swipe');
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
      // this.swipe(evt);
    });
    this.userServiceCustom.currentSession.subscribe(user => {
      if (user) {
        this.userSession = user;
      }
    });
  }

  async ngOnInit() {
    console.log(this.userSession);
    console.log(this.order);
    this.flags = await this.storageService.get('flags_'+environment.checkInType.bundle);
    this.userFlags = await this.storageService.get('flags_user');
    this.flagsByType = await this.storageService.get('flags_by_type').then(res => res.priceFlags);
    console.log(this.flagsByType);
    this.buildOrder(this.order);
  }

  checkFlagDisabled(flag: any, entity: any) {
    return this.flag.checkFlagDisabled(flag, entity);
  }


  // async swipe(event) {
  //   // add global variable to check listener
  //   const res = await this.cardSwipe.cardSwipe(event);
  //   if (res) {
  //     const dateFormat = `${res.cardExpYear}-${res.cardExpMonth}-01T00:00:00-07:00`;
  //     this.paymentControls.controls.exp_month.setValue(dateFormat);
  //     this.paymentControls.controls.exp_year.setValue(dateFormat);
  //     // Show the expiration date
  //     this.showDatePicked(this.paymentJSON[0].fields[1]);
  //     this.showDatePicked(this.paymentJSON[0].fields[2]);

  //     this.paymentControls.controls.card_number.setValue(res.cardNum);
  //     this.billingControls.controls.commerce_customer_address['controls'].first_name.setValue(res.cardFirst);
  //     this.billingControls.controls.commerce_customer_address['controls'].last_name.setValue(res.cardLast);
  //   }
  // }

  segmentChanged(paymentType) {
    this.payment.payment_method = paymentType.value;
    for (const paymentMethod of this.paymentMethods) {
      paymentMethod.fill = paymentMethod.value === paymentType.value ? 'solid' : 'outline';
    }
    console.log(this.payment);
  }

  async switchRoles(order: APICommerceOrder) {
    const orderOwner = order.order_owner[0];
  
    if (orderOwner.hasRegularRole) {
      await this.switchUserRole(order, environment.user.churchMemberRole, 'Switching order LBC church member pricing');
    } else if (orderOwner.hasChurchRole) {
      await this.switchUserRole(order, environment.user.regularUserRole, 'Switching order to regular delegate pricing');
    } else {
      this.message.presentAlert('Access Denied', 'The role for this order owner cannot be modified.');
    }
  }
  
  private async switchUserRole(order: APICommerceOrder, newRole: string, loadingMessage: string) {
    await this.message.showLoading(loadingMessage, true, 2100);
    const user: User = {
      uid: order.uid,
      mail: order.order_owner[0].mail,
      roles: [newRole]
    };
    const updatedUser = await this.userService.updateUser(user);
    console.log(updatedUser);
    if (updatedUser) {
      await this.reloadOrder();
    }
  }

  calculatePrice(product: CommerceProduct) {
    const price = product.quantity * product.commerce_price;
    return formatCurrency(parseFloat(price.toFixed(2)) / 100, 'en-US', '$');
  }
  

  async addProductToCart(product: CommerceProduct) {
    this.commerce.addProductToCart(product, this.order).then(async lineItem => {
      await this.reloadOrder();
    });
  }

  async deleteLineItem(lineItem) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Remove',
      message: 'Are you sure you want to remove ' + lineItem.line_item_title + ' from Order ' + this.order.order_id + '? This cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'DELETE',
          cssClass: 'primary',
          handler: async () => {
            await this.commerce.deleteLineItem(lineItem.line_item_id);
            await this.reloadOrder();
          }
        }
      ]

    });
    await alert.present();
  }

  overrideAmountConfirm() {
    console.log(this.payment);
    this.paymentService.overrideAmountConfirm(this.payment, this.order);
  }

  async reloadOrder() {
    this.payment = null;
    this.message.showLoading('Refreshing the order... ', false, 2000);
    this.payment = new Payment('elavon_direct');
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [this.order.order_id],
    };
    const foundOrder = await this.entityService.getViewWithOptions(`registration/commerce-order`, options);
    if (foundOrder?.results.length && foundOrder?.results[0]?.order_id === this.order.order_id) {
      this.order = foundOrder.results[0];
    }
    return this.buildOrder(this.order);
  }

  showPayment() {
    console.log(this.payment);
  }

  buildOrder(res: APICommerceOrder) {
      // Setup the line item total
      if (res.commerce_line_items?.length) {
        let lineItemsTotal = 0;
        for (const lineItem of res.commerce_line_items) {
            lineItemsTotal += lineItem.commerce_total;
        }
        res.commerce_line_items_amount = lineItemsTotal;
        res.commerce_line_items_amount_formatted = formatCurrency(parseFloat(res.commerce_line_items_amount.toFixed(2)) / 100, 'en-US', '$');
      }
    
      // Setup the payment transactions
      if (res.commerce_payment_transactions?.length) {
        let transactionsTotal = 0;
        for (const transaction of res.commerce_payment_transactions) {
          if (transaction.status === CommercePaymentTransactionStatus.success) {
            const amount = Number(transaction.amount.toString().replace(/[^0-9.-]+/g,''));
            transactionsTotal += amount;
          }
        }
        res.commerce_order_payments_amount = +transactionsTotal.toFixed(2).replace('.', '');
        res.commerce_order_payments_amount_formatted = formatCurrency(parseFloat(res.commerce_order_payments_amount.toFixed(2)) / 100, 'en-US', '$');
      }

      if (res.order_owner?.length) {
        for (const owner of res.order_owner) {
          if (owner.rid) {
            const roles = owner.rid.split(',');
            Object.keys(roles).map(key => {
              owner.hasRegularRole = environment.user.roleTypes.RegularRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
            Object.keys(roles).map(key => {
              owner.hasChurchRole = environment.user.roleTypes.ChurchRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
          }
        }
      }
      
    if (res.commerce_order_total !== 0) {
      this.payment.amount = res.balance;
    } else {
      this.payment.amount = 0;
    }
    this.payment.uid = this.userSession.user.uid;
    this.payment.order_id = res.order_id;
    // this.payment.receipt_email = res.mail;

    this.order = res;
    console.log(this.order);
    return this.order;
  }

  async submitBilling(billingInfo: PostalAddress) {
    const body: CommerceCustomerProfile = {
        status: 1,
        type: 'billing',
        commerce_customer_address: billingInfo,
        uid: this.userSession.user.uid
    }
    // Add additional properties to the body
    const firstName = billingInfo.first_name;
    const lastName = billingInfo.last_name;
    billingInfo.name_line = firstName !== null && lastName !== null ? firstName + ' ' + lastName : null;
    console.log(body);
    return await this.commerceCustomerProfileService.createProfile(body).then(customerProfile => {
        console.log(customerProfile);
        return customerProfile;
    });
  }

  async updateBillingOnOrder(orderId: number, profileID: number) {
    const mail = this.order.order_owner.length ? this.order.order_owner[0].mail : this.order.mail;
    const body = {
      mail,
      commerce_customer_billing: profileID,
      order_id: orderId
    };
    return await this.commerceOrderService.updateOrder(body);
  }

  async checkoutElavon(drupalTransaction: {card_details: ElavonCreditCardTransaction, commerce_customer_address: PostalAddress}) {
    console.log(drupalTransaction);
    const payment_method = 'commerce_payment_elavon_direct';

    if (!this.payment.remote_id) {
      this.payment.remote_id = this.remoteId;
    }

    // Create the billing and update the billing on the order;
    this.submitBilling(drupalTransaction.commerce_customer_address).then(async (res) => {
      const updatedBilling = await this.updateBillingOnOrder(+this.payment.order_id, res.profile_id);
      console.log(updatedBilling);
    });

    const transaction = await this.elavonService.elavonPaymentTransactionProcess(drupalTransaction.commerce_customer_address, drupalTransaction.card_details, this.payment, this.order, this.userSession).then(async (transaction: ElavonTransaction) => transaction);
    console.log(transaction);
    const errorCode = transaction.elavon_response.xml.errorCode;
    const status = transaction.elavon_response.status;
    const errMessage = transaction.elavon_response.msg ? transaction.elavon_response.msg : transaction.elavon_response.xml.errorMessage;
    if (!status && !errorCode) {
       return this.message.presentAlert('Error', errMessage);
    }
    if (errorCode) {
      const errorName = transaction.elavon_response.xml.errorName;
      const errorMessage = transaction.elavon_response.xml.errorMessage;
      this.message.presentAlert(`${errorCode} Error: ${errorName}`, errorMessage);
    }

    const Amount = +transaction.elavon_response.xml.ssl_amount * 100;
    const formattedAmount = formatCurrency(Amount / 100, 'en-US', '$');
    console.log(formattedAmount);
    const updatedOrder = await this.reloadOrder();
    if (Amount === this.order.commerce_order_better_balance) {
      this.modalController.dismiss({
        payment: transaction,
        order: updatedOrder
      }, 'submit');
    } else {
      this.message.presentAlert('Payment Received', 'A payment in the amount of ' + formattedAmount +
      ' has been added to order ' + this.order.order_id + '. It looks like this order still has a balance of ' +
      this.order.commerce_order_better_balance_formatted + '. Please complete payment for this order.');
    }
  }


  checkoutAltPaymentMethod() {
    this.payment.status = CommercePaymentTransactionStatus.success;
    console.log(this.payment);
    if (!this.payment.remote_id) {
      this.payment.remote_id = this.remoteId;
    }

      this.paymentService.createDrupalPayment(this.payment).then(async (res) => {
        console.log(res);
        const Amount = formatCurrency(res.amount / 100, 'en-US', '$');
        if (res.amount === this.order.commerce_order_better_balance) {
          const order = await this.reloadOrder();
          this.modalController.dismiss({
            order
          }, 'submit');
        } else {
          await this.reloadOrder();
          this.message.presentAlert('Payment Received', 'A payment in the amount of ' + Amount +
          ' has been added to order ' + this.order.order_id + '. It looks like this order still has a balance of ' +
          this.order.commerce_order_better_balance_formatted + '. Please complete payment for this order.');
        }
      });

  }

  cancelModal() {
    this.modalController.dismiss({submit: false}, 'cancel');
  }

}
