import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { Pager } from "src/app/services/drupal7/public_api";

@Component({
    selector: 'app-pager-summary',
    template: `@if (pager) {<p>Displaying {{ (pager.limit * pager.page) + 1 }}-{{(pager.limit * pager.page) + count}} of {{pager.count}}</p>}`,
    styleUrls: ['./pager-summary.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule]
})
export class PagerSummaryComponent {

    @Input() pager: Pager;
    @Input() count: number;

    constructor() {}
}
