import { Component, Input, OnInit } from '@angular/core';
import { UserServiceCustom } from '../../services/user.service';
import { MessagesService } from '../../services/messages.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule, KeyValue } from '@angular/common';
import { CommerceOrderStatus, DrupalFormControlObject, FlagIonic, FormFromJSON, SystemConnection } from '../../services/drupal7/models';
import { EntityService } from '../../services/drupal7/drupal7-services.module';
import { FlagServiceCustom } from '../../services/flag.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeComponent } from 'angularx-qrcode';
import { DelegateRegistration } from 'src/app/models/models';
import { FormsService } from 'src/app/services/drupal-forms/forms.service';
import { environment } from 'src/environments/environment.prod';
import { FormFieldOptions } from 'src/app/services/drupal7/models/form-controls';
import { SeatingComponent } from 'src/app/seating/seating.component';
import { SeatingService } from 'src/app/seating/seating.service';
@Component({
    selector: 'app-seating-reservation-card',
    templateUrl: './seating-reservation-card.component.html',
    styleUrls: ['./seating-reservation-card.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, QRCodeComponent, SeatingComponent]
})
export class SeatingReservationCardComponent implements OnInit {
    @Input() row: DelegateRegistration;
    @Input() disabled: boolean = false;
    userSession: SystemConnection;
    loggedIn = false;
  
    flags: FlagIonic[];
    checkInOutFlags: FlagIonic[];
    formSchema: FormFromJSON;
    orderStatus = CommerceOrderStatus;

    constructor(
        private entityService: EntityService,
        private flagService: FlagServiceCustom,
        public router: Router,
        private user: UserServiceCustom,
        public message: MessagesService,
        private modalCtrl: ModalController,
        private seatService: SeatingService,
        private forms: FormsService
    ) {
        this.user.currentSession.subscribe(user => {
          this.userSession = user;
          if (user && user.user.uid) {
            this.loggedIn = true;
          } else {
            this.loggedIn = false;
          }
        });
        this.flagService.currentFlags.subscribe(flags => {
          this.flags = flags;
        });
        this.flagService.currentFlagTypes.subscribe(flagTypes => {
          if (flagTypes) {
            this.checkInOutFlags = flagTypes.checkInOutFlags;
          }
        });
     }

     async ngOnInit(): Promise<void> {
      const opts: FormFieldOptions = {
        entityRef: {
          excludeOptions: ['linked_church', 'seat_reservation_id', 'linked_spouse', 'linked_bulk_registration',
        'field_childcare_information', 'linked_payment_transaction']
        }
      }
      this.formSchema = await this.forms.getInternalForm(this.userSession, environment.checkInType.entityType, environment.checkInType.bundle, 'form', opts);
      this.seatService.changeSeat(this.row.seat_reservation[0]);
     }

     originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;

    checkFlagDisabled(flag: any, entity: any) {
        return this.flagService.checkFlagDisabled(flag, entity);
    }
      
    checkFlagHidden(flag: any, entity: any) {
        return this.flagService.checkFlagHidden(flag, entity, this.userSession);
    }

      async updatePhoneNumber(entityType: string, entity: any, formField: DrupalFormControlObject) {
        // this.entity.presentUpdateFieldAlert(entityType, entity, formField, options).then(val => {
        //   if (val) {
        //    return entity[key] = val;
        //   }
        // });
      }
    
      async toggleOnOffCampus(entityType: string, entity: any, key: string) {
        const body = {};
        body[key] = !entity[key];
        const fromText = entity[key] ? 'Off Campus': 'On Campus';
        const toText = !entity[key] ? 'Off Campus': 'On Campus';
        this.message.showLoading('Updating ' + key + ' from ' + fromText + ' to ' + toText, false, 2000);
        return entity[key] = await this.entityService.updateEntity(entityType, body, entity.id).then(res => res[key]);
      }
    
      flagEntity(item: DelegateRegistration, flag: FlagIonic, flagValue: boolean) {
        const firstName = item.contact_first_name ? item.contact_first_name : item.contact_first_name;
        const flagMessage = `Flagged ${flag.label} for ${firstName} ${item.contact_last_name}`;
        const unflagMessage = `Unflagged ${flag.label} for ${firstName} ${item.contact_last_name}`;
        this.flagService.flagEntity(item, flag, flagValue, this.userSession, flagMessage, unflagMessage);
        console.log(item);
      }

      canUpdate(entity: any, key: string) {
        if (this.formSchema) {
          if (entity.author[0].rid === 'church member' && key === 'selected_seat') {
            return false;
          } else {
            return this.formSchema.formGroup.controls[key] ? true : false;
          }
        }
      }

      async openSeating(entity: DelegateRegistration) {
        this.seatService.changeSeat(entity.seat_reservation[0]);
        const modal = await this.modalCtrl.create({
          component: SeatingComponent,
          componentProps: {
            seats: entity.seat_reservation
          }
        });
        await modal.present();
      }
    
    
}