import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertController, IonicModule, LoadingController, ModalController } from "@ionic/angular";
import { DrupalFormControlObject, EntityService, ViewOptions, ViewService } from "../../services/drupal7/public_api";
import { FormsService } from "../../services/drupal-forms/forms.service";
import { DelegateRegistration, SeatData } from "src/app/models/models";
import { SeatingReservationCardComponent } from "src/app/components/seating-reservation-card/seating-reservation-card.component";
import { SeatingComponent } from "src/app/seating/seating.component";
import { SeatingService } from "src/app/seating/seating.service";
import { SeatingCardComponent } from "src/app/seating/seating-card/seating-card.component";
import { EntityServiceCustom } from "src/app/services/entity.service";

@Component({
    selector: 'app-entity-reference-seat-reservation',
    styleUrls: ['./entity_reference_seat_reservation.component.scss'],
    templateUrl: './entity_reference_seat_reservation.component.html',
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, SeatingReservationCardComponent, SeatingComponent, SeatingCardComponent]
})
export class EntityReferenceSeatReservationComponent implements OnInit, OnDestroy {
    @Input() entity: DelegateRegistration;
    @Input() field: DrupalFormControlObject;
    proposedSeat: SeatData;

    fieldOptions: SeatData[] = [];
    section: string = 'all';
    row: string = 'all';
    assignable: string = '1';

    sections = [101, 102, 103, 104, 105, 106, 107, 202, 203, 204, 205, 206, 302, 303, 304, 305, 306];
    rows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    existingSeatReservation: SeatData;

    loading: HTMLIonLoadingElement;

    constructor(
        public formsService: FormsService,
        private entityService: EntityService,
        private entityServiceCustom: EntityServiceCustom,
        private seatService: SeatingService,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        private modalCtrl: ModalController) {}

    ngOnInit(): void {
        console.log(this.entity);
        this.setFieldClassType();
        if (this.existingSeatReservation) {
            this.seatService.changeSeat(this.existingSeatReservation);
        }
        if (!this.field) {
            this.field = {
                field_name: 'selected_seat',
                label: 'Seating Assignment',
                type: 'entityreference',
                ionic_type: 'list',
                status: true,
                access: true
            }
        }
    }

    ngOnDestroy(): void {
        this.reset();
    }

    setFieldClassType() {
        this.reset();
        if (this.entity.seat_reservation?.length) {
            this.existingSeatReservation = this.entity.seat_reservation.find(reservation => reservation.delegate_nid === this.entity.nid);
        }
    }

    async search() {
        this.proposedSeat = null;
        const options: ViewOptions = {
          display_id: 'api_available_seats_for_seat_reservation',
          convert_options: true,
          filters: {
            seat_assignable: +this.assignable,
          }
        };
        if (this.section !== 'all') {
            options.filters.seating_section = this.section;
        }
        if (this.row !== 'all') {
            options.filters.seat_row = this.row;
        }
        const url = `available/${this.field.field_name}`;
        await this.entityServiceCustom.getViewWithOptions(url, options).then(res => {
          this.fieldOptions = res.results;
        });
      }
    

    setValue(seat: SeatData) {
        if (this.proposedSeat) {
            this.proposedSeat.isSelecting = false;
        }
        this.proposedSeat = null;
        setTimeout(() => {
            seat.isSelecting = true;
            this.proposedSeat = seat;
        }, 5);
    }

    getProposedSeat() {
        if (this.existingSeatReservation) {
            return [this.proposedSeat, this.existingSeatReservation];
        }
        return [this.proposedSeat];
    }

    async cancel() {
        this.reset();
        this.modalCtrl.dismiss({}, 'cancel');
    }

    async submit() {
        this.loading = await this.loadingCtrl.create({
            message: 'Submitting...'
        });
        await this.loading.present();
        const seatReservation = await this.createSeatReservation();
        this.loading.dismiss();
        this.reset();
        this.modalCtrl.dismiss(seatReservation, 'submit');
    }

    reset() {
        this.fieldOptions = [];
        this.proposedSeat = null;
        this.existingSeatReservation = null;
        this.section = 'all';
        this.row = 'all';
        this.assignable = '1';
    }

    async createSeatReservation() {
        const body = new SeatReservation(this.entity.uid, this.entity.nid);
        body.selected_seat = this.proposedSeat.nid;
        await this.deleteSeatReservation();
        const seatReservation = await this.entityService.createEntity('node', body);
        return seatReservation;
    }

    async deleteSeatReservation() {
        if (!this.loading) {
            this.loading = await this.loadingCtrl.create({
                message: 'Deleting reservation...',
                id: 'delete'
            });
            await this.loading.present();
        }
        if (this.existingSeatReservation) {
            console.log('removing reservation', this.existingSeatReservation);
            this.entityService.deleteEntity('node', this.existingSeatReservation.nid);
            if (this.loading.id === 'delete') {
                this.loading.dismiss();
            }
        }
    }

    async deleteAndClose() {
        const alert = await this.alertCtrl.create({
            header: 'Release Seat Reservation',
            message: 'Are you sure you want to release this seat reservation? This cannot be undone.',
            buttons: [{
                id: 'cancel',
                text: 'Cancel',
                role: 'cancel'
            },{
                id: 'submit',
                text: 'Delete',
                role: 'submit',
                handler: async () => {
                    await this.deleteSeatReservation();
                    this.reset();
                    this.modalCtrl.dismiss(true, 'submit');
                }
            }
        ]
        });
        await alert.present();
        
    }
}

export class SeatReservation {
    selected_delegate: number;
    selected_seat: number;
    type: string = 'seat_reservation';
    author: number;
    created: number;
    constructor(author: number, delegate: number) {
        this.author = author;
        this.selected_delegate = delegate;
        this.created = Math.round((new Date()).getTime() / 1000);
    }
}
  