// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-card-layout ion-card-header ion-card-title {
  font-size: 2rem;
}
.photo-card-layout ion-card-header ion-card-subtitle.img-id {
  bottom: auto;
  font-size: 3rem;
  margin-top: 1rem;
}

ion-avatar.person-image {
  width: 180px;
  height: 180px;
}

.input-padding {
  padding: 1rem !important;
  background: var(--ion-color-light-input);
  color: var(--ion-color-light-input-contrast);
  border-radius: 1.5rem;
  font-size: 1.7rem;
}

.v-center {
  height: 100%;
}

ion-text {
  font-size: large;
}

.header-icon {
  width: 8rem;
  height: 8rem;
  margin: 0 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/app/self-check-in/self-check-in.component.scss"],"names":[],"mappings":"AAEQ;EACI,eAAA;AADZ;AAGQ;EACI,YAAA;EACA,eAAA;EACA,gBAAA;AADZ;;AAKA;EACI,YAAA;EACA,aAAA;AAFJ;;AAIA;EACI,wBAAA;EACA,wCAAA;EACA,4CAAA;EACA,qBAAA;EACA,iBAAA;AADJ;;AAGA;EACI,YAAA;AAAJ;;AAEA;EACI,gBAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAEJ","sourcesContent":[".photo-card-layout {\n    ion-card-header {\n        ion-card-title {\n            font-size: 2rem;\n        }\n        ion-card-subtitle.img-id {\n            bottom: auto;\n            font-size: 3rem;\n            margin-top: 1rem;\n        }\n    }\n}\nion-avatar.person-image {\n    width: 180px;\n    height: 180px;\n}\n.input-padding {\n    padding: 1rem !important;\n    background: var(--ion-color-light-input);\n    color: var(--ion-color-light-input-contrast);\n    border-radius: 1.5rem;\n    font-size: 1.7rem;\n}\n.v-center {\n    height: 100%;\n}\nion-text {\n    font-size: large;\n}\n.header-icon {\n    width: 8rem;\n    height: 8rem;\n    margin: 0 .75rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
