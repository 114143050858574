import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SeatData } from "src/app/models/models";

@Component({
    selector: 'app-seating-card',
    styleUrls: ['./seating-card.component.scss'],
    template: `
    <ion-list class="item-separate" lines="none" (click)="log()">
    <ion-item *ngIf="seat" [color]="seatColor">
    <ion-icon slot="start" src="../../../assets/imgs/seat.svg"></ion-icon>
    <ion-label class="ion-text-wrap">
    <p *ngIf="seat.nid" color="primary">Reservation ID: {{seat.nid}}</p>
    <h1 *ngIf="seat.delegate">Reserved: {{seat.seating_section}}-{{seat.seat_row}}-{{seat.temporary_seat_number}} ({{seat.nid_1}})</h1>
    <h1 *ngIf="!seat.delegate">Seat: {{seat.seating_section}}-{{seat.seat_row}}-{{seat.temporary_seat_number}} ({{seat.nid_1}})</h1>
    <h2 *ngIf="seat.delegate">Delegate: {{seat.delegate}} ({{seat.delegate_nid}})</h2>
    <p *ngIf="seat.seating_notes">{{seat.seating_notes}}</p>
    <ion-badge>{{convertSeatType(seat) | titlecase}}</ion-badge>
    <ion-badge>{{seat.temporary_seat ? 'Temporary Seat' : 'Permanent Seat'}}</ion-badge>
    </ion-label>
    </ion-item>

    <ion-item *ngIf="viewSeat" [ngClass]="viewSeat?.seat_type">
                    <ion-icon [ngClass]="viewSeat?.seat_type" slot="start" src="../../../assets/imgs/seat.svg"></ion-icon>
                    <ion-label class="ion-text-wrap">
                        <h1 *ngIf="viewSeat.delegate_nid">{{viewSeat.contact_first_name}} {{viewSeat.contact_last_name}} ({{viewSeat.delegate_nid}})</h1>
                        <h1>{{viewSeat.delegate_nid ? 'Reserved' : 'Seat'}}: {{viewSeat.seating_section}}-{{viewSeat.seat_row}}-{{viewSeat.temporary_seat_number}} ({{viewSeat.nid}})</h1>
                        <p *ngIf="viewSeat.delegate_nid" color="primary">Reservation ID: {{viewSeat.seat_reservation_nid}}</p>
                    <p *ngIf="viewSeat.seating_notes">Reserved: {{viewSeat.seating_notes}}</p>
                    <p>{{convertSeatType(viewSeat) | titlecase}}</p>
                    <p>{{viewSeat.temporary_seat ? 'Temporary Seat' : 'Permanent Seat'}}</p>
                    <p *ngIf="viewSeat.seat_number !== viewSeat.temporary_seat_number">Permanent Seat Number: {{viewSeat.seat_number}}, Temporary Seat Number: {{viewSeat.temporary_seat_number}}</p>
                    </ion-label>
                    </ion-item>

    <ion-item *ngIf="proposedSeat" color="primary">
    <ion-icon slot="start" src="../../../assets/imgs/seat.svg"></ion-icon>
    <ion-label class="ion-text-wrap">
    <h1>Selected: {{proposedSeat.seating_section}}-{{proposedSeat.seat_row}}-{{proposedSeat.temporary_seat_number}} ({{proposedSeat.nid}})</h1>
    <h2 *ngIf="proposedSeat.delegate">Delegate: {{proposedSeat.delegate}} ({{proposedSeat.delegate_nid}})</h2>
    <p *ngIf="proposedSeat.seating_notes">{{proposedSeat.seating_notes}}</p>
    <ion-badge>{{convertSeatType(proposedSeat) | titlecase}}</ion-badge>
    <ion-badge>{{proposedSeat.temporary_seat ? 'Temporary Seat' : 'Permanent Seat'}}</ion-badge>
    </ion-label>
    </ion-item>
    </ion-list>`,
standalone: true,
imports: [IonicModule, CommonModule, FormsModule]
})
 export class SeatingCardComponent {

    @Input() seat: SeatData;
    @Input() seatColor: string;
    @Input() proposedSeat: SeatData;
    @Input() viewSeat: SeatData;

    constructor() {}

    log() {
        console.log(this.seat);
        console.log(this.viewSeat);
    }
    
      convertSeatType(seat: SeatData) {
          if (seat?.seat_type) {
            const res = seat.seat_type.replace(/_/g, ' ');
            return res;
          }
        return '';
      }
 }