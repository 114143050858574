import { Component, inject, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../../../services/user.service';
import { MessagesService } from '../../../services/messages.service';
import { AlertController, IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemConnection } from '../../../services/drupal7/models';
import { HeaderComponent } from '../../../system/header/header.component';
import { QRCodeComponent } from 'angularx-qrcode';
import { PagerSummaryComponent } from 'src/app/system/pager/pager-summary.component';
import { PagerComponent } from 'src/app/system/pager/pager.component';
import { PrintBadgeService } from '../../styles/print-badge.service';
import { QRCode } from 'src/app/services/drupal7/models/qrcode';


@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, HeaderComponent, QRCodeComponent, PagerSummaryComponent, PagerComponent]
})
export class A6BlankBadgeComponent implements OnInit {

  public router = inject(Router);
  public storage = inject(Storage);
  private user = inject(UserServiceCustom);
  public messagesService = inject(MessagesService);
  private printBadgeService = inject(PrintBadgeService);
  private alertCtrl = inject(AlertController);

  userSession: SystemConnection;
  data: A6Badge[] = [];

  newNames = '';
  badge = {
    id: 'SLC Asia 2025',
    delegateType: 'Delegate',
    country: 'Philippines',
    locationPrefix: 'Z',
    qrCodeValue: 'https://slconferenceasia.com'
  }

  constructor() {}

   async ngOnInit() {
    this.user.currentSession.subscribe(user => {
      this.userSession = user;
    });
   }

   addNames() {
    const splitNames = this.newNames.split('\n').map(nameLine => {
      const [contact_first_name, contact_last_name] = nameLine.trim().split(' ');
      return { contact_first_name, contact_last_name };
    }).filter(name => name.contact_first_name || name.contact_last_name);

    // Filter out entries with missing first or last names
    const validNames = splitNames.map(name => {
      const newMember = new A6Badge();
      Object.keys(newMember).map(k => {
        if (this[k]) {
          newMember[k] = this[k];
        } else {
          newMember[k] = name[k];
        }
      });
      newMember.delegate_type = this.badge.delegateType;
      newMember.country = this.badge.country;
      return newMember;
    });

    // Add the valid names to the list
    this.data.push(...validNames);

    // Clear the textarea
    this.newNames = '';
    console.log(this.data);
  }

  async updateField(badge: A6Badge, key: string) {
  const alert = await this.alertCtrl.create({
    header: `Update for ${badge.contact_first_name} ${badge.contact_last_name}`,
    backdropDismiss: true,
    inputs: [
      {
        id: key,
        name: key,
        type: 'text',
        value: badge[key]
      }
    ],
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel'
      }, {
        text: 'Update',
        cssClass: 'primary',
        role: 'submit'
      }
    ]
  });
  await alert.present();
  return await alert.onDidDismiss().then(async (form: any) => {
    if (form.role === 'submit') {
      badge[key] = form.data.values[key];
    }
  });
}

  remove(i: number) {
    this.data.splice(i, 1);
  }

  reset() {
    this.data = [];
  }

  async printPage() {
    const styles = this.printBadgeService.a6Styles();
    this.printBadgeService.printPage(document.title, document.getElementById('printArea').innerHTML, styles);
  }

}

export class A6Badge {
  contact_first_name: string = '';
  contact_last_name: string = '';
  country: string = '';
  delegate_type: string = '';
  qr_codes: QRCode[] = [];
  hidden: boolean = false;
  contact_first_name_size: string = '26';
  contact_last_name_size: string = '26';
  constructor() {}
}