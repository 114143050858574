import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../../system/header/header.component';
import { FirebaseService } from '../../services/firebase.service';
import { Country, Region, Table, TableAnalytics, TableAssignment, TableAssignmentsService } from 'src/app/services/table-assignments.service';
import { SeatChangeModalComponent } from '../seat-change/seat-change.component';

@Component({
  selector: 'app-firebase-table-assignments',
  templateUrl: './firebase-table-assignments.component.html',
  styleUrls: ['./firebase-table-assignments.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, HeaderComponent]
})
export class FirebaseTableAssignmentsComponent implements OnInit {

    private firebaseService = inject(FirebaseService);
    private tableAssignmentsService = inject(TableAssignmentsService);
    private alertCtrl = inject(AlertController);
    private modalCtrl = inject(ModalController);

    table_assignments: Table[] = [];
    regions: Region[] = [];
    countries: Country[] = [];
    tables: Table[] = [];

    filters = {
      type: 'all',
      inputData: '',
      table: '',
      numberOfOpenSeats: ''
    };

    tableCount = 40;
    reservedTables = 2;
    availableTables = this.tableCount - this.reservedTables;
    seatsPerTable = 10;
    hasPeople = false;
    showAllColumns = false;
    showCountries = false;
    allowDelete = false;
    viewMode = 'grid';
    tableCheck = 'all';
    @Input() allowUpdate = false;

    analytics: TableAnalytics = new TableAnalytics();

    @ViewChild('dataTable', { static: false }) table!: ElementRef;
    copySuccess: string = '';

    constructor() {}

    ngOnInit() {
      console.log(this.table_assignments);
      this.table_assignments = this.tableAssignmentsService.createTables();
      this.getSubmissions();
      this.getTables();
    }

    copyTable() {
      if (!this.table) {
        this.copySuccess = 'Table not found!';
        return;
      }
  
      const rows = this.table.nativeElement.querySelectorAll('tr');
      let tableText = '';
  
      rows.forEach(row => {
        const cells = row.querySelectorAll('th, td');
        const rowText = Array.from(cells).map((cell: any) => cell.innerText).join('\t'); // Use tab separation
        tableText += rowText + '\n'; // Add newline for rows
      });
  
      navigator.clipboard.writeText(tableText).then(() => {
        this.copySuccess = 'Table copied to clipboard!';
        setTimeout(() => this.copySuccess = '', 2000); // Hide success message after 2 seconds
      }).catch(err => {
        console.error('Failed to copy: ', err);
        this.copySuccess = 'Failed to copy table!';
      });
    }

    deleteDocument(person: any) {
      const id = `${person.foreign_key}_${person.id.toString()}`;
      this.firebaseService.deleteDocument(id);
    }

    async clearAll() {
      this.table_assignments.forEach(table => {
        table.seats.forEach(async (seat) => {
          this.deleteDocument(seat);
          await new Promise<void>(resolve => setTimeout(()=>resolve(), 500));
        });
      });
    }

    async checkInSpeaker(person: TableAssignment, checked_in: string = 'Yes') {
      const id = `${person.foreign_key}_${person.id.toString()}`;
      this.firebaseService.updateCheckedIn(id, checked_in);
    }

    getTables() {
      this.firebaseService.getTableData().subscribe(async (res) => {
        console.log(res);
        if (res.length) {
          this.tables = res as Table[];
        }
        this.getCountries();
      });
    }

    getCountries() {
      const countriesNotUsed = this.regions.flatMap(r => r.countries.map(c => c.name));
      const uniqueCountries = [...new Set(countriesNotUsed)];
      const countryCount = Object.fromEntries(
        Object.entries(
          this.tables
            .map(t => t.country)
            .reduce((acc, country) => {
              if (!acc[country]) {
                acc[country] = this.tables.filter(t => t.country === country);
              }
              return acc;
            }, {} as Record<string, Table[]>)
        ).sort((a, b) => b[1].length - a[1].length)
      );
      const countriesUsed = [...new Set(this.tables.map(t => t.country))];
      uniqueCountries.sort();

      const intersection = uniqueCountries.filter(item => countriesUsed.includes(item));
      const intersectionNot = uniqueCountries.filter(item => !countriesUsed.includes(item));
      intersection.sort();
      intersectionNot.sort();

      console.log('All countries', uniqueCountries);
      console.log('Used', intersection);
      console.log('Not used', intersectionNot);
      console.log('Usage Count', countryCount);

    }

    getTableData(tableFromAssignment: Table, key: string) {
      return this.tableAssignmentsService.getTableData(tableFromAssignment, key, this.tables);
    }

    getTableColor(table: Table) {
      return this.tableAssignmentsService.getTableColor(table);
    }

    async changeSeat(person: TableAssignment) {
      const tables = this.tableAssignmentsService.createTables();
      const tableNumbers = tables.map(t => t.tableNumber.toString());
      const seatNumbers = tables[0].seats.map(s => s.seat_number);
      const modal = await this.modalCtrl.create({
        component: SeatChangeModalComponent,
        componentProps: {
          person,
          tables: tableNumbers,
          seats: seatNumbers
        }
      });
      await modal.present();
      const res = await modal.onDidDismiss();
      if (res.role === 'submit') {
        person.table = res.data.table;
        person.seat_number = res.data.seat;
      }
    }

     async updateTableDetails(table: Table) {

        const currentCountry = this.getTableData(table, 'country');
        const currentRegion = this.getTableData(table, 'region');
        const currentTableNumber = this.getTableData(table, 'tableNumber');
        const alert = await this.alertCtrl.create({
          header: 'Update Table Details',
          backdropDismiss: true,
          message: 'Enter the Region and Country for this table',
          inputs: [
            {
              id: 'country',
              name: 'country',
              label: 'Country',
              type: 'text',
              value: currentCountry
            },
            {
              id: 'region',
              name: 'region',
              label: 'Region',
              type: 'text',
              value: currentRegion
            },
            {
              id: 'tableNumber',
              name: 'tableNumber',
              label: 'Table Number',
              type: 'number',
              value: currentTableNumber
            }
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel'
            }, {
              text: 'Update',
              cssClass: 'primary',
              role: 'submit'
            }
          ]
        });
        await alert.present();
        return await alert.onDidDismiss().then(async (form: any) => {
          if (form.role === 'submit') {
            console.log(form);
            const tableId = `table_${form.data.values.tableNumber}`;
            const updated = this.firebaseService.updateTableDocument(tableId, form.data.values.tableNumber, form.data.values.country, form.data.values.region);
            if (updated) {
              table.seats.forEach(seat => {
                const id = `${seat.foreign_key}_${seat.id.toString()}`;
                const updatedSeat = this.firebaseService.updateDocument(id, form.data.values.tableNumber, seat.seat_number);
              });
            }
          }
        });
     }

    getSubmissions() {
      this.firebaseService.getData().subscribe(async (res) => {
        console.log(res);
        const data = await this.tableAssignmentsService.groupByTable(res as TableAssignment[]);
        if (data) {
          console.log(data);
          this.table_assignments = data.tables;
          this.regions = data.regions.sortedRegions;
          this.analytics = data.regions.analytics;
          this.hasPeople = true;
        }
      });
    }

    totalFiltered() {
      return this.tableAssignmentsService.totalFiltered(this.table_assignments);
    }

    filterResults() {
      if (this.filters.table !== '') {
        this.table_assignments.map(t => t.hidden = t.tableNumber.toString() !== this.filters.table ? true : false);
      } else {
        this.table_assignments.forEach(t => t.hidden = false);
      }
      this.tableAssignmentsService.filterResults(this.filters, this.table_assignments);
    }

    filterOpenTables() {
      if (+this.filters.numberOfOpenSeats === -1) {
        this.table_assignments.forEach(t => t.hidden = false);
      } else {
        this.table_assignments.forEach(t => {
          const openSeats = t.seats.filter(s => s.uuid === '');
          t.hidden = (openSeats.length >= +this.filters.numberOfOpenSeats) ? false : true;
        });
      }
    }

    filterOverbooked() {
      switch (this.tableCheck) {
        case 'all':
          this.table_assignments.forEach(t => t.hidden = false);
          break;
        case 'overbooked':
          this.table_assignments.forEach(t => {
            const numberOfSeats = t.seats.length;
            t.hidden = (numberOfSeats > this.tableAssignmentsService.seatsPerTable) ? false : true;
          });
          // this.table_assignments.forEach(t => t.hidden = true);
          break;
        case 'open':
          this.table_assignments.forEach(t => {
            const openSeats = t.seats.filter(s => s.uuid === '');
            t.hidden = openSeats.length ? false : true;
          });
          break;
        case 'intersect':
          this.table_assignments.forEach(t => {
            const overbookedTable = t.seats.length > this.tableAssignmentsService.seatsPerTable;
            const openSeats = t.seats.filter(s => s.uuid === '').length;
            if (overbookedTable || openSeats) {
              t.hidden = false;
            }
          });
      }
    }
    
}