import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { ResizableModule } from 'angular-resizable-element';
import { ExpandPipe } from 'src/app/pipes/expand.pipe';
import seatingMapJSON from '../../assets/seating-map.json';
import { StorageService } from '../services/storage/storage.service';
import { UserServiceCustom } from '../services/user.service';
import { SeatData, SeatSection } from '../models/models';
import { ViewService } from '../services/drupal7/drupal7-services.module';
import { SystemConnection, ViewOptions } from '../services/drupal7/public_api';
import { SVGZoomService } from '../services/svg-zoom.service';
import { SeatingService } from './seating.service';

@Component({
  selector: 'app-seating',
  templateUrl: './seating.component.html',
  styleUrls: ['./seating.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, ResizableModule, FormsModule, ExpandPipe],
})
export class SeatingComponent  implements OnInit, AfterViewInit {
  @ViewChild('svg') svg;

  @Input() nid: number;
  @Input() seats: SeatData[] = [];
  @Input() selectedSeat: SeatData;
  @Input() header: boolean = false;
  initialSeats: SeatData[] = [];
  categories: any = [];
  seatingMap: SeatSection[] = seatingMapJSON;
  userSession: SystemConnection;

  toggleAssignable = false;
  toggleTemporary = false;
  seatStatus: any = {};

  viewBox = {
    x: 0,
    y: 0,
    zx: 400,
    zy: 400
  };

  touchZoom = false;
  touchStartDistance = 0;
  touchStartCenter = {
    x: 0,
    y: 0
  };
  loading: HTMLIonLoadingElement;

  constructor(public storage: StorageService,
    private userService: UserServiceCustom,
    private svgZoom: SVGZoomService,
    private seatService: SeatingService,
    private viewService: ViewService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController) {
  }

  async ngOnInit() {
    this.userService.currentSession.subscribe(session => {
      if (session) {
        this.userSession = session;
      }
    });
    this.seatService.currentSeat.subscribe(seat => {
      if (seat) {
        setTimeout(() => {
          this.seats = [seat];
          this.initialSeats = [... [seat]];
          this.removeSeatClass();
          this.mapReservations(this.seats);
          this.selectedSeat = this.seats.find(x => x.delegate_nid === this.seats[0].delegate_nid);
        }, 500);
      }
    });

    setTimeout(() => {
      if (this.seats) {
        console.log(this.seats);
        if (!Array.isArray(this.seats)) this.seats = [this.seats];
        this.initialSeats = [... this.seats];
        this.removeSeatClass();
        this.mapReservations(this.seats);
        this.selectedSeat = this.seats.find(x => x.delegate_nid === this.seats[0].delegate_nid);
      }
    }, 500);

  }

  ngAfterViewInit() {
    const svgEl = this.svg.nativeElement;
    this.enableInteraction();
    const textEls = svgEl.querySelectorAll('text');
    textEls.forEach((textEl: any) => {
      textEl.addEventListener('click', this.handleClick.bind(this));
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }
  
  handleClick(event: any) {
    const textEl = event.target;
    const svgEl = textEl.closest('g');
    const circleEl = textEl.previousElementSibling;
    if (circleEl && circleEl.tagName === 'circle' && circleEl.parentNode === svgEl) {
      const seat = circleEl.getAttribute('data-name');
      if (this.seats) {
        this.selectedSeat = this.seats.filter(x => seat === `${x.seating_section}-${x.seat_row}-${x.temporary_seat_number}`)[0];
      }
    }
  }

  pinFormatter(value: number) {
    return `${value}`;
  }

  enableInteraction() {
    const parentEl = document.getElementById("svg-container");
    const innerEl = document.getElementById('seating-map-container');
    this.svgZoom.enableInteraction(this.svg.nativeElement, parentEl, innerEl);
  }

  async getSeatReservations() {
    const options: ViewOptions = {
      display_id: 'api_seating_map',
    };
    return this.viewService.getView('manage/seat-reservation', options).then((res: any) => {
      console.log(res);
      this.mapReservations(res);
      return res;
    });
  }

  async getSeats(id: number) {
    this.loading = await this.loadingCtrl.create({message: 'Loading Seats'});
    await this.loading.present();
    const seats = await this.get(id);
    await this.loading.dismiss();
    this.mapReservations(seats);
    this.selectedSeat = this.seats.find(x => x.delegate_nid === id);
  }

  async get(id?: number) {
    const options: ViewOptions = {
      display_id: 'api_manage_seats',
      args: [id]
    };
    return this.viewService.getView('manage/seats', options).then((res: any) => {
      console.log(res);
      return res;
    });
  }

  toggleAssignCategory() {
    this.toggleAssignable = !this.toggleAssignable;
    const svg = document.getElementsByTagName('svg')[0];
    for (const seat of this.seats) {
      this.mapSeatClass(seat, svg);
    }
  }

  toggleAssignTemporary() {
    this.toggleTemporary = !this.toggleTemporary;
    const svg = document.getElementsByTagName('svg')[0];
    for (const seat of this.seats) {
      if (this.toggleTemporary) {
        const svgElement = svg.querySelector(`[data-name="${seat.seating_section}-${seat.seat_row}-${seat.temporary_seat_number}"]`);
        if (svgElement) {
          while (svgElement.classList.length > 0) {
            svgElement.classList.remove(svgElement.classList.item(0));
          }
          if (seat.temporary_seat && seat.status) {
            svgElement.classList.add('seat_temporary');
          }
          if (seat.seat_removed || !seat.status) {
            svgElement.classList.add('seat_removed');
          }
        }
      } else {
        this.mapSeatClass(seat, svg);
      }
    }
  }

  removeSeatClass() {
    const svg = this.svg.nativeElement;
    const polyEls = svg.querySelectorAll('polygon');
    this.clearClasses(polyEls);
    const rectEls = svg.querySelectorAll('rect');
    this.clearClasses(rectEls);
    const circleEls = svg.querySelectorAll('circle');
    this.clearClasses(circleEls, true);
    const textEls = svg.querySelectorAll('text');
    this.clearClasses(textEls, false, true);
    const groupEls = svg.querySelectorAll('g');
    this.clearClasses(groupEls);
  }

  clearClasses(els: any, radius: boolean = false, fontSize: boolean = false) {
    els.forEach((el: any) => {
      while (el.classList.length > 0) {
        el.classList.remove(el.classList.item(0));
        if (radius) {
          el.setAttribute('r', '2.2');
        }
        if (fontSize) {
          el.setAttribute('font-size', '3')
        }
      }
    });
  }

  mapSeatClass(seat: SeatData, svg: any) {
      const sectionElement = svg.querySelector(`[data-name="bg-section-${seat.seating_section}"]`);
      const groupElement = svg.querySelector(`[data-name="section-${seat.seating_section}"]`);
      const svgElement = svg.querySelector(`[data-name="${seat.seating_section}-${seat.seat_row}-${seat.temporary_seat_number}"]`);
      const svgTextElement = svg.querySelector(`[data-name="text-${seat.seating_section}-${seat.seat_row}-${seat.temporary_seat_number}"]`);
      if (svgElement) {
        if (seat.nid_1) {
          groupElement.classList.add('reserved-group');
          if (seat?.isSelecting) {
            sectionElement.classList.add('selected');
            svgElement.classList.add('selected');
          }
          sectionElement.classList.add('reserved');
          svgElement.classList.add('reserved');
          svgElement.setAttribute('r', '10');
          svgTextElement.setAttribute('font-size', '13')
          svgTextElement.classList.add('reserved-text');

          if (seat.delegate === 'Unnamed Delegate') {
            svgElement.classList.add('unnamed_delegate');
          }
        }
        if (seat.seat_type) {
          svgElement.classList.add(seat.seat_type);
        }
        if (seat.temporary_seat && seat.status) {
          svgElement.classList.add('seat_temporary');
        }
        if (this.toggleAssignable) {
          if (seat.seat_assignable && !seat.nid) {
            svgElement.classList.add('seat_assignable');
          }
          if (seat.seat_assignable === 0) {
            svgElement.classList.add('seat_not_assignable');
          }
          if (seat.seat_assignable && seat.nid) {
            svgElement.classList.remove('unnamed_delegate');
          }
        } else {
          svgElement.classList.remove('seat_assignable', 'seat_not_assignable');
        }
        if (seat.seat_removed) {
          svgElement.classList.add('seat_removed');
        }
      }
  }

  mapCategories(seat: SeatData) {
    if (seat.seat_type && seat.status && !this.categories.length) {
      if (!this.categories[seat.seat_type]) {
        this.categories[seat.seat_type] = {
          count: 1,
          type: seat.seat_type,
          color: `seat-${seat.seat_type.toLowerCase().replace(/_/g, '-')}`
        };
      } else {
        const count = this.categories[seat.seat_type].count;
        this.categories[seat.seat_type].count = count + 1;
      }
    }
  }

  mapAssignable(seat: SeatData) {
    if (seat.status && seat.temporary_seat) {
      if (this.seatStatus.temporary) {
        this.seatStatus.temporary.push(seat);
      } else {
        this.seatStatus.temporary = [seat];
      }
    }
    if (seat.status && seat.seat_assignable) {
      if (this.seatStatus.assignable) {
        this.seatStatus.assignable.push(seat);
      } else {
        this.seatStatus.assignable = [seat];
      }
      if (this.seatStatus.empty && !seat.nid) {
        this.seatStatus.empty.push(seat);
      } else if (!seat.nid) {
        this.seatStatus.empty = [seat];
      }
      if (this.seatStatus.filled && seat.nid) {
        this.seatStatus.filled.push(seat);
      } else if (seat.nid) {
        this.seatStatus.filled = [seat]
      }
    }
    if (seat.status && seat.seat_assignable === 0) {
      if (this.seatStatus.not_assignable) {
        this.seatStatus.not_assignable.push(seat);
      } else {
        this.seatStatus.not_assignable = [seat];
      }
    }
  }

  mapReservations(seats: SeatData[]) {
    const svgEl = this.svg.nativeElement;
    for (const seat of seats) {
      this.mapCategories(seat);
      this.mapSeatClass(seat, svgEl);
      this.mapAssignable(seat);
    }
    this.categories = Object.values(this.categories).map((o: any) => {o.type = this.titleCase(o.type); return o;});
  }

  getSeatClass() {
    if (this.selectedSeat) {
      if (this.selectedSeat.seat) {
        return 'reserved';
      }
      if (this.selectedSeat.seat_type) {
        return this.selectedSeat.seat_type;
      }
      if (this.selectedSeat.temporary_seat) {
        return 'seat_temporary';
      }
      if (this.selectedSeat.seat_assignable) {
        return 'seat_assignable';
      }
      if (this.selectedSeat.seat_assignable === 0) {
        return 'seat_not_assignable';
      }
      if (this.selectedSeat.seat_removed) {
        return 'seat_removed';
      }
    }
    return '';
  }

  convertSeatType() {
    if (this.selectedSeat) {
      if (this.selectedSeat.seat_type) {
        return this.selectedSeat.seat_type.replace(/_/g, ' ');
      }
    }
    return '';
  }

  titleCase(str: string): string {
    if (!str) {
      return str;
    }
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());
  }


}
