import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { UserServiceCustom } from 'src/app/services/user.service';
import { LoginCredentials } from 'src/app/services/drupal7/models';
import { environment } from 'src/environments/environment.prod';
import { LogoHeaderComponent } from 'src/app/system/logo-header/logo-header.component';
import { StorageService } from 'src/app/services/storage/storage.service';
import { DrupalConstants } from 'src/app/services/drupal7/public_api';
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, LogoHeaderComponent]
})
export class LoginPage {
  version = environment.version;

  credentials: LoginCredentials = {
    username: '', 
    password: ''
  };
  constructor(
    private userService: UserServiceCustom,
    public router: Router,
    private storageService: StorageService
  ) {}

  login() {
    this.userService.login(DrupalConstants.storageKey, this.credentials, true, environment.checkInType.entityType, environment.checkInType.bundle).then(res => {
      this.router.navigate(['']);
      this.credentials.username = '';
      this.credentials.password = '';
    });
  }
  
  clearCache() {
    this.storageService.clear();
  }
}