import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessagesService } from './messages.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import PaymentMethods from '../../assets/commerce/payment_methods.json';
import { AlertController } from '@ionic/angular';
import { CommercePaymentTransactionService } from './drupal7/drupal7-services.module';
import { APICommerceOrder, Payment } from './drupal7/public_api';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(
    public http: HttpClient,
    private alertCtrl: AlertController,
    private message: MessagesService,
    private commercePaymentTransactionService: CommercePaymentTransactionService
  ) {
  }

  getActivePaymentMethods(prop) {
    const activePaymentMethods = [];
    for (const paymentMethod of PaymentMethods) {
      if (paymentMethod[prop]) {
        activePaymentMethods.push(paymentMethod);
      }
    }
    return activePaymentMethods;
  }
  /*
    See commerce_payment_entity_property_info()
    in sites/all/modules/commerce/modules/payment/commerce_payment.info.inc in Drupal 7
    Make sure to set the setter callback property otherwise the API won't support writing to the fields and will return an error.
    Property to add: 'setter callback' => 'entity_property_verbatim_set',
    Objects to add this property: 'remote_id', 'amount', 'currency_code', 'message'
    After adding this property, you'll need to flush your caches on the site and/or run cron and run updates for the changes to take effect.
  */
  async createDrupalPayment(payment: Payment): Promise<any> {
    // Make sure the amount is properly formatted
    payment.amount = parseInt(payment.amount.toString().replace('.', ''), 10);
    this.message.showLoading('Submitting your payment...', true, 3000);
    console.log(payment);
    const transaction = await this.commercePaymentTransactionService.createPaymentTransaction(payment);
    return transaction;
  }

  async overrideAmountConfirm(payment, order) {
    const alert = await this.alertCtrl.create({
      header: 'Override Payment',
      message: 'Enter the password to continue.',
      backdropDismiss: false,
      inputs: [
        {
          name: 'password',
          type: 'password',
          id: 'password',
          placeholder: 'Password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          handler: form => {
            if (+form.password === environment.appSettings.linkDevicePass) {
              this.overrideAmount(payment, order);
            } else {
              this.message.presentAlert('Invalid password', 'The password you have entered is incorrect');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async overrideAmount(payment: Payment, order: APICommerceOrder) {
    const alert = await this.alertCtrl.create({
      header: 'Enter new payment amount.',
      backdropDismiss: false,
      inputs: [
        {
          name: 'amount',
          type: 'number',
          id: 'amount',
          placeholder: 'Amount',
          value: payment.amount
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          handler: form => {
            const newAmount = parseFloat(form.amount);
            const formattedAmount = formatCurrency(newAmount / 100, 'en-US', '$');
            payment.amount = newAmount;
            order.commerce_order_total = newAmount;
            order.balance = newAmount;
            order.balance_formatted = formattedAmount;
            order.commerce_order_total_formatted = formattedAmount;
          }
        }
      ]
    });
    await alert.present();
  }


}

export enum PaymentMethodOptions {
  elavon_direct = 'elavon_direct',
  commerce_payment_example = 'commerce_payment_example',
  cash = 'cash',
  credit_card_machine = 'credit_card_machine',
  slc_app = 'slc_app'
}
