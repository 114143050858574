import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any) {
    this._storage.set(key, value);
  }

  async storeEntity(storageKey: string, entity: any, entityKey: 'nid' | 'id' | 'item_id' | 'order_id') {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o[entityKey]).indexOf(entity[entityKey]);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  getEach() {
    this._storage?.forEach((value, key, index) => {
      this.get(key).then(val => {
        console.log(val);
      });
    });
  }

  async get(key: string) {
  
    if (!this.storage) {
      return null;
    }
  
    try {
      const value = await this.storage.get(key);
      return value;
    } catch (error) {
      return null;
    }
  }
  

  getAll(key: string): Observable<any> {
    return from(this._storage.get(key)).pipe(
      map((storedOperations: any) => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          console.log(storedObj);
          return storedObj;
        } else {
          console.log('no local data for type: ' + key);
          return firstValueFrom(of(false));
        }
      })
    );
  }

  async clear() {
    this._storage.forEach((v, k, i) => {
        this._storage.remove(k);
    });
  }

  async remove(storageKey: string) {
    return this._storage.remove(storageKey).then(res => res).catch(() => false);
  }


}

export interface StoredRequest {
  url: string;
  type: string;
  data: any;
  time: number;
  reqID: string;
  id: string;
  entityType: string;
}
