import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { addHours, format, getUnixTime, parseISO, formatISO } from 'date-fns';
import { KeyValue } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { DrupalFormControlObject, FormFromJSON, Options, SchemaService, SystemConnection, ViewOptions, ViewService } from '../drupal7/public_api';
import { FieldGroup } from './forms-model';
import { ConditionalFields, FieldGroupOptions, FormFieldOptions } from '../drupal7/models/form-controls';
import { toZonedTime } from 'date-fns-tz';
import { AddressFieldService } from './addressfield.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(
    private fb: FormBuilder,
    private schemaService: SchemaService,
    private addressFieldService: AddressFieldService,
    private viewService: ViewService,
    private alertCtrl: AlertController,
  ) {}

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;
  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value.localeCompare(b.value);

  async getInternalForm(session: SystemConnection, entityType: string, entityBundle: string, viewMode: string = 'form', opts: FormFieldOptions, formOpts?: FieldGroupOptions) {
    const schema = await import('../../../assets/'+ entityType + '/' + entityBundle + '.json').then(res => res.default);
    if (schema) {
      return this.processForm(schema, session, opts, formOpts);
    }
  }

  async getExternalForm(session: SystemConnection, entityType: string, entityBundle: string, viewMode: string = 'form', opts: FormFieldOptions, formOpts?: FieldGroupOptions) {
      const schema = await this.schemaService.getSchema(entityType, entityBundle, viewMode, session.user.uid);
      if (schema) {
        return this.processForm(schema, session, opts, formOpts);
      }
  }

  async processForm(controls: any, session: SystemConnection, opts: FormFieldOptions, formOpts?: FieldGroupOptions) {

    /*
       Send to backend API. The custom module services_ionic for Drupal 7 was
       created to send a JSON response of any entity structure and have it
       be immediately immplemented to the app without any changes.
       The JSON response contains bundle_info: DrupalEntityBundleInfo and form_fields: DrupalFormControlObject
    */
       const fields = [];
       const fieldGroups: DrupalFormControlObject[] = [];
       const nestedFieldGroups = [];
       const bundleInfo = controls.ionic_form.bundle_info;
       let conditional_fields: ConditionalFields = controls.conditional_fields;
       let administrativeAreas: any;
         administrativeAreas = this.setupAdministrativeAreas(controls.ionic_form.form_fields);
         for (const group of controls.ionic_form.form_fields as DrupalFormControlObject[]) {
           if (group.ionic_type === 'field_group' && group.access) {
             const newFieldGroup = new FieldGroup(group.field_name, group.label, group.description, group.type, group.ionic_type, group.status, group.access, group.visible, []);
             for (const field of group.fields) {
               const fieldAccess = this.checkFieldPermission(field, session, opts, group);
               if (fieldAccess) {
                 if (field.fields) {
                   this.setupNestedFields(field, session, opts, administrativeAreas, fields, nestedFieldGroups);
                   newFieldGroup.fields.push(field);
                 } else {
                   if (field.type === 'entityreference') {
                     this.setupEntityReference(field, session, opts);
                   }
                   fields.push(field);
                   newFieldGroup.fields.push(field);
                 }
               }
             }
             fieldGroups.push(newFieldGroup);
           } else if (group.fields) {
             this.setupNestedFields(group, session, opts, administrativeAreas, fields, nestedFieldGroups);
             fieldGroups.push(group);
           } else {
             const fieldAccess = this.checkFieldPermission(group, session, opts);
             if (group.type === 'entityreference') {
               this.setupEntityReference(group, session, opts);
             }
             if (fieldAccess) {
               fields.push(group);
             }
             if (group.access) {
               fieldGroups.push(group);
             }
           }
         }
       const formGroup = await this.buildForm(fields);
       // const sortedFields = fieldGroups.sort((a, b) => this.originalOrder(a, b));
       const sortedFields = fieldGroups.sort((a, b) => {
         if (a.weight && b.weight) {
           return +a.weight < +b.weight ? -1 : 0;
         } else if ((a.ionic_type === 'field_group' && a.fields.length) && (b.ionic_type === 'field_group' && b.fields.length)) {
           a.fields.sort((c, d) => {
             return +c.weight < +d.weight ? -1 : 0;
           });
           b.fields.sort((e, f) => {
             return +e.weight < +f.weight ? -1 : 0;
           });
         }
          else {
           return 0;
         }
       });
 
       // Initialize field dependencies
       this.setupConditionalFields(conditional_fields, sortedFields, formGroup);
       const isFieldGroup = this.isFieldGroupForm(sortedFields);
 
       const res: FormFromJSON = {
         form_fields: sortedFields,
         formGroup,
         nestedFieldGroups,
         bundle_info: bundleInfo,
         activeIndex: 0,
         formSubmitLabel: 'Submit',
         administrativeAreas,
         conditional_fields,
         isFieldGroup
       };
       if (!isFieldGroup && formOpts?.wrap && formOpts?.group) {
         const wrappedFieldGroup: DrupalFormControlObject[] = [];
         const fieldGroup = new FieldGroup(formOpts.group.field_name, formOpts.group.label, formOpts.group.description, formOpts.group.type, formOpts.group.ionic_type, formOpts.group.status, formOpts.group.access, formOpts.group.visible, sortedFields);
         wrappedFieldGroup.push(fieldGroup);
         res.form_fields = wrappedFieldGroup;
         res.isFieldGroup = true;
       }
       return res; 
  }

  setupConditionalFields(conditionalFields, fields, formGroup) {
    if (conditionalFields.dependents) {
      for (const f of fields) {
        if (f.ionic_type === 'field_group') {
          for (const field of f.fields) {
            this.valueChangesSubscription(field, formGroup, f.fields, conditionalFields);
            this.conditionalField(null, field, formGroup, f.fields, conditionalFields);
          }
        } else {
          this.valueChangesSubscription(f, formGroup, fields, conditionalFields);
          this.conditionalField(null, f, formGroup, fields, conditionalFields);
        }
      }
    }
  }

  valueChangesSubscription(field: DrupalFormControlObject, formGroup: FormGroup, fields: DrupalFormControlObject[], conditionalFields: ConditionalFields) {
    const formControl = formGroup.get(field.field_name);
    if (formControl) {
      formControl.valueChanges.subscribe(v => {
        this.conditionalField(v, field, formGroup, fields, conditionalFields);
      });
    }
  }

  setupNestedFields(field: DrupalFormControlObject, session: SystemConnection, opts: FormFieldOptions, administrativeAreas: any, fields: DrupalFormControlObject[], nestedFieldGroups: DrupalFormControlObject[]) {
    const nestedFields = [];
    for (const nestedField of field.fields) {
      const nestedFieldAccess = this.checkFieldPermission(nestedField, session, opts, field);
      if (nestedFieldAccess) {
        nestedFields.push(nestedField);
      }
      if (field.ionic_type === 'addressfield' && nestedField.ionic_type === 'state' && !administrativeAreas) {
        administrativeAreas = this.getAdministrativeAreas(nestedField);
      }
    }
    if (field.ionic_type === 'addressfield') {
        this.setupAddressFieldLabels(field);
    }
    field.fields = nestedFields;
    fields.push(field);
    nestedFieldGroups.push(field);
  }

  async setupEntityReference(field: DrupalFormControlObject, session: SystemConnection, opts: FormFieldOptions) {
    if ((opts?.entityRef?.getAvailableOptions?.indexOf(field.field_name) > -1)) {
      await this.getEntityRefOptions(field, field.field_name);
    }
    if ((opts?.entityRef?.excludeOptions?.indexOf(field.field_name) > -1)) {
      field.options = [];
    }
  }

  setupAdministrativeAreas(fields: DrupalFormControlObject[]) {
    const addressField = this.findObjectWithKey(fields, 'ionic_type', 'state');
    if (addressField) {
      return this.getAdministrativeAreas(addressField);
    }
  }

  setupAddressFieldLabels(addressField: DrupalFormControlObject) {
    const labels = {
      postal_code: 'Zip code',
      country: 'Country',
      organisation_name: 'Company',
      name_line: 'Name on card',
      first_name: 'First Name',
      last_name: 'Last Name',
      thoroughfare: 'Address 1',
      premise: 'Address 2',
      sub_premise: 'Address 2',
      locality: 'City',
      dependent_locality: 'Suburb',
      administrative_area: 'State',
      sub_administrative_area: 'County',
    }

    if (addressField?.fields) {
      addressField.fields.map(field => labels[field.field_name] ? field.label = labels[field.field_name] : null);
    }
  }

   findObjectWithKey(fields: DrupalFormControlObject[], prop: string, val: string) {
    for (const obj of fields) {
      if (obj[prop] === val) {
        return obj;
      }
      if (Array.isArray(obj.fields) && obj.fields.length > 0) {
        const result = this.findObjectWithKey(obj.fields, prop, val);
        if (result !== undefined) {
          return result;
        }
      }
    }
    return undefined;
  }

  workflowField(field: DrupalFormControlObject) {
    if (field.type === 'workflow') {
      field.status = false;
      field.access = false;
    }
  }

  getAdministrativeAreas(addressField: DrupalFormControlObject) {
    const options = [...addressField.options];
    return options;
  }

  changeFieldPermissions(fieldName: string, field: DrupalFormControlObject, opts: FormFieldOptions) {
    if (opts?.enabled?.indexOf(fieldName) > -1) {
      field.status = true;
      field.create = true;
      field.access = true;
    }
    if (opts?.hidden?.indexOf(fieldName) > -1) {
      field.status = false;
      field.access = false;
    }
    if (opts?.excluded?.indexOf(fieldName) > -1) {
      field.status = false;
      field.access = false;
      field.create = false;
    }
  }

  checkFieldPermission(field: DrupalFormControlObject, session: SystemConnection, opts: FormFieldOptions, parentField?: DrupalFormControlObject): boolean {
    if (parentField) {
      const fieldName = `${parentField.field_name}__${field.field_name}`;
      this.changeFieldPermissions(fieldName, field, opts);
    } else {
      this.changeFieldPermissions(field.field_name, field, opts);
    }

    this.workflowField(field);
    if (field.status) {
      if (field.field_permissions_role_access) {
        let res: boolean = false;
        Object.keys(session.user.roles).map(key => {
          if (field.field_permissions_role_access[key]) {
            const index = field.field_permissions_role_access[key].perms.indexOf('create ' + field.field_name);
            if (index > -1) {
              res = true;
            }
          }
        });
        return res;
      } else if (field.create) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isEntityRef(entity: any, key: string, formSchema: any) {
    if (formSchema.formGroup.controls[key]) {
      for (const g of formSchema.form) {
        if (g.fields) {
          const formField: DrupalFormControlObject = g.fields.filter(o => o.field_name === key)[0];
          if (formField.type === 'entityreference' && formField.field_name === key) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }

  isFieldGroupForm(formFields: DrupalFormControlObject[]) {
    if (formFields[0].ionic_type === 'field_group') {
      return true;
    }
  }

  async buildForm(controls: DrupalFormControlObject[]) {
    const entityForm = this.fb.group({});
        for (const control of controls) {
          if (control.fields && !control.multiple) {
            const newGroup = new FormGroup({});
            if (control.required) {
              newGroup.setValidators(Validators.required);
            }
              for (const child of control.fields) {
              if (child.create) {
                const newControl = new FormControl();
                if (child.required) {
                  newControl.setValidators(Validators.required);
                }
                if (child.type === 'email') {
                  newControl.setValidators(Validators.email);
                }
                if (child.default_value) {
                  newControl.setValue(child.default_value);
                }
                newGroup.addControl(child.field_name, newControl);
              }
            }
            entityForm.addControl(control.field_name, newGroup);
        } else if (control.multiple && control.ionic_type === 'group' || control.multiple && control.ionic_type === 'table_group') {
          const newArray = new FormArray([]);
          const newGroup = new FormGroup({});
          if (control.required) {
            newGroup.setValidators(Validators.required);
          }
          for (const child of control.fields) {
            if (child.create) {
              const newControl = new FormControl();
              if (child.required) {
                newControl.setValidators(Validators.required);
              }
              if (child.type === 'email') {
                newControl.setValidators(Validators.email);
              }
              if (child.default_value) {
                newControl.setValue(child.default_value);
              }
              newGroup.addControl(child.field_name, newControl);
            }
          }
          newArray.push(newGroup);
          entityForm.addControl(control.field_name, newArray);
        } else if (control.multiple && control.ionic_type === 'array') {
          const newArray = new FormArray([]);
          newArray.push(new FormControl(''));
          entityForm.addControl(control.field_name, newArray);
        } else {
          const newFormControl = new FormControl();
          if (control.required) {
            newFormControl.setValidators(Validators.required);
          }
          if (control.type === 'email') {
            newFormControl.setValidators(Validators.email);
          }
          if (control.default_value) {
            newFormControl.setValue(control.default_value);
          }
          if (control.type === 'datetime') {
            const date = this.setDefaultDate();
            newFormControl.patchValue(date);
          }
            entityForm.addControl(control.field_name, newFormControl);
          }
        }
        return entityForm;
  }

  setDefaultDate() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = formatISO(toZonedTime(new Date(), userTimeZone));
    return date;
  }

  convertObjectToArray(object: object) {
    const newArray = [];
    Object.keys(object).map(key => {
      key = object[key];
      if (typeof(object[key]) === 'object' && !Array.isArray(object[key]) && object[key] !== null) {
        Object.entries(object[key]).map(([nestedKey, nestedValue], x) => {
          if (key === nestedKey) {
            object[key] = nestedValue;
          }
        });
      } else {
        newArray.push(key);
      }
      return newArray;
    });
    return newArray;
  }

  goToFieldGroup(i: number, form: FormGroup, fields: DrupalFormControlObject[]) {
    let x = 0;
    for (const fieldGroup of fields) {
      if (x === i - 1) {
        for (const field of fieldGroup.fields) {
          if (!form.controls[field.field_name].valid &&
            form.controls[field.field_name].hasValidator(Validators.required)) {
            this.fixErrors(field);
            return;
          }
        }
        fields[x].access = false;
      } else if (x === i ) {
        fields[x].access = true;
        return x;
      } else {
        fields[x].access = false;
      }
      x++;
    }
  }

  addArrayField(arrayName: string, form: FormGroup) {
    const control = this.getFormArray(arrayName, form);
    control.push(new FormControl(''));
  }

  addArrayGroup(arrayName: string, fields: DrupalFormControlObject[], form: FormGroup) {
    const control = this.getFormArray(arrayName, form);
    const oneGroup = new FormGroup({});
    fields.map(child => {
      oneGroup.addControl(child.field_name, new FormControl());
    });
    control.push(oneGroup);
  }

  removeArrayGroup(i: number, arrayName: string, form: FormGroup) {
    const control = this.getFormArray(arrayName, form);
    control.removeAt(i);
  }

  checkNextGroup(i: number, fields: DrupalFormControlObject[]) {
    fields[i + 1].visible = true;
  }

  getFormArray(key: string, form: FormGroup) {
    return form.controls[key] as FormArray;
  }

  getFormControlFromArray(key: string, form: FormGroup, index: number): FormControl {
    const formArray = form.controls[key] as FormArray;
    return formArray.at(index) as FormControl;
  }  

  getAdministrativeAreaOptions(options: any) {
    return !Array.isArray(options[0].value) ? options : [];
  }

  updateAdministrativeAreas(event: any, addressField: DrupalFormControlObject[], options: any) {
    const country = event?.detail?.value;
    const administrativeAreaField = addressField.find(field => field.ionic_type === 'state');
    if (administrativeAreaField) {

      const newOptions = options.find(option => option.key === country);
      
      if (newOptions && newOptions.value) {
        administrativeAreaField.options = newOptions.value;
      } else {
        // Reset the options to the original list
        administrativeAreaField.options = options;
      }
    }
  }

  deepCopy(obj: any) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    
    if (Array.isArray(obj)) {
      return obj.map(item => this.deepCopy(item));
    }
    
    const newObj = {};
    for (const key in obj) {
      newObj[key] = this.deepCopy(obj[key]);
    }
    
    return newObj;
  }

  async fixErrors(field: DrupalFormControlObject) {
    const alert = await this.alertCtrl.create({
      header: 'Missing Information',
      message: field.label + ' is required.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          cssClass: 'primary',
        }
      ]
    });
    await alert.present();
  }

  getFieldControl(key: string, form: FormGroup, fields: DrupalFormControlObject[]) {
    if (form.controls[key]) {
      const formField: DrupalFormControlObject = fields.find(field => {
        if (field.field_name === key) {
          return field;
        } else if (field.fields) {
          return this.getFieldControl(key, form, field.fields);
        }
      });
      if (formField) {
        return formField;
      }
    }
  } 

  async getEntityRefOptions(fieldElement: DrupalFormControlObject, entity: any) {
    const options: ViewOptions = {
      display_id: '',
      convert_options: true
    };
    const url = entity ? `available/${fieldElement.field_name}` : `available/${fieldElement.field_name}/${entity[fieldElement.field_name]}`;
    await this.viewService.getView(url, options).then(res => {
      fieldElement.options = res.results;
    });
    return fieldElement;
  }

  async getAutoCompleteOptions(e: any, field: DrupalFormControlObject) {
    const options: ViewOptions = {
      display_id: '',
      convert_options: true
    };
    const url = `available/${field.field_name}?id=${e.detail.value}`;
    return field.options = await this.viewService.getView(url, options).then(res => res.results);
  }

  loadEntity(entity: any, form: FormGroup, fields: DrupalFormControlObject[], nestedFields: DrupalFormControlObject[], administrativeAreas: Options[]) {
    if (entity.user) {
      entity = entity.user;
    }
    
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      const formField = this.findObjectWithKey(fields, 'field_name', key);
      
      if (!control) {
        return;
      }
      if (control['controls']) {
        return this.loadEntity(entity, control as FormGroup, fields, nestedFields, administrativeAreas);
      }
  
      if (entity[key]) {
        if (formField.ionic_type === 'list' && formField.field_name === 'country') {
          const addressField: DrupalFormControlObject = this.findObjectWithKey(fields, 'ionic_type', 'addressfield');
          if (addressField) {
            this.updateAdministrativeAreas({detail: {value: entity[key]}}, addressField.fields, administrativeAreas);
          }
        }
        if (formField.ionic_type === 'entityreference' || formField.ionic_type === 'autocomplete') {
          this.getEntityRefOptions(formField, entity).then(() => {
            if (entity[key].und) {
              control.setValue(entity[key].und[0].target_id.toString());
            }
          });
        } else if (formField.type === 'email' && !entity[key]) {
          // Set the value of the email address to be the user's email
          control.setValue(entity.mail);
        } else if (formField.ionic_type === 'date' || formField.ionic_type === 'enddate_get') {
          if (entity[key]?.und) {
            const formattedDate = format(parseISO(entity[key].und[0].value), 'yyyy-MM-dd');
            control.setValue(formattedDate);
          }
        } else if (Array.isArray(entity[key]) && entity[key].length) {
          control.setValue(entity[key]);
        } else if (!Array.isArray(entity[key]) && entity[key]?.und) {
          control.setValue(entity[key].und[0].value);
        } else {
          control.setValue(entity[key]);
        }
      }
  
      if (Array.isArray(control['controls']) && control['controls']) {
        const fieldCollection = nestedFields.find(o => o.field_name === key);
        if (fieldCollection) {
          // Remove the initial control since we will add controls for each entity loaded
          this.removeArrayGroup(0, key, form);
  
          entity[key].forEach((arrayItem, z) => {
            this.addArrayGroup(key, fieldCollection.fields, form);
            Object.keys(arrayItem).forEach(nestedKey => {
              const arrControl = control['controls'][z].get(nestedKey);
              if (arrControl) {
                arrControl.setValue(arrayItem[nestedKey]);
              }
            });
          });
        }
      } else if (typeof control['controls'] === 'object' && control['controls'] !== null) {
        Object.keys(control['controls']).forEach(nestedKey => {
          const nestedControl = control.get(nestedKey);
          if (nestedControl) {
            nestedControl.setValue(entity[key + '_' + nestedKey]);
          }
        });
      }
    });
  }
  
  convertObjectWithNestedKey(object: object) {
    Object.keys(object).map(key => {
      const val = object[key];
      if (typeof(val) === 'object' && !Array.isArray(val) && val !== null) {
        Object.entries(val).map(([nestedKey, nestedValue], x) => {
          if (key === nestedKey) {
            return object[key] = nestedValue;
          }
        });
      }
    });
    return object;
  }

  formatDate(form: FormGroup, field: DrupalFormControlObject) {
    if (form.value[field.field_name] !== null) {
      switch(field.presentation) {
        case 'date': {
          return format(parseISO(form.value[field.field_name]), 'EEEE, MMMM dd, yyyy');
        }
        case 'month-year': {
          return format(parseISO(form.value[field.field_name]), 'MMMM yyyy');
        }
        case 'month': {
          return format(parseISO(form.value[field.field_name]), 'MMMM');
        }
        case 'year': {
          return format(parseISO(form.value[field.field_name]), 'yyyy');
        }
        default: {
          return format(parseISO(form.value[field.field_name]), 'yyyy-MM-dd');
        }
      }
    } else {
      return;
    }
  }

  formatNodeFormFieldValuesFormControl(field: DrupalFormControlObject, form: FormGroup) {
    if (!field.required && form.value[field.field_name] === null) {
      delete form.value[field.field_name];
    }
    switch(field.ionic_type) {
      case 'markup':
        delete form.value[field.field_name];
        break;
      case 'date':
        const unixTime = new Date(form.value[field.field_name]).valueOf().toString().substring(0, 10);
        // const formattedString = format(parseISO(this.entityForm.value[field.field_name]), 'yyyy-MM-dd HH:mm:ss');
        form.value[field.field_name] = unixTime;
        break;
      case 'enddate_get':
        Object.keys(form.value[field.field_name])
        .map(key => {
          const value = form.value[field.field_name][key];
          form.value[field.field_name][key] = format(parseISO(value), 'yyyy-MM-dd HH:mm:ss');
        });
        break;
      default:
        break;
    }
    if (field.type === 'commerce_price') {
      form.value[field.field_name] = {amount: form.value[field.field_name] * 100, currency_code: 'USD'};
    }
    if (field.text_processing) {
      form.value[field.field_name] = {value: form.value[field.field_name]};
    }
    if (field.multiple && !Array.isArray(form.value[field.field_name])) {
      form.value[field.field_name] = [form.value[field.field_name]];
    }
    return form;
  }

  prepareFormValuesFormControl(fields: DrupalFormControlObject[], form: FormGroup) {
    for (const field of fields) {
      if (field.ionic_type === 'field_group') {
        for (const subfield of field.fields) {
          this.formatNodeFormFieldValuesFormControl(subfield, form);
        }
      } else {
        this.formatNodeFormFieldValuesFormControl(field, form);
      }
    }
    return form.value;
  }

  prepareFormValues(fields: DrupalFormControlObject[], form: FormGroup): any {
    const result = {};
    for (const field of fields) {
      if (field.ionic_type === 'field_group') {
        for (const subfield of field.fields) {
          this.formatFormFieldValuesForDrupal(subfield, form, result);
        }
      } else {
        this.formatFormFieldValuesForDrupal(field, form, result);
      }
    }
    return result;
  }

  formatFormFieldValuesForDrupal(field: DrupalFormControlObject, form: FormGroup, result: any) {
    switch(field.ionic_type) {
      case 'markup':
        break;
      case 'date':
        // Convert the time to unix and add 7 hours for the timezone offset
        let unixTime = getUnixTime(new Date(form.value[field.field_name]));
        if (form.value[field.field_name].endsWith('Z')) {
          unixTime = getUnixTime(addHours(new Date(form.value[field.field_name]), 7));
        }
        result[field.field_name] = unixTime;
        break;
      case 'enddate_get':
        Object.keys(form.value[field.field_name])
        .map(key => {
          // Convert the time to unix and add 7 hours for the timezone offset
          let nestedUnixTime = getUnixTime(new Date(form.value[field.field_name][key]));
          if (form.value[field.field_name].endsWith('Z')) {
            nestedUnixTime = getUnixTime(addHours(new Date(form.value[field.field_name][key]), 7));
          }
          result[field.field_name][key] = nestedUnixTime;
        });
        break;
      default:
        result[field.field_name] = form.value[field.field_name];
        break;
    }
    if (field.type === 'commerce_price') {
      result[field.field_name] = {amount: form.value[field.field_name] * 100, currency_code: 'USD'};
    }
    if (field.type === 'text_with_summary') {
      result[field.field_name] = {value: form.value[field.field_name]};
    }
    if (field.text_processing) {
      result[field.field_name] = {value: form.value[field.field_name]};
    }
    if (field.multiple && !Array.isArray(form.value[field.field_name])) {
      result[field.field_name] = [form.value[field.field_name]];
    }

    // Remove the null values to prevent errors
    if (form.value[field.field_name] === null) {
      delete result[field.field_name];
    }
    return result;
  }


  /**************************
   * Conditional Fields
   **************************/
  conditionalField(value: any, dependeeField: DrupalFormControlObject, form: FormGroup, fields: DrupalFormControlObject[], conditionalFields: ConditionalFields) {
    if (conditionalFields?.dependees[dependeeField.field_name]) {
  
      Object.keys(conditionalFields.dependees[dependeeField.field_name]).map(id => {
        const dependency = conditionalFields.dependees[dependeeField.field_name][id];
        let prop = 'value';
        switch(dependeeField.module) {
          case 'entityreference': {
            prop = 'target_id';
            break;
          }
          default: {
            prop = 'value'
            break;
          }
        }

        let isValue = (dependency.options.values_set === 1 && dependency.options.value.map(v => v[prop].toString()).indexOf(value) > -1);
        let andValue = (dependency.options.values_set === 2 && dependency.options.values.indexOf(value) > -1)
        let orValue = (dependency.options.values_set === 3 && dependency.options.values.indexOf(value) > -1)
        let xorValue = (dependency.options.values_set === 4 && dependency.options.values.indexOf(value) === -1);
        let notValue = (dependency.options.values_set === 5 && dependency.options.values.indexOf(value) === -1);
        if (dependeeField?.multiple && value) {
          isValue = (dependency.options.values_set === 1 && dependency.options.value.map(v => v[prop].toString()).some(v => value.includes(v)));
          notValue = (dependency.options.values_set === 5 && dependency.options.value.map(v => v[prop].toString()).some(v => !value.includes(v)));
        }

        switch(dependency.options.state) {
          case 'visible': {
            const access = (isValue || notValue) ? true : false;
            this.showHideDependentField(access, dependency.dependent, form, fields);
            break;
          }
          case '!visible': {
            const access = (isValue || notValue) ? false : true;
            this.showHideDependentField(access, dependency.dependent, form, fields);
            break;
          }
          case 'required': {
            const field = this.getDependentFieldAndControl(dependency.dependent, form, fields);
            if (field) {
              const required = (isValue || notValue) ? true : false;
              this.toggleRequiredDependentField(required, field.dependentField, field.dependentControl)
            }
            break;
          }
        }
      });
    }
  }

  getDependentFieldAndControl(dependentFieldName: string, form: FormGroup, fields: DrupalFormControlObject[]) {
    const dependentControl = form.get(dependentFieldName);

    if (!dependentControl) {return;}

    if (dependentControl) {
      const dependentField = fields.find(f => {
        if (f.ionic_type === 'field_group' && f.fields?.length && f.field_name !== dependentFieldName) {
          return f.fields.find(g => g.field_name === dependentFieldName);
        } else {
          return f.field_name === dependentFieldName;
        }
      });

      return {dependentField, dependentControl};
    }
  }

  showHideDependentField(fieldAccess: boolean, dependentFieldName: string, form: FormGroup, fields: DrupalFormControlObject[]) {
    const field = this.getDependentFieldAndControl(dependentFieldName, form, fields);
    if (field?.dependentField) {
      if (field.dependentField?.fields?.length) {
        field.dependentField.fields.map(f => {
          const controls = field.dependentControl as FormArray;
          if (controls.controls[f.field_name]) {
            this.processShowHideDependentField(fieldAccess, f, controls.controls[f.field_name]);
          } else {
            for (const control of controls.controls as FormGroup[]) {
              this.processShowHideDependentField(fieldAccess, f, control.controls[f.field_name]);
            }
          }
        });
      }
      this.processShowHideDependentField(fieldAccess, field.dependentField, field.dependentControl);
    }
  }

  processShowHideDependentField(fieldAccess: boolean, dependentField: DrupalFormControlObject, dependentControl: AbstractControl) {
    dependentField.access = fieldAccess;
    this.processRequiredDependentField(dependentField, dependentControl);
  }

  toggleRequiredDependentField(required: boolean, dependentField: DrupalFormControlObject, dependentControl: AbstractControl) {
    dependentField.required = required;
    if (required) {
      if (!dependentControl.hasValidator(Validators.required)) {
        dependentControl.addValidators(Validators.required);
      }
    } else {
      if (dependentControl.hasValidator(Validators.required)) {
        dependentControl.removeValidators(Validators.required);
      }
    }
  }

  processRequiredDependentField(dependentField: DrupalFormControlObject, dependentControl: AbstractControl) {
    if (dependentField.required && !dependentField.access && dependentControl.hasValidator(Validators.required)) {
      dependentControl.removeValidators(Validators.required);
      dependentControl.reset();
      dependentControl.updateValueAndValidity();
    }
    if (dependentField.required && dependentField.access && !dependentControl.hasValidator(Validators.required)) {
      dependentControl.reset();
      dependentControl.addValidators(Validators.required);
      dependentControl.updateValueAndValidity();
    }
  }

}