import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { StorageService } from './app/services/storage/storage.service';
import { HttpClientModule } from '@angular/common/http';
import { Drupal7ServicesModule, DrupalConstants, Settings } from './app/services/drupal7/public_api';
import { Drupal10ServicesModule, DrupalConstants as Drupal10Constants, Settings as Drupal10Settings } from './app/services/drupal10/public_api';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import '@angular/compiler';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';


if (environment.production) {
  enableProdMode();
}

const drupalSettings: Settings = {
  apiHost: 'slconferenceasia.com',
  apiEndPoint: 'api',
  language: 'und',
  requestTimeout: 15000,
  apiProtocol: 'https',
  allowOffline: true,
  cookieHeader: true,
  sessionDays: 23,
  sessionStorageKey: 'd7_session',
  timestampKey: 'timestamp'
};
DrupalConstants.Settings = drupalSettings;

const drupal10Settings: Drupal10Settings = {
  apiHost: 'slconference.com',
  apiEndPoint: '',
  language: 'und',
  requestTimeout: 15000,
  apiProtocol: 'https',
  allowOffline: true,
  cookieHeader: true,
  sessionDays: 23,
  sessionStorageKey: 'd10_session',
  timestampKey: 'd10_timestamp'
};
Drupal10Constants.Settings = drupal10Settings;

bootstrapApplication(AppComponent, {
  providers: [
    Storage,
    StorageService,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxQRCodeModule,
    BarcodeScanner,
    AngularFirestore,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    importProvidersFrom(AngularFireModule.initializeApp(environment.firebaseConfig),),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(IonicModule.forRoot({})),
    importProvidersFrom(Drupal7ServicesModule),
    importProvidersFrom(Drupal10ServicesModule),
    importProvidersFrom(IonicStorageModule.forRoot({
      name: '__slc_asia_registration',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })),
    provideRouter(routes),
  ],
});
