import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../system/header/header.component';
import { FirebaseTableAssignmentsComponent } from './firebase-table-assignments/firebase-table-assignments.component';
import { Country, Region, Table, TableAnalytics, TableAssignment, TableAssignmentsService } from '../services/table-assignments.service';
import { FlagAction, FlagService, ViewOptions } from '../services/drupal7/public_api';
import { FirebaseService } from '../services/firebase.service';
import { SeatChangeModalComponent } from './seat-change/seat-change.component';
import { GroupMemberService } from '../services/drupal10/public_api';

@Component({
  selector: 'app-table-assignments',
  templateUrl: './table-assignments.component.html',
  styleUrls: ['./table-assignments.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, HeaderComponent, FirebaseTableAssignmentsComponent]
})
export class TableAssignmentsComponent implements OnInit {

    public tableAssignmentsService = inject(TableAssignmentsService);
    private firebaseService = inject(FirebaseService);
    private modalCtrl = inject(ModalController);
    private flagService = inject(FlagService);
    private groupMemberService = inject(GroupMemberService);

    loading: HTMLIonLoadingElement;

    table_assignments: Table[] = [];
    regions: Region[] = [];
    countries: Country[] = [];
    filters = {
      type: 'all',
      inputData: '',
      assigned: 'all',
      table: '',
    };

    tableCount = 40;
    reservedTables = 2;
    availableTables = this.tableCount - this.reservedTables;
    seatsPerTable = 10;
    currentTable = 1;
    assignMode = 'per_table';
    assignFailures = 0;

    showAllColumns = false;
    showRegions = false;
    showCountries = false;
    showCountriesPerTable = false;
    showSidebar = false;
    showFirebaseList = false;
    hasPeople = false;
    viewMode = 'grid';

    primaryOptions: ViewOptions = {
      display_id: 'missions_connect_registrations_delegates',
      filters: {
        checked_in: '',
        field_linked_conference_target_id: 18212,
        group_manager_target_id: '',
        group_manager_target_id_op: '',
        country_value: '',
        flagged_attending: '1',
        table_assigned: '0',
        field_attend_missions_connect_value: '',
        field_attend_missions_connect_value_1: '',
        spouse_delegate_type: [],
        delegate_type: []
      }
    };
    spouseOptions: ViewOptions = {
      display_id: 'missions_connect_registrations_delegates',
      filters: {
        checked_in: '',
        field_linked_conference_target_id: 18212,
        group_manager_target_id: '',
        group_manager_target_id_op: 'in',
        country_value: '',
        flagged_attending: '1',
        field_attend_missions_connect_value: '',
        field_attend_missions_connect_value_1: '1',
        spouse_delegate_type: ['professional_businessman', 'missionary'],
        delegate_type: [
          'pastor', 'filipino_pastor', 'pastor-wife',
          'ministry_employee', 'church_member',
          'bible_college_student', 'homemaker',
          'conference_speaker', 'support_staff', 'wcbc_student']
      }
    };

    analytics: TableAnalytics = new TableAnalytics();

    @ViewChild('dataTable', { static: false }) table!: ElementRef;
    copySuccess: string = '';

    constructor() {}

    ngOnInit() {
      console.log(this.table_assignments);
      this.table_assignments = this.tableAssignmentsService.createTables();
      console.log(this.table_assignments);
      // this.getDelegates();
    }


  copyTable() {
    if (!this.table) {
      this.copySuccess = 'Table not found!';
      return;
    }

    const rows = this.table.nativeElement.querySelectorAll('tr');
    let tableText = '';

    rows.forEach(row => {
      const cells = row.querySelectorAll('th, td');
      const rowText = Array.from(cells).map((cell: any) => cell.innerText).join('\t'); // Use tab separation
      tableText += rowText + '\n'; // Add newline for rows
    });

    navigator.clipboard.writeText(tableText).then(() => {
      this.copySuccess = 'Table copied to clipboard!';
      setTimeout(() => this.copySuccess = '', 2000); // Hide success message after 2 seconds
    }).catch(err => {
      console.error('Failed to copy: ', err);
      this.copySuccess = 'Failed to copy table!';
    });
  }

    async getSLCAsiaDelegates() {
      const data = await this.tableAssignmentsService.getPrimaryAndSpouseDelegates();
      console.log(data);
    }

    async getRegions() {
      const data = await this.tableAssignmentsService.getRegions();
      console.log(data);
    }

    async getDelegates(type: 'all' | 'per_region') {
      this.tableAssignmentsService.assignType = type;

      const data = await this.tableAssignmentsService.getDelegates(this.table_assignments);
      if (data) {
        console.log(data);
        this.table_assignments = data.tables;
        this.regions = data.regions.sortedRegions;
        this.analytics = data.regions.analytics;
        this.hasPeople = true;
      }
    }

    getTableData(tableFromAssignment: Table, key: string) {
      return this.tableAssignmentsService.getTableData(tableFromAssignment, key, this.table_assignments);
    }

    getTableColor(table: Table) {
      return this.tableAssignmentsService.getTableColor(table);
    }

    async getDelegatesFromCSV(type: 'all' | 'per_region') {
      this.tableAssignmentsService.assignType = type;
      const data = await this.tableAssignmentsService.splitCsv();
      if (data) {
        console.log(data);
        this.table_assignments = data.tables;
        this.regions = data.regions.sortedRegions;
        this.analytics = data.regions.analytics;
        this.hasPeople = true;
      }
    }

    totalFiltered() {
      return this.tableAssignmentsService.totalFiltered(this.table_assignments);
    }

    filterResults() {
      if (this.filters.table !== '') {
        this.table_assignments.map(t => t.hidden = t.tableNumber.toString() !== this.filters.table ? true : false);
      } else {
        this.table_assignments.forEach(t => t.hidden = false);
      }
      this.tableAssignmentsService.filterResults(this.filters, this.table_assignments);
    }

    async saveAssignment(person: TableAssignment) {
      if (!person) {
        return;
      }
      const personObject = Object.assign({}, person);
      const id = `${person.foreign_key}_${person.id.toString()}`;
      const newRecord = await this.firebaseService.setDocument(personObject, id);
      if (newRecord) {
        this.flagSeatAssigned(person);
      }
    }

    async checkInSpeaker(person: TableAssignment, checked_in: string = 'Yes') {
      const id = `${person.foreign_key}_${person.id.toString()}`;
      this.firebaseService.updateCheckedIn(id, checked_in);
    }

    async changeSeat(person: TableAssignment) {
      const tables = this.tableAssignmentsService.createTables();
      const tableNumbers = tables.map(t => t.tableNumber.toString());
      const seatNumbers = tables[0].seats.map(s => s.seat_number);
          const modal = await this.modalCtrl.create({
            component: SeatChangeModalComponent,
            componentProps: {
              person,
              tables: tableNumbers,
              seats: seatNumbers,
              saveToFirebase: false
            }
          });
          await modal.present();
          const res = await modal.onDidDismiss();
          if (res.role === 'submit') {
            person.table = res.data.table;
            person.seat_number = res.data.seat;

            // Resort the table
            const data = await this.tableAssignmentsService.groupByTable(this.table_assignments.flatMap(t => t.seats));
            if (data) {
              this.table_assignments = data.tables;
            }
          }
    }

    async flagSeatAssigned(person: TableAssignment) {
      if (person.foreign_key.includes('slconferenceasia')) {
        const flagging: FlagAction = {
          flag_name: 'table_and_seat_assigned',
          entity_id: person.id,
          action: 'flag'
        };
        const flagged = await this.flagService.flag(flagging);
        if (flagged[0]) {
          person.table_assigned = 'Yes';
        }
      }
      if (person.foreign_key.includes('slconference_speakers')) {
        const body = {
          id: person.uuid,
          attributes: {
            field_table_and_seat_assigned: '1'
          }
        };
        const entity = await this.groupMemberService.updateGroupMember(body, 'slc_asia_delegate');
        if (entity && entity.data.attributes) {
          person.table_assigned = entity.data.attributes?.field_table_and_seat_assigned ? 'Yes' : 'No';
        }
        await new Promise<void>(resolve => setTimeout(()=>resolve(), 500));
      }
    }

    saveTableDetails(table: Table) {
      const tableId = `table_${table.tableNumber}`;
      this.firebaseService.setTableDocument({id: table.tableNumber.toString(), tableNumber: table.tableNumber, country: table.country, region: table.region}, tableId);
    }

    saveTableAssignments() {
      this.table_assignments.forEach(table => {

        table.delegates.forEach(async (person) => {
          const personObject = Object.assign({}, person);
          const id = `${person.foreign_key}_${person.id.toString()}`;
          const newRecord = await this.firebaseService.setDocument(personObject, id);
          if (newRecord) {
            await this.flagSeatAssigned(person);
          }
        });

      });
    }

}