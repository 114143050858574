// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `small {
  margin: 2rem auto;
  display: block;
}

ion-avatar {
  margin-inline: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  flex-flow: column;
  gap: 5px;
  position: relative;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm15LWFjY291bnQucGFnZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksaUJBQUE7RUFDQSxjQUFBO0FBQ0o7O0FBRUU7RUFDRSxtQkFBQTtFQUNBLGVBQUE7RUFDQSxrQkFBQTtFQUNBLGFBQUE7RUFDQSxpQkFBQTtFQUNBLFFBQUE7RUFDQSxrQkFBQTtBQUNKIiwiZmlsZSI6Im15LWFjY291bnQucGFnZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsic21hbGwge1xuICAgIG1hcmdpbjogMnJlbSBhdXRvO1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICB9XG5cbiAgaW9uLWF2YXRhciB7XG4gICAgbWFyZ2luLWlubGluZTogMjBweDtcbiAgICBtYXJnaW4tdG9wOiAycHg7XG4gICAgbWFyZ2luLWJvdHRvbTogMnB4O1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1mbG93OiBjb2x1bW47XG4gICAgZ2FwOiA1cHg7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB9Il19 */`, "",{"version":3,"sources":["webpack://./src/app/account/my-account/my-account.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;AACJ;;AAEE;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,QAAA;EACA,kBAAA;AACJ;AACA,grBAAgrB","sourcesContent":["small {\n    margin: 2rem auto;\n    display: block;\n  }\n\n  ion-avatar {\n    margin-inline: 20px;\n    margin-top: 2px;\n    margin-bottom: 2px;\n    display: flex;\n    flex-flow: column;\n    gap: 5px;\n    position: relative;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
