/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CardSwipeService {
    swipeHandler: any;
    // String buffer to store characters from the swipe
    swipe_buffer = '';
    // Global keypress timeout to differentiate between typing and swiping
    swipe_timeout = null;
    // This governs the maximum number of milliseconds allowed between keypresses for the input to be tested as part of a swipe
    SWIPE_TIMEOUT_MS = 100;  

    constructor() {
    }


addListeners() {
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
        this.cardSwipe(evt);
    });
}

deleteListeners() {
    document.removeEventListener('keypress', this.swipeHandler, true);
    console.log('Done!');
}

async cardSwipe(event) {
    this.swipe_buffer += String.fromCharCode(event.keyCode);

    await new Promise<void>(resolve => setTimeout(()=>resolve(), 300));
    let card_holder, first_name, last_name, cc_number, exp_month, exp_year, cvv;
    let cardExp, cardNum, cardFirst, cardLast;

    const full_cc_regex = /(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})[=D][0-9]{4}/;
    const cc_regex = /(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})/;
    const exp_regex = /=(\d{4})/;
    const name_regex =/(?:\^(\w+)\/(\w+))/;
  
    if (this.swipe_buffer !== null) {
      if (this.swipe_buffer.match(full_cc_regex)) {
        const exp = exp_regex.exec( this.swipe_buffer);
        
        exp_month = exp[1].substr(2);
        exp_year = '20' + exp[1].substr(0,2);
        cc_number = cc_regex.exec(this.swipe_buffer);
        card_holder = name_regex.exec(this.swipe_buffer);

        // console.log('cctype ' + this.getCardTypeByNumber(cc_number));
        // console.log('cc_number_field ' +  cc_number);
        // console.log('cc_exp_month ' + exp_month);
        // console.log('cc_exp_year ' + exp_year);
        // console.log('cc_track1data ' + this.getTrack1FromSwipe(this.swipe_buffer));

        cardExp = exp_month + ' ' + exp_year;
        cardNum = cc_number[0];
        cardFirst = this.titleCase(card_holder[2]);
        cardLast = this.titleCase(card_holder[1]);

        const result = {
            cardExp,
            cardExpMonth: exp_month,
            cardExpYear: exp_year,
            cardNum,
            cardFirst,
            cardLast
        };
        this.swipe_buffer = '';
        return result;
      }
    }
  }

  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }


getTrack1FromSwipe(swipe_data) {
  if (swipe_data.substr(0, 1) === '%') {
    swipe_data = swipe_data.substr(1); // remove the leading character
  }

  const tracks = swipe_data.split('?'); // split off tracks one and two
  const track1 = tracks[0];
  const track2 = tracks[1].substr(1); // this will strip off the ';', which is the second track's leading sentinel.

   /* This method parses out both track1 and track2 from the swipe data, but only returns track1
    * To return both, you could do something like the following, instead:
    *
    * return {
    *   track1_data: track1,
    *   track2_data: track2
    * };
    */

  // For this implementation, just return track 1
  return track1;
}

getCardTypeByNumber(cc_number) {
    console.log(cc_number);
  cc_number = cc_number.toString();

  // Visa
  if (cc_number.match(/^4[0-9]{12}(?:[0-9]{3})?$/) !== null) {
    return 'Visa';
  }
  // AmericanExpress
  else if (cc_number.match(/^3[47][0-9]{13}$/) !== null) {
    return 'AmericanExpress';
  }
  // Discover
  else if (cc_number.match(/^6(?:011|5[0-9]{2})[0-9]{12}$/) !== null) {
    return 'Discover';
  }
  // MasterCard
  else if (cc_number.match(/^5[1-5][0-9]{14}$/) !== null) {
    return 'MasterCard';
  }
  // Other
  else {
    // this is likely invalid, but let's return 'Visa' anyway for now. The Luhn Algorithm should check validity later anyway.
    return 'Visa';
  }
}


}