import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import CommerceProducts from '../../assets/commerce/commerce_products.json';
import { CommerceLineItemService } from './drupal7/drupal7-services.module';
import { CommerceLineItemEntity, CommerceProduct } from './drupal7/public_api';

@Injectable({
  providedIn: 'root'
})
export class CommerceServiceCustom {

  constructor(
    private commerceLineItemService: CommerceLineItemService,
    public message: MessagesService,
    public storage: Storage) { }

    getActiveProducts() {
      const activeProducts = [];
      for (const product of CommerceProducts) {
        if (product.status) {
          activeProducts.push(product);
        }
      }
      return activeProducts;
    }

    async addProductToCart(product: CommerceProduct, order): Promise<any> {
      this.message.showLoading('Adding the ' + product.title + ' to order ' + order.order_id, true, 3000);
      const body: CommerceLineItemEntity = {
        order_id: order.order_id,
        commerce_product: product.id,
        type: 'product',
        quantity: product.quantity,
        commerce_unit_price: {amount: product.commerce_price, currency_code: 'USD'}
      };
      console.log(body);
      return await this.commerceLineItemService.createLineItem(body);
    }

    deleteLineItem(id) {
      this.message.showLoading('Removing from cart...', true, 2000);
      return this.commerceLineItemService.deleteLineItem(id);
    }

}