import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { CommercePaymentTransaction } from "src/app/services/drupal7/public_api";

@Component({
    selector: 'app-commerce-payment-transactions',
    templateUrl: './commerce-payment-transactions.component.html',
    styleUrls: ['./commerce-payment-transactions.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule]
})
export class CommercePaymentTransactionsComponent {

    @Input() transactions: CommercePaymentTransaction[];
    @Input() paid: string;
    @Input() balance: string;
    @Input() order_id: number;
    constructor() {}
}