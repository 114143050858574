import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule, Platform } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { SystemConnection } from 'src/app/services/drupal7/public_api';
import { UserServiceCustom } from 'src/app/services/user.service';
import { HeaderComponent } from 'src/app/system/header/header.component';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.page.html',
  styleUrls: ['./my-account.page.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, HeaderComponent]
})
export class MyAccountPage implements OnInit {

    version = environment.version;
    userSession: SystemConnection;
       
  constructor(
    public platform: Platform,
    private user: UserServiceCustom,
    public router: Router
  ) {
  }

  ngOnInit(): void {
    this.user.currentSession.subscribe(user => {
      this.userSession = user;
    });
  }

  logout() {
    this.user.logout();
  }

  async login() {
    this.router.navigate(['login']);
  }

  async navigate(page: string) {
    this.router.navigate([page]);
  }


}