import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../../../services/user.service';
import { MessagesService } from '../../../services/messages.service';
import { IonSearchbar, IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemConnection } from '../../../services/drupal7/models';
import { HeaderComponent } from '../../../system/header/header.component';
import { QRCodeComponent } from 'angularx-qrcode';
import { EntityServiceCustom } from 'src/app/services/entity.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, QRCodeComponent, HeaderComponent]
})
export class FourBySixBlankBadgeComponent implements OnInit {

  public router = inject(Router);
  public storage = inject(Storage);
  private user = inject(UserServiceCustom);
  public messagesService = inject(MessagesService);
  private entity = inject(EntityServiceCustom);

  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  userSession: SystemConnection;
  data: ChurchMember[] = [];
 
  contact_first_name: string = '';
  contact_last_name: string = '';
  church_title: string = '';
  church_title_size: string = '10';
  contact_first_name_size: string = '26';
  contact_last_name_size: string = '26';
  approved_volunteer: string = 'Approved Volunteer';
  area: string = 'Guest Experience';

  newNames = '';

  constructor() {
 
      this.user.currentSession.subscribe(user => {
        this.userSession = user;
      });
   }


   async ngOnInit() {}

   addNames() {
    const splitNames = this.newNames.split('\n').map(nameLine => {
      const [contact_first_name, contact_last_name] = nameLine.trim().split(' ');
      return { contact_first_name, contact_last_name };
    }).filter(name => name.contact_first_name || name.contact_last_name);

    // Filter out entries with missing first or last names
    const validNames = splitNames.map(name => {
      const newMember = new ChurchMember();
      Object.keys(newMember).map(k => {
        if (this[k]) {
          newMember[k] = this[k];
        } else {
          newMember[k] = name[k];
        }
      });
      return newMember;
    });

    // Add the valid names to the list
    this.data.push(...validNames);

    // Clear the textarea
    this.newNames = '';
    console.log(this.data);
  }

  remove(i: number) {
    this.data.splice(i, 1);
  }

  setChurch() {
    this.data.map(o => o.church_title = this.church_title);
  }

  getDelegateType(entity: any): string {
    return this.entity.getDelegateType(entity);
  }

  getGroupLabel(entity: any): string {
    return this.entity.getGroupLabel(entity);
  }  

  reset() {
    this.data = [];
  }

  async printPage() {

    if (this.data) {
      const popupWinWidth = 1000;
      const popupWinHeight = 1000;
      const pageTitle = 'PRINT';
      const left = (screen.width - popupWinWidth) / 2;
      const top = (screen.height - popupWinHeight) / 4;
      const printBadges = window.open('', pageTitle,
          'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left);

      printBadges.document.write('<html><head><title>' + document.title  + '</title>');
      printBadges.document.write(`<style>
      .slc-badge {text-align: center;}
    .slc-badge ion-col {
        flex: none;
        font-family: "avenir next", sans-serif;
        margin: 0rem 0.5rem 2rem;
        position: relative;
        width: 4in;
        height: 6in;
        background-repeat: no-repeat;
        background-size: 100% !important;
        padding: 0;
        border: 0.1rem solid #cacaca;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        color: black;
    }
    .slc-badge ion-col .badge-name {
        position: relative;
        top: 2.5in;
        font-size: 22pt;
        line-height: 22pt;
        font-weight: 700;
        margin: 0 auto;
        min-height: 0.85in;
        text-transform: uppercase;
        color: black;
    }
    .slc-badge ion-col .badge-name .first-name {
        margin-bottom: 5px;
    }        
    .slc-badge ion-col .badge-name .first-name, .slc-badge ion-col .badge-name .last-name {
        display: block;
    }
    .slc-badge ion-col .seat-number {
        font-size: 1rem;
        font-weight: 700;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        top: 2.1in;
        margin-bottom: 10px;
    }
    .slc-badge ion-col .seat-number .seat-icon {
        max-width: 18pt !important;
        margin-right: 3pt;
    }
    .slc-badge ion-col .church-name {
        font-size: 10pt;
        text-transform: uppercase;
        position: relative;
        top: 2.4in;
    }
    .slc-badge ion-col .approved-volunteer {
      font-size: 10pt;
      text-transform: uppercase;
      position: relative;
      top: 3.3in;
      transform: rotateZ(180deg);
    }
    .slc-badge ion-col .entity-id {
        display: flex;
        transform: rotateZ(180deg);
        font-size: 10pt;
        font-weight: 700;
        position: relative;
        top: 3.75in;
        justify-content: center;
    }
    .slc-badge ion-col .qrcodeImage,
    .slc-badge ion-col ngx-qrcode {
        max-width: 120px !important;
        position: relative;
        top: 3.77in;
        transform: rotateZ(180deg);
        display: block;
        margin: 0 auto;
    }
    .slc-badge ion-col .order-id {
      display: flex;
      font-size: 9pt;
      font-weight: 700;
      position: relative;
      top: 4.25in;
      margin: 0 .2in;
      justify-content: space-between;
    }
    .slc-badge ion-col .order-id ion-text:first-child {
      transform: rotate(180deg);
    }
    .hidden-print, 
    .ion-hide-print {display:none !important;}

    @media print {
        .slc-badge {
            display: block;
            flex-flow: column;
            margin: 0;
      }
        .slc-badge ion-col {
            float: none;
            page-break-after: always;
            border: none;
            padding: 0rem;
            margin: 0 auto;
            display: block;
      }
        .slc-badge ion-col:last-child {
            page-break-after: auto;
      }
      .hidden-print, 
      .ion-hide-print {display:none !important;}
    }</style>`);
      printBadges.document.write('</head><body class="slc-badge">');
      printBadges.document.write(document.getElementById('printArea').innerHTML);
      printBadges.document.write('</body></html>');

      printBadges.document.close(); // necessary for IE >= 10
      printBadges.focus(); // necessary for IE >= 10*/

      await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
      printBadges.print();
      printBadges.close();
  }
}

}

export class ChurchMember {
  contact_first_name: string = '';
  contact_last_name: string = '';
  church_title: string = '';
  church_title_size: string = '10';
  contact_first_name_size: string = '26';
  contact_last_name_size: string = '26';
  approved_volunteer: string = 'Approved Volunteer';
  area: string = 'Guest Experience';
  constructor() {}
}