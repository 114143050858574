import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  filters = new BehaviorSubject<any>(null);
  currentFilters = this.filters.asObservable();

  constructor(private storage: StorageService) {
    this.getFilters();
  }

  async getFilters() {
    const currentFilters = await this.storage.get('filters');
    this.changeFilters(currentFilters);
  }

  changeFilters(filters: any) {
    this.filters.next(filters);
  }

  async addFilter(key: string, value: any) {
    const currentFilters = await this.storage.get('filters');
    if (currentFilters) {
      currentFilters[key] = value;
      this.storage.set('filters', currentFilters);
      this.filters.next(currentFilters);
    } else {
      this.storage.set('filters', {[key]: value});
      this.filters.next({[key]: value});
    }
  }

  changeSemester(event: any) {
    if (event.detail.value) {
      this.addFilter('semester', event.detail.value);
    }
  }

  changeYear(event: any) {
    if (event.detail.value) {
      this.addFilter('year', event.detail.value);
    }
  }

  getYears(reverse: boolean = true) {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];

    for (let i = 1995; i <= currentYear + 4; i++) {
      years.push(i);
    }
    if (reverse) {
      return years.reverse();
    }
    return years;
  }


}