import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { StorageService } from '../services/storage/storage.service';
import { SystemConnection } from '../services/drupal7/models';
import { DrupalConstants } from '../services/drupal7/public_api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private alertController: AlertController,
    private platform: Platform) {}

  async isLoggedIn(): Promise<boolean> {
    const auth: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
    return new Promise<boolean>((resolve) => {
      if (auth && auth?.user?.uid) {
        resolve(true);
      } else {
        this.router.navigate(['login']);
        resolve(false);
      }
    });
  }

  async isAuthorized(showAlert: boolean = false): Promise<boolean> {
    const auth: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
    return new Promise<boolean>((resolve) => {
        if (auth && auth?.user?.uid === environment.appSettings.AppUserUID) {
          resolve(true);
        } else {
          if (showAlert) {
            this.accessDenied('You are not authorized to view this page');
          }
          resolve(false);
        }
    });
  }

  async isSeatViewer(showAlert: boolean = false): Promise<boolean> {
    const auth: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
    return new Promise<boolean>((resolve) => {
        if (auth?.user?.uid === environment.appSettings.SeatViewerUID) {
          resolve(true);
        } else {
          if (showAlert) {
            this.accessDenied('You are not authorized to view this page');
          }
          resolve(false);
        }
    });
  }

  async redirectSeatViewer(showAlert: boolean = false): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
        if (await this.isSeatViewer() && await this.isLoggedIn()) {
          this.router.navigate(['seating']);
          resolve(true);
        } else if ((await this.isSeatViewer() === false) && await this.isLoggedIn()) {
          resolve(true);
        } else {
          if (showAlert) {
            this.accessDenied('You are not authorized to view this page');
          }
          resolve(false);
        }
    });
  }

  async isNotLoggedIn(): Promise<boolean> {
    const auth: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
    return new Promise<boolean>((resolve) => {
        if (auth && auth?.user?.uid) {
          this.router.navigate(['login']);
          resolve(false);
        } else {
          resolve(true);
        }
    });
  }

  async init(): Promise<boolean> {
    const auth: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
    return new Promise<boolean>((resolve) => {
        if (auth && auth?.user?.uid) {
          this.router.navigate(['']);
          resolve(false);
        } else {
          resolve(true);
        }
    });
  }

  canRegister(): boolean {
    if (this.platform.is('capacitor')) {
      return true;
    } else {
      return true;
    }
  }

  canDeactivate(): boolean {
    // Implement your custom logic here to determine if the component can be deactivated.
    // For always destroying the component, you can simply return true.
    return true;
  }

  async presentAlert(message: string) {
      const alert = await this.alertController.create({
        header: 'Access Denied',
        message,
        backdropDismiss: false,
        buttons: [{
          text: 'Login',
          role: 'submit',
          handler: () => {
            this.router.navigate(['login']);
          },
        }],
      });
      await alert.present();
  }

  async accessDenied(message: string) {
    const alert = await this.alertController.create({
      header: 'Access Denied',
      message,
      backdropDismiss: false,
      buttons: [{
        text: 'OK',
        role: 'cancel',
      }],
    });
    await alert.present();
}



}
