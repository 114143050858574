import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSearchbar, IonicModule, ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { MessagesService } from '../services/messages.service';
import { ScanSettingsComponent } from '../scan-settings/scan-settings.component';
import { QrCodeScanService } from '../services/codescan/codescan.service';
import { FlagIonic, SystemConnection, ViewOptions } from '../services/drupal7/models';
import { FlagServiceCustom } from '../services/flag.service';
import { CommonModule } from '@angular/common';
import { UserServiceCustom } from '../services/user.service';
import { FiltersService } from '../services/filters.service';
import { FormsModule } from '@angular/forms';
import { EntityServiceCustom, EntitySetupOptions } from '../services/entity.service';
import { QRCodeModule } from 'angularx-qrcode';
import { KioskService } from '../services/kiosk.service';
import { StorageService } from '../services/storage/storage.service';
import { DelegateRegistration } from '../models/models';
import { CheckInCardComponent } from '../components/check-in-card/check-in-card.component';

@Component({
  selector: 'app-self-check-in',
  templateUrl: './self-check-in.component.html',
  styleUrls: ['./self-check-in.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, QRCodeModule, CheckInCardComponent]
})
export class SelfCheckInComponent implements OnInit {

  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  userSession: SystemConnection;
  loggedIn = false;
  data: DelegateRegistration[];
  flags: FlagIonic[];

  manualEntry = false;
  scannedInput: string | number;

  lastName: string = '';
  nid: number;

  constructor(
    private platform: Platform,
    private codeScan: QrCodeScanService,
    private entity: EntityServiceCustom,
    private alertCtrl: AlertController,
    private flagService: FlagServiceCustom,
    private user: UserServiceCustom,
    private storage: StorageService,
    private message: MessagesService,
    private kioskService: KioskService,
    private modalController: ModalController,
  ) {
    this.user.currentSession.subscribe(user => {
      if (user) {
        this.userSession = user;
      }
    });
    this.flagService.currentFlags.subscribe(flags => {
      console.log(flags);
      this.flags = flags;
    });

    console.log('listening');
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
        this.scan(evt);
      });
  }

  async ngOnInit() {
  }

  async scan(event) {
    if (!this.manualEntry) {
      const res = await this.codeScan.scan(event);
      if (res) {
        console.log(res);
        this.search(true, true);
      }
    }
  }

  async scanQRCode() {
    if (this.platform.is('capacitor')) {
      if (!await this.storage.get('defaultScanType')) {
        const result = await this.codeScan.scanQRCode();
        console.log(result);
        if (result) {
          this.message.showLoading('Searching for ' + result, false, 1500);
          this.get(true, true);
        }
      } else {
        this.scannedInput = '';
        this.searchInput.setFocus();
      }
    } else if (!await this.storage.get('defaultScanType')) {
      const result = await this.codeScan.startBarcodeScan();
      console.log(result);
      if (result) {
        const qrCode = await this.codeScan.parseScan(result);
        console.log(qrCode);
        if (qrCode) {
          this.nid = qrCode.id;
        }
        this.message.showLoading('Searching for ' + qrCode.id, false, 1500);
        this.get(true, true);
      }
    } else {
      this.scannedInput = '';
      this.searchInput.setFocus();
    }
  }

  search(autoflag: boolean = false, scanned: boolean = false) {
    this.message.showLoading('Searching for ' + this.nid, false, 1500);
    this.get(autoflag, scanned);
  }

  async get(autoFlag: boolean = false, scanned: boolean = false) {
    const options: ViewOptions = {
      display_id: 'api_registration_self_check_in',
      filters: {}
    };
    if (scanned) {
      options.filters.nid = this.nid;
    } else {
      options.filters.nid = this.nid;
      options.filters.contact_last_name = this.lastName;
    }
    const setupOptions: EntitySetupOptions = {};
    if (autoFlag) {
      setupOptions.autoFlag = true;
    }
    const foundRegistrations = await this.entity.getViewWithOptions(`registration/self-check-in`, options, setupOptions);
    console.log(foundRegistrations);
    if (foundRegistrations) {
      this.data = foundRegistrations.results;
      if (autoFlag) {
        await new Promise<void>(resolve => setTimeout(()=>resolve(), 3000));
        this.resetSearch();
      }
    }
  }

  resetSearch() {
    this.data = null;
    this.manualEntry = false;
  }

  async scanSettings() {
    const modal = await this.modalController.create({
      component: ScanSettingsComponent,
      cssClass: 'scan-settings',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log(data);
    if (data) {
      this.message.presentToast('Scan settings saved.', 2000);
    }
  }


  async exit() {
    const alert = await this.alertCtrl.create({
      header: 'Kiosk Mode Settings',
      message: 'Enter the passcode to access the settings for this area or exit kiosk mode.',
      cssClass: 'scan-settings',
      inputs: [
        {
          name: 'pass',
          type: 'password',
          id: 'pass',
        }
      ],
      buttons: [
        {
          text: 'Scan Settings',
          cssClass: 'scan-settings-button',
          handler: async (form) => {
            if (+form.pass === environment.appSettings.linkDevicePass) {
              this.scanSettings();
            } else {
              this.message.presentAlert('Passcode incorrect', 'The passcode you entered is incorrect');
            }
          }
        },
        {
          text: 'EXIT Kiosk Mode',
          cssClass: 'scan-settings-button',
          handler: async form => {
            this.kioskService.exitKioskMode(+form.pass);
          }
        }, {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'scan-settings-button',
        }
      ]

    });
    await alert.present();
  }


}

