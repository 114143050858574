import { Routes } from '@angular/router';
import { ThreeByEightBadgeComponent } from './badges/delegates/3x8/badges.component';
import { SmsInboxComponent } from './sms-inbox/sms-inbox.component';
import { SelfCheckInComponent } from './self-check-in/self-check-in.component';
import { CheckInComponent } from './check-in/check-in.page';
import { inject } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { MyAccountPage } from './account/drupal/my-account/my-account.page';
import { LoginPage } from './account/drupal/login/login.page';
import { SeatingListComponent } from './seating/seating-list/seating-list.component';
import { TableAssignmentsComponent } from './table-assignments/table-assignments.component';
import { FourBySixBlankBadgeComponent } from './badges/blanks/4x6/badges.component';
import { A6BadgeComponent } from './badges/delegates/a6/badges.component';
import { ThreeFiveByFourFiveBadgeComponent } from './badges/delegates/3.5x4.5/badges.component';
import { FirebaseRegistrationComponent } from './account/firebase/registration/registration.component';
import { FirebaseLoginComponent } from './account/firebase/login/login.component';
import { FirebasePasswordResetComponent } from './account/firebase/password-reset/password-reset.component';
import { FirebaseVerifyEmailComponent } from './account/firebase/verify-email/verify-email.component';
import { FirebaseMyAccountComponent } from './account/firebase/my-account/my-account.component';
import { A6BlankBadgeComponent } from './badges/blanks/a6/badges.component';


export const routes: Routes = [
  { path: '', canActivate: [() => inject(AuthGuard).isLoggedIn()],
    children: [
      { path: 'check-in', component: CheckInComponent, canActivate: [() => inject(AuthGuard).redirectSeatViewer()], canDeactivate: [() => inject(AuthGuard).canDeactivate()]},
      { path: '', redirectTo: 'check-in', pathMatch: 'full'},
    ]},
    { path: 'badges', component: A6BadgeComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: '3-by-8-badges', component: ThreeByEightBadgeComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'a6-badges', component: A6BadgeComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: '35-by-45-badges', component: ThreeFiveByFourFiveBadgeComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'blank-badges', component: A6BlankBadgeComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: '4-by-6-blank-badges', component: FourBySixBlankBadgeComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'sms-inbox', component: SmsInboxComponent},
    { path: 'self-check-in', component: SelfCheckInComponent},
    { path: 'table-assignments', component: FirebaseMyAccountComponent},
    { path: 'seating', component: SeatingListComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'login', canActivate: [() => inject(AuthGuard).init()], component: LoginPage},
    { path: 'my-account', component: MyAccountPage, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'firebase', children: [
      {path: '', redirectTo: 'settings', pathMatch: 'full'},
      {path: 'login', component: FirebaseLoginComponent},
      {path: 'register', component: FirebaseRegistrationComponent},
      {path: 'password-reset', component: FirebasePasswordResetComponent},
      {path: 'verify-email', component: FirebaseVerifyEmailComponent},
      {path: 'settings', component: TableAssignmentsComponent}
    ]}
  
];

