import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CodeScanService {
    swipeHandler: any;
    scanBuffer = '';

    constructor() {
    }

addListeners() {
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
        this.scan(evt);
    });
}

deleteListeners() {
    document.removeEventListener('keypress', this.swipeHandler, true);
    console.log('Done!');
}

async scan(event) {
    // Setup the patterns to look for in the scan
    const entity_full_regex = /(?:\%id=(\d+)\^lineitemid=(\d+)\^orderid=(\d+)\^uid=(\d+))\?/;
    const entity_regex = /(?:\%id=(\d+)\^)/;
    const line_item_regex = /(?:\^lineitemid=(\d+)\^)/;
    const order_id_regex = /(?:\^orderid=(\d+)\^)/;
    const uid_regex = /(?:\^uid=(\d+)\?)/;

    // Add the key to the buffer
    this.scanBuffer += String.fromCharCode(event.keyCode);
    // Set timeout to make sure we have all the data from the buffer
    await new Promise<void>(resolve => setTimeout(()=>resolve(), 500));
    if (this.scanBuffer !== null && this.scanBuffer.match(entity_full_regex)) {
        const fullMatch = entity_full_regex.exec(this.scanBuffer);
        const entityMatch = entity_regex.exec(this.scanBuffer);
        const lineItemMatch = line_item_regex.exec(this.scanBuffer);
        const orderIDMatch = order_id_regex.exec(this.scanBuffer);
        const uidMatch = uid_regex.exec(this.scanBuffer);

        // Setup the return
        const res = {
            id: entityMatch[1],
            line_item_id: lineItemMatch[1],
            order_id: orderIDMatch[1],
            uid: uidMatch[1],
            qr_code_value: this.scanBuffer
        }
        this.scanBuffer = '';
        return res;
    } else {
        this.scanBuffer = '';
    }
  }

}

export interface QRCode {
    id: string;
    line_item_id: string;
    order_id: string;
    uid: string;
    qr_code_value: string;
}


