import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import { AlertController, AlertInput } from '@ionic/angular';
import { FlagServiceCustom } from './flag.service';
import { EntityService, FileService, ViewService } from './drupal7/drupal7-services.module';
import { CommerceOrderStatus, FileAttachToEntity, ViewOptions } from './drupal7/models';
import { DrupalFormControlObject } from './drupal7/models/form-controls';
import { QRCode } from './drupal7/models/qrcode';
import { PhotoService } from './photo/photo.service';

@Injectable({
  providedIn: 'root'
})
export class EntityServiceCustom {

  constructor(
    private viewService: ViewService,
    private alertCtrl: AlertController,
    private messagesService: MessagesService,
    private entityService: EntityService,
    private photoService: PhotoService,
    private fileService: FileService,
    public flag: FlagServiceCustom,
    public storage: Storage
    ) {}


    async getViewWithOptions(url: string, options: ViewOptions, setupOptions: EntitySetupOptions = {}) {
      options.obj_to_arr_props = environment.arrayFields;
      return this.viewService.getView(url, options).then((res) => {
        const results = res.results;
          if (!res) return false;
  
          if (setupOptions.autoFlag) {
            this.flag.autoflag(results);
          }
          if (setupOptions.qrCodes) {
            this.setupQRCode(results);
          }
          if (setupOptions.uniqueOrders) {
            this.setupUniqueOrders(results);
          }
          if (setupOptions.badgeControls) {
            this.setupBadgeControls(results);
          }
  
          const returnedResult = {
            headers: res.headers,
            pager: res.pager,
            results,
          };
          if (setupOptions.paidCount) {
            returnedResult['paidCount'] = this.setupPaidCount(results);
          }
          
          return returnedResult;
      }).catch(err => err);
    }
  

    setupQRCode(data: any) {
      for (const entity of data) {
        entity.qr_codes = [];
        if (entity.referenced_orders) {
          for (const order of entity.referenced_orders) {
            const qrCodeDataValue = `%id=${entity.id}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}^group=${entity.group_manager}?`;
            // const qrCodeDataValue = `%id=${entity.id}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}?`;
            const qrCodeData: QRCode = {
              id: entity.id,
              line_item_id: order.line_item_id,
              order_id: order.order_id,
              uid: order.uid,
              group_manager: entity.group_manager,
              qr_code_value: qrCodeDataValue
            };
            entity.qr_codes.push(qrCodeData);
          }
        }
      }
      return data;
    }
  
    setupBadgeType(data: any) {
      for (const entity of data) {
        entity.class = 'regular';
        entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-regular.svg) !important;';
        // if (environment.badgeType1.indexOf(entity.delegate_type) > -1) {
        //   entity.class = 'roundtable';
        //   entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-roundtable.svg) !important;';
        // }
      }
      return data;
    }
  
    getDelegateType(entity: any): string {
      let val = entity.delegate_type_value;
      Object.keys(environment.delegateTypes).map(key => {
        const value = environment.delegateTypes[key];
        if (value.types.indexOf(entity.delegate_type) !== -1) {
          val = value.value;
        }
        if (value.types.indexOf(entity.nid) !== -1) {
          val = value.value;
        }
      });
      return val;
    }
  
    getGroupLabel(entity: any): string {
      const val = entity.field_meeting_location;
      const result = environment.badgeGroups[val];
      return result ? result : 'Z';
    }  
  
    setupBadgeControls(data: any[]) {
      for (const entity of data) {
        const firstNameLength = entity.contact_first_name.length;
        const lastNameLength = entity.contact_last_name.length;
        entity.contact_first_name_size = '26';
        entity.contact_last_name_size = '26';
        if (firstNameLength > 20) {
          entity.contact_first_name_size = '13';
        } else if (firstNameLength > 18) {
          entity.contact_first_name_size = '14';
        } else if (firstNameLength > 15) {
          entity.contact_first_name_size = '15';
        } else if (firstNameLength > 13) {
          entity.contact_first_name_size = '17';
        }
        if (lastNameLength > 15) {
          entity.contact_last_name_size = '13';
        } else if (lastNameLength > 11) {
          entity.contact_last_name_size = '17';
        }
  
        entity.church_title_size = '10';
        entity.country_size = '10';
        const seatReservation = entity.seat_reservation?.find(s => s.delegate_nid === entity.nid);
        if (seatReservation) {
          if (!entity.selected_seat && seatReservation?.seat) {
            entity.selected_seat = seatReservation.seat;
          }
          entity.selected_seat_original = seatReservation.seat ? seatReservation.seat : 'General Seating';
        } else {
          entity.selected_seat = 'General Seating';
          entity.selected_seat_original = 'General Seating';
        }
        entity.church_title = entity.linked_church_value;
        entity.church_title_original = entity.linked_church_value;
      }
      return data;
    }
  
    setupUniqueOrders(data: any[]) {
      for (const entity of data) {
        const hasOpenOrder = entity.referenced_orders?.find((o) => o.state !== CommerceOrderStatus.invoiced);
        entity.has_open_order = hasOpenOrder ? true : false;
        entity.unique_orders = [];
        if (entity.referenced_orders) {
          for (const order of entity.referenced_orders) {
            const index = entity.unique_orders.map((o) => o.order_id).indexOf(order.order_id);
            if (index === -1) {
              entity.unique_orders.push(order);
            }
          }
        }
      }
      return data;
    }
  
    setupPaidCount(data: any) {
      let paidCount = 0;
        data.map(e => {
          paidCount += e.flag_delegate_paid ? 1 : 0;
        });
        return paidCount;
    }
  
  async presentUpdateFieldAlert(entityType: string, entity: any, formField: DrupalFormControlObject, field: any, inputs: any[], entityIdFieldName: string) {
    const alert = await this.alertCtrl.create({
      header: formField.label,
      backdropDismiss: true,
      message: 'Enter the ' + field + ' for ' +  entity.first_name + ' ' + entity.last_name,
      inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Update',
          cssClass: 'primary',
          role: 'submit'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      if (form.role === 'submit') {
        const body = {};
        body[field] = form.data.values[field];
        if (form.data.values[field] === undefined) {
          body[field] = form.data.values;
        }
        this.messagesService.showLoading('Updating ' + formField.label + ' from ' + entity[field] + ' to ' + body[field], false, 3000);
        return await this.entityService.updateEntity(entityType, body, entity[entityIdFieldName]).then(res => res[field]);
      }
    }, err => new Error(err));
  }

  async updatePhoneNumber(entityType: string, entity: any, fieldName: string) {
    const formField: DrupalFormControlObject = {
        field_name: fieldName,
        label: 'Phone Number',
        type: 'tel',
        ionic_type: 'input',
        status: true,
        access: true
    };
    const inputs: AlertInput[] = [{
        type: 'tel',
        value: entity[formField.field_name],
        name: fieldName
    }];
    this.presentUpdateFieldAlert(entityType, entity, formField, fieldName, inputs, 'id').then(val => {
      if (val) {
       return entity[formField.field_name] = val;
      }
    });
  }

  async addPhoto(entity: any, entityType: string, fieldName: string, entityIdFieldName: string, fileNamePattern: object) {
    let fileName = '';
    Object.keys(fileNamePattern).forEach((key, index) => {
      fileName += this.optionToString(key, fileNamePattern[key]);
    });
    if (fileName.endsWith('-')) {
      fileName = fileName.slice(0, -1);
    }
    const newPhoto = await this.photoService.takePhoto(fileName);
    this.messagesService.showLoading('Submitting file...', false, 2000);
    const fileBody: FileAttachToEntity = {
      file: newPhoto,
      entity_id: entity[entityIdFieldName],
      entity_type: entityType,
      field_name: fieldName,
    };
    const newFile = await this.fileService.attachFilesToEntity(fileBody);
    if (entity[fieldName].src) {
        entity[fieldName].src = newFile.uri_full;
    } else {
        entity[fieldName] = {src: newFile.uri_full, alt: newFile.alt, title: newFile.title};
    }
    if (newFile?.fid) {
      this.messagesService.presentToast('Photo added', 2000);
    } else {
      this.messagesService.presentAlert('Error creating file', 'Error Code: ' + newFile.status);
    }
  }

  protected optionToString(key: string, value: any): string {
    if (!value) {
      return '';
    }
    let str = '';
    if (value instanceof Array) {
      value.forEach((element, index) => {
        str += `${element}-`;
      });
    } else if (value instanceof Object) {
      Object.keys(value).forEach((element: string, index) => {
        if (value[element] instanceof Object) {
          str += this.serializeObject(value[element], `${key}[${element}]`);
        } else {
          str += `${value[element]}-`;
        }
      });
    } else {
      str += `${value}-`;
    }
    return str.trim().toLowerCase();
  }

  private serializeObject(obj: any, parentSerialized: string): string {
    let str = '';
    Object.keys(obj).forEach((key, index) => {
      const value = obj[key];
      if (value instanceof Object) {
        str += `${this.serializeObject(value, `${parentSerialized}[${key}]`)}`;
      } else {
        str += `${value}`;
      }
    });
    return str;
  }

}

export interface EntitySetupOptions {
  qrCodes?: boolean;
  childcare?: boolean;
  uniqueOrders?: boolean;
  badgeType?: boolean;
  badgeControls?: boolean;
  paidCount?: boolean;
  autoFlag?: boolean;
}


