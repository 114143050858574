// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.primary {
  background: var(--ion-color-primary-shade) !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVudGl0eV9yZWZlcmVuY2VfbGlua2VkX2NodXJjaC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLHFEQUFBO0FBQ0oiLCJmaWxlIjoiZW50aXR5X3JlZmVyZW5jZV9saW5rZWRfY2h1cmNoLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsidHIucHJpbWFyeSB7XG4gICAgYmFja2dyb3VuZDogdmFyKC0taW9uLWNvbG9yLXByaW1hcnktc2hhZGUpICFpbXBvcnRhbnQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/entity_reference/linked_church/entity_reference_linked_church.component.scss"],"names":[],"mappings":"AAAA;EACI,qDAAA;AACJ;AACA,4ZAA4Z","sourcesContent":["tr.primary {\n    background: var(--ion-color-primary-shade) !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
