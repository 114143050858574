import { Component, inject, OnInit } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../system/header/header.component';
import { ViewService } from '../services/drupal7/drupal7-services.module';
import { ViewOptions } from '../services/drupal7/public_api';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-table-assignments',
  templateUrl: './table-assignments.component.html',
  styleUrls: ['./table-assignments.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, HeaderComponent]
})
export class TableAssignmentsComponent implements OnInit {

    private alertCtrl = inject(AlertController);
    private viewService = inject(ViewService);
    private commonService = inject(CommonService);

    data: TableAssignment[] = [];
    newNames = '';

    tableCount = 34;
    reservedTables = 2;
    availableTables = this.tableCount - this.reservedTables;
    seatsPerTable = 10;
    currentTable = 1;
    assignMode = 'leaders_initialize';
    assignFailures = 0;

    extra_business_leaders = [];
    ban_list = [];
    table_assignments = Array.from({ length: this.availableTables }, () => ({delegates: [], country: null}));
    counted_delegates = 0;
    counted_business_leaders = 0;

    primaryOptions: ViewOptions = {
      display_id: 'missions_connect_registrations_delegates',
      filters: {
        checked_in: '',
        field_linked_conference_target_id: 18212,
        delegate_type_value: ['professional_businessman', 'missionary']
      }
    };
    spouseOptions: ViewOptions = {
      display_id: 'missions_connect_registrations_delegates',
      filters: {
        checked_in: '',
        field_linked_conference_target_id: 18212,
        delegate_type_value_1: ['professional_businessman', 'missionary'],
        delegate_type_value: [
          'pastor', 'filipino_pastor', 'pastor-wife',
          'ministry_employee', 'church_member',
          'bible_college_student', 'homemaker',
          'conference_speaker', 'support_staff', 'wcbc_student']
      }
    };

    constructor() {}

    ngOnInit() {
        
    }



    async checkCheckedIn() {
      const primaryOptions = this.commonService.deepCopy(this.primaryOptions);
      primaryOptions.filters.checked_in = '1';
      const spouseOptions = this.commonService.deepCopy(this.spouseOptions);
      spouseOptions.filters.checked_in = '1';
      const primaryDelegates = await this.viewService.getView('missions-connect', primaryOptions);
      const spouseDelegates = await this.viewService.getView('missions-connect', spouseOptions);

      if (primaryDelegates) {
        this.data.map(v => {
          const delegate = primaryDelegates.results.find(d => d.nid === v.nid);
          if (delegate) {
            v.checked_in = delegate.checked_in;
          }
        });
      }

      if (spouseDelegates) {
        this.data.map(v => {
          const delegate = spouseDelegates.results.find(d => d.nid === v.nid);
          if (delegate) {
            v.checked_in = delegate.checked_in;
          }
        });
      }

    }

    async getDelegates() {

      this.resetSeating();

      const primaryDelegates = await this.viewService.getView('missions-connect', this.primaryOptions);
      const spouseDelegates = await this.viewService.getView('missions-connect', this.spouseOptions);

      if (primaryDelegates && spouseDelegates) {

        const allDelegates = [...primaryDelegates.results, ...spouseDelegates.results];
        console.log(allDelegates);
        
        const tableAssignments: TableAssignment[] = allDelegates.map(delegate => {
          return new TableAssignment(delegate.nid, delegate.first_name, delegate.last_name, delegate.country, delegate.country_code, delegate.delegate_type, delegate.checked_in, delegate.spouse_nid);
        });

        this.assignTables(tableAssignments);
        
      }
    }

    setAvailableTables() {
        this.availableTables = this.tableCount - this.reservedTables;
    }

    splitCsv() {
      const rows = this.newNames.split('\n').map(nameLine => {
        const [nid, first_name, last_name, country, country_code, delegate_type, checked_in, spouse_nid, region] = nameLine.trim().split('\t');
        return new TableAssignment(+nid, first_name, last_name, country, country_code, delegate_type, checked_in, +spouse_nid);
      }).filter(name => name.first_name || name.last_name);

      return rows;
    }

    async assignTables(rows: TableAssignment[]) {

        // Filter out duplicates based on first and last name
        const seen = new Set();
        const seenObj = new Set();
        const duplicatesObj = {};
        const uniqueRows = rows.filter(obj => {
          const fullName = `${obj.first_name.trim().toLowerCase()} ${obj.last_name.trim().toLowerCase()}`;
          if (seen.has(fullName)) {
            const seenArray = [... seenObj];
            const primary: any = seenArray.find((item: any) => {
              const primaryName = `${item.first_name.trim().toLowerCase()} ${item.last_name.trim().toLowerCase()}`;
              return primaryName === fullName;
            });
            if (!duplicatesObj[primary.nid]) {
              duplicatesObj[primary.nid] = {primary: primary, duplicates: [obj]};
            } else {
              duplicatesObj[primary.nid].duplicates.push(obj);
            }
            return false;
          }
          seen.add(fullName);
          seenObj.add(obj);
          return true;
        });

        // Check if there are enough seats
        if ((this.availableTables * this.seatsPerTable) < uniqueRows.length) {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: 'Not enough seats',
                message: "You don't have enough seats. Increase your table and seat count or check your delegate data.",
                buttons: [
                    {
                        text: 'OK',
                        id: 'ok',
                        role: 'cancel'
                    }
                ]
            });
            await alert.present();
        }

        // Organize delegates by country
        const countries = uniqueRows.reduce((acc, person) => {
          if (!acc.countriesObj[person.country_code]) {
            acc.countriesObj[person.country_code] = new Country(person.country_code, person.country);
          }
          if (person.delegate_type === 'Professional/Business Leader') {
            acc.countriesObj[person.country_code].business_leaders.push(person);
          } else {
            acc.countriesObj[person.country_code].delegates.push(person);
          }
          return acc;
        }, new Region('all', 'All'));


        const sortedCountries: Country[] = Object.values(countries.countriesObj).sort((a: any, b: any) => b.business_leaders.length - a.business_leaders.length);

        this.data = uniqueRows;

        // Assign tables to business leaders
        sortedCountries.forEach((country) => {
          country.business_leaders.forEach(businessLeader => {
            this.assignTable(businessLeader, this.currentTable, this.assignMode);
          });
        });

        // Assign tables to delegates
        sortedCountries.forEach(country => {
          country.delegates.forEach(delegate => {
            this.assignTable(delegate, this.currentTable, 'delegates');
          });
        });

        this.data.sort((a, b) => {
          // Convert to numbers
          const tableA = +a.table;
          const tableB = +b.table;
          const seatA = +a.seat_number;
          const seatB = +b.seat_number;
        
          // Sort by table first
          if (tableA !== tableB) {
            return tableA - tableB;
          }
        
          // If tables are the same, sort by seat number
          return seatA - seatB;
        });
    }

    assignTable(assignee: TableAssignment, currentTable: number, assignMode) {
      if (this.ban_list.includes(assignee.nid)) {
        console.log(`SKIPPED, already added NID ${assignee.nid} to a table.`);
        return;
      }
      const currentTableIndex = currentTable - 1;
      
      if (!this.table_assignments[currentTableIndex].country) {
        this.table_assignments[currentTableIndex].country = assignee.country;
      }

      if (this.table_assignments[currentTableIndex].delegates.length >= this.seatsPerTable) {
        console.log(`Table ${currentTable} is full. Moving to the next table.`);
        this.incrementTable();
        this.assignTable(assignee, this.currentTable, assignMode);
        return;
      }

      this.table_assignments[currentTableIndex].delegates.push(assignee);
      assignee.table = currentTable.toString();
      assignee.seat_number = this.table_assignments[currentTableIndex].delegates.length.toString();
      console.log(`MODE: ${assignMode} Assigned Table ${this.currentTable} to ${assignee.first_name}`);
      this.ban_list.push(assignee.nid);
    
      if (assignee.spouse_nid !== null) {
        const spouse = this.data.find(person => person.nid === assignee.spouse_nid);
        if (spouse) {
          console.log(`Also assigning spouse ${spouse.first_name} to table ${this.currentTable}`);
          this.assignTable(spouse, currentTable, assignMode);
          return;
        }
      }
    
      this.incrementTable();
      
    }

    incrementTable() {
      this.currentTable++;
      if (this.currentTable > this.availableTables) {
        this.currentTable = 1;
        if (this.assignMode === "leaders_initialize") {
          this.assignMode = "leaders_desperate";
        }
      }
    }

    checkSpouseSeats() {
      this.data.map(person => {
        if (person.spouse_nid !== null) {
          const spouse = this.data.find(p => p.nid === person.spouse_nid);
          if (spouse && person.table !== spouse.table) {
            console.log('Person Table: ', person.table, ' Spouse Table: ', spouse.table);
          }
        }
      });
    }

    getCounts() {
      for (let n = 0; n < this.table_assignments.length; n++) {
        for (let i = 0; i < this.table_assignments[n].delegates.length; i++) {
          if (this.table_assignments[n].delegates[i].delegate_type == "Professional/Business Leader") {
            this.counted_business_leaders++
          } else {
            this.counted_delegates++
          }
        }
      }

      this.checkSpouseSeats();
      
      console.log(this.table_assignments);
      console.log('Business leaders: ', this.counted_business_leaders);
      console.log('Delegates: ', this.counted_delegates);
      console.log('Total: ', this.counted_delegates + this.counted_business_leaders);
      console.log('Banned: ', this.ban_list.length);
      console.log('Rows: ', this.data.length);
    }

    reset() {
        this.newNames = '';
        this.data = [];
    }

    resetSeating() {
      this.currentTable = 1;
      this.assignMode = 'leaders_initialize';
      this.ban_list = [];
      this.table_assignments = Array.from({ length: this.availableTables }, () => ({delegates: [], country: null}));
      this.counted_delegates = 0;
      this.counted_business_leaders = 0;
    }
}

export class TableAssignment {
    nid: number;
    first_name: string;
    last_name: string;
    country: string;
    country_code: string;
    delegate_type: string;
    region: string;
    spouse_nid?: number = null;
    checked_in?: string;

    table?: string;
    seat_number?: string;

    regions?: Region[] = regionsList;

    constructor(id: number, firstName: string, lastName: string, country: string, countryCode: string, delegateType: string, checkedIn?: string, spouseNid?: number) {
        this.nid = id;
        this.first_name = firstName;
        this.last_name = lastName;
        this.country = country;
        this.country_code = countryCode;
        this.delegate_type = delegateType;
        this.checked_in = checkedIn;
        if (!isNaN(spouseNid)) {
          this.spouse_nid = spouseNid;
        }
        this.setRegion(this.getRegion(countryCode));
    }

    getRegion(country: string) {
        this.regions.map(region => region.countries_array = region.countries.split(', '));
        const region = this.regions.find(region => region.countries_array.indexOf(country) > -1);
        if (region) {
            return region.region;
        } else {
            return 'International';
        }
    }

    setRegion(region: string) {
        this.region = region;
    }
}

export class Region {
    id: string;
    region: string;
    countries: string;
    countries_array?: string[] = [];
    countriesObj?: {[key: string]: Country} = {};

    constructor(id: string, region: string) {
      this.id = id;
      this.region = region;
    }
}

export class Country {
    id: string;
    name: string;
    tableAssignments?: TableAssignment[] = [];
    business_leaders?: TableAssignment[] = [];
    delegates?: TableAssignment[] = [];

    constructor(id: string, name: string) {
      this.id = id;
      this.name = name;
    }
}

export const regionsList = [
    {
    id: "18686",
    region: "South Asia",
    countries: "AF, BD, BT, IN, MV, NP, PK, LK"
    },
    {
    id: "18687",
    region: "Southeast Asia",
    countries: "BN, KH, CN, ID, LA, MY, MM, SG, TH, TL, VN"
    },
    {
    id: "26746",
    region: "Far East",
    countries: "BN, KH, CN, HK, ID, JP, LA, MO, MY, MN, MM, KP, PH, RU, SG, KR, TW, TH, TL, VN"
    },
    {
    id: "26747",
    region: "Africa",
    countries: "DZ, AO, BJ, BW, BF, BI, CM, CV, CF, TD, KM, CG, CD, DJ, EG, GQ, ER, ET, GA, GM, GH, GN, GW, KE, LS, LR, LY, MG, MW, ML, MR, MU, MA, MZ, NA, NE, NG, RW, ST, SN, SC, SL, SO, ZA, SS, SD, SZ, TZ, TG, TN, UG, ZM, ZW"
    },
    {
    id: "26748",
    region: "Middle East",
    countries: "BH, CY, EG, IR, IQ, IL, JO, KW, LB, OM, PS, QA, SA, SY, TR, AE, YE"
    },
    {
    id: "26749",
    region: "Central Europe",
    countries: "AT, HR, CZ, DE, HU, LI, LT, PL, SK, SI, CH"
    }
]