// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item-group.item-separate ion-item::part(native), ion-list.item-separate ion-item::part(native) {
  padding: 13px;
}

.table-striped tbody tr:nth-child(odd) {
  background-color: var(--ion-color-tint);
}

td p {
  margin: 0;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/table-assignments/table-assignments.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,uCAAA;AACJ;;AAEA;EACI,SAAA;EACA,gBAAA;AACJ","sourcesContent":["ion-item-group.item-separate ion-item::part(native), ion-list.item-separate ion-item::part(native) {\n    padding: 13px;\n}\n\n.table-striped tbody tr:nth-child(odd) {\n    background-color: var(--ion-color-tint);\n}\n\ntd p {\n    margin: 0;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
