import { NodeEntity } from '../public_api';
import { PostalAddress } from './address';
import { ElavonResponse } from './elavon';
import { WebformSubmission } from './webform-submission';

export const CommerceOrderStatus = {
    canceled: 'Canceled',
    cart: 'Cart',
    checkout_checkout: 'Checkout: Checkout',
    checkout_review: 'Checkout: Review',
    checkout_payment: 'Checkout: Payment',
    checkout_complete: 'Checkout: Complete',
    pending: 'Pending',
    processing: 'Processing',
    invoiced: 'Invoiced'
  } as const;

  export const CommercePaymentTransactionStatus = {
    canceled: 'canceled',
    pending: 'pending',
    success: 'success',
    failure: 'failure'
  } as const;

  export const CommerceComponentsState = {
    base_price: 'base_price',
    discount: 'discount',
    fee: 'fee'
  } as const;

  export interface CommerceProduct {
    sku: string;
    id: number;
    title: string;
    commerce_price: number;
    commerce_total_formatted: string;
    status: boolean | 0 | 1;
    quantity: number;
    set_quantity: boolean;
  }

  export interface PaymentMethod {
    base: string;
    title: string;
    description: string;
    active: number;
    checkout: number;
    terminal: number;
    fieldable: number;
    method_id: string;
    module: string;
    offsite: boolean;
    offsite_autoredirect: boolean;
    callbacks?: {
      settings_form: string;
      submit_form: string;
      submit_form_validate: string;
      submit_form_submit: string;
      redirect_form: string;
      redirect_form_back: string;
      redirect_form_validate: string;
      redirect_form_submit: string;
    }
    file: string;
    display_title: string;
    short_title: string;
    type: string;
    value: string;
    label: string;
    icon: string;
    checked: boolean;
    fill: string;
    access: boolean;
    available_offline: boolean;
    available_online: boolean;
    requires_billing: boolean;
    requires_card: boolean;
  }

  export interface AltPayment {
    payload: Array<string>;
    check_number: string;
    receipt_email: string;
  }
  export interface CreditCard {
    card_number: string;
    exp_month: string;
    exp_year: string;
    exp_date: string;
    code: string;
  }
  export interface CommerceComponents {
    name: typeof CommerceComponentsState[keyof typeof CommerceComponentsState];
    price: {
      amount: string | number;
      currency_code: string;
      data: {
        components: Array<object>;
      };
    };
    included: boolean;
  }

  export interface CommercePrice {
    amount: number;
    currency_code?: string;
    data?: {
      components: CommerceComponents[];
    };
  }

  export interface CommerceLineItemDataContext {
    context: {
      button_text: string;
      product_ids: Array<number>;
      add_to_cart_combine: number;
      view: {
        view_name: string;
        display_name: string;
        human_name: string;
        page: string;
      };
    };
  }

  export interface CommerceLineItemEntity {
    data?: CommerceLineItemDataContext;
    commerce_unit_price?: CommercePrice;
    commerce_total?: CommercePrice;
    commerce_display_path?: string;
    rdf_mapping?: Array<string>;
    commerce_pricing_attributes?: string;
    line_item_title?: string;
    commerce_unit_price_formatted?: string;
    commerce_total_formatted?: string;
    line_item_id?: number;
    order_id: number;
    type: string;
    line_item_label?: string;
    quantity: number;
    created?: number;
    changed?: number;
    commerce_product: number;
    commerce_webform_nid?: number
    commerce_webform_sid?: number
    commerce_webform_submission?: WebformSubmission;
    commerce_webform_submission_node?: NodeEntity;
  }

  export interface CreatedCommerceLineItem extends CommerceLineItemEntity {
    line_item_id: number;
  }

  export interface CommerceCustomerProfile {
    status: number;
    type: string;
    uid: number;
    commerce_customer_address: PostalAddress;
    created?: number;
    changed?: number;
    profile_id?: number;
    revision_id?: number;
    revision_uid?: number;
    log?: string;
    revision_timestamp?: number;
    data?: boolean;
    rdf_mapping?: string[];
  }

  export class CommerceCustomerProfile {
    status: number;
    type: string;
    uid: number;
    commerce_customer_address: PostalAddress;
    created?: number;
    changed?: number;
    profile_id?: number;
    revision_id?: number;
    revision_uid?: number;
    log?: string;
    revision_timestamp?: number;
    data?: boolean;
    rdf_mapping?: string[];
  
    constructor(uid) {
      this.status = 1;
      this.type = 'billing';
      this.uid = uid;
      this.created = +new Date().valueOf().toString().substring(0, 10);
      this.changed = +new Date().valueOf().toString().substring(0, 10);
      this.commerce_customer_address = new PostalAddress();
    }
  }

  export interface Payment {
    payment_method: string;
    status?: typeof CommercePaymentTransactionStatus[keyof typeof CommercePaymentTransactionStatus];
    uid: number | string;
    order_id: number | string;
    amount: number;
    formattedAmount?: string;
    amount_formatted?: string;
    currency_code: string;
    message?: string;
    remote_id?: string;
    remote_status?: string;
    created?: string;
    changed?: string;
  }

  export class Payment {
    payment_method: string;
    status?: typeof CommercePaymentTransactionStatus[keyof typeof CommercePaymentTransactionStatus];
    uid: number | string = null;
    order_id: number | string = null;
    amount: number = 0;
    formattedAmount?: string;
    amount_formatted?: string;
    currency_code: string = 'USD';
    message?: string = '';
    remote_id?: string;
    remote_status?: string = '';
    created?: string;
    changed?: string;
  
    constructor(paymentMethod?: string) {
      this.payment_method = paymentMethod;
    }
  }

  export interface CommercePaymentTransaction extends Payment {
    transaction_id: string;
    revision_id: string;
    instance_id: string;
    message_variables: string;
    payload?: string;
    created: string;
    changed: string;
    data?: string;
  }

  export interface CommerceOrderData {
    last_cart_refresh: Date;
    payment_method: string; // Instance ID: ex. 'method|commerce_payment_method'
    commerce_payment_order_paid_in_full_invoked: string;
  }

  export interface CommerceOrder {
    order_number: number;
    revision_id: string;
    revision_uid: string;
    mail: string;
    status: typeof CommerceOrderStatus[keyof typeof CommerceOrderStatus];
    placed: string;
    log: string;
    revision_timestamp: number;
    revision_hostname: string;
    data: CommerceOrderData;
    order_id: number;
    type: string;
    uid: number;
    created: number;
    changed: number;
    hostname: string;
    commerce_line_items: number[];
    commerce_order_total: CommercePrice;
    commerce_customer_billing: number;
    field_commerce_billy_cancel_date: string;
    field_commerce_billy_i_date: string;
    commerce_discounts: object[];
    commerce_coupons: object[];
    rdf_mapping: string[];
    commerce_order_total_formatted: string;
    commerce_line_items_entities: {[key: string]: CommerceLineItemEntity};
    commerce_customer_billing_entities: {[key: string]: CommerceCustomerProfile};
    commerce_payment_transactions: CommercePaymentTransaction[];
    commerce_order_balance: number;
    commerce_order_payments_amount: number;
    commerce_order_payments_amount_formatted: string;
    commerce_order_balance_formatted: string;
    commerce_order_better_balance?: number;
    commerce_order_better_balance_formatted?: string;
    
  }

  export interface APICommerceOrder {
    order_id: number;
    order_number: number;
    type: string;
    uid?: number;
    mail?: string;
    commerce_order_total?: number;
    commerce_order_total_formatted?: string;
    balance?: number;
    balance_formatted?: string;
    commerce_order_better_balance?: number;
    commerce_order_better_balance_formatted?: string;
    status?: typeof CommerceOrderStatus[keyof typeof CommerceOrderStatus];
    state?: typeof CommerceOrderStatus[keyof typeof CommerceOrderStatus];
    created?: string;
    changed?: string;
    placed?: string;
    field_commerce_billy_i_date?: string;
    field_commerce_billy_cancel_date?: string;
    commerce_coupons?: string;
    commerce_discounts?: string;
    flag_early_order_registration?: boolean;
    flag_check_in_by_order?: boolean;
    flag_scanned_order_id?: boolean;
    commerce_customer_billing?: string;
    commerce_customer_profiles?: Array<CommerceCustomerProfile>;
    commerce_payment_transactions?: Array<CommercePaymentTransaction>;
    commerce_order_payments_amount?: number;
    commerce_order_payments_amount_formatted?: string;
    commerce_line_items_amount?: number;
    commerce_line_items_amount_formatted?: string;
    order_owner?: APIOrderOwner[];
    commerce_line_items?: any;
    commerce_line_items_grouped?: any;
    delegate_registrations?: object;
    custom_order_message?: string;
    custom_early_order_message?: string;
    conference_delegates?: object;  
  }

  export interface APIOrderOwner {
    access?: string;
    church?: any[];
    contact_cell_number?: string | number;
    created?: string;
    flag_free_registration?: boolean;
    flag_free_registration_text?: string;
    hasChurchRole?: boolean;
    hasRegularRole?: boolean;
    linked_church?: number;
    linked_church_value?: string;
    login?: string;
    mail?: string;
    name?: string;
    rid?: string;
    secondary_email?: string;
    status: boolean;
    uid: number;
    user_name?: string;
    uuid?: string;
  }

  export interface CustomerProfileAttachToEntity {
    order_id: number;
    field_name: string;
    attach: CommerceCustomerProfile;
  }

  export interface CommerceCheckoutResponse {
    commerce_customer_billing: CommerceCustomerProfile;
    commerce_payment_transaction: CommercePaymentTransaction;
    commerce_order: CommerceOrder;
    elavon_response: ElavonResponse;
    view: APICommerceOrder;
  }
