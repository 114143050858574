import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../../../shared/authentication-service';
import { IonicModule, Platform } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-firebase-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, CommonModule, FormsModule]
})
export class FirebaseRegistrationComponent {
  constructor(
    public authService: AuthenticationService,
    public router: Router,
    public platform: Platform
  ) {}
  signUp(email, password){
    this.authService.RegisterUser(email.value, password.value)
    .then((res) => {
      this.authService.SendVerificationMail();
      this.router.navigate(['firebase/verify-email']);
    }).catch((error) => {
      window.alert(error.message);
    })
}}