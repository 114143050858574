import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../services/user.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { FlagIonic, SystemConnection } from '../services/drupal7/models';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlagServiceCustom } from '../services/flag.service';
import { DrupalConstants } from '../services/drupal7/public_api';

@Component({
  selector: 'app-scan-settings',
  templateUrl: './scan-settings.component.html',
  styleUrls: ['./scan-settings.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule]
})
export class ScanSettingsComponent implements OnInit {
  flags: FlagIonic[];
  filterFlags: string;
  userSession: SystemConnection;
  constructor(
    public storage: Storage,
    public modalController: ModalController,
    private flagService: FlagServiceCustom,
    private user: UserServiceCustom
  ) {
    this.user.currentSession.subscribe(user => {
      this.userSession = user;
    });
    this.flagService.currentFlags.subscribe(flags => {
      this.flags = flags;
    });
  }

  async ngOnInit() {
    this.initializeData();
  }

  async initializeData() {
    if (!this.flags) {
      this.flagService.changeFlags(await this.user.setFlagAccess(this.userSession, environment.checkInType.entityType, environment.checkInType.bundle, DrupalConstants.storageKey).then(res => res.flags));
    }
  }

  bulkToggle(property: string) {
    Object.values(this.flags).map(k => k[property] = !k[property]);
  }

  async refreshFlagAccess(reset: boolean = false) {
    if (reset) {
      await this.storage.remove('flags_'+environment.checkInType.bundle);
    }
    const result = await this.user.setFlagAccess(this.userSession, environment.checkInType.entityType, environment.checkInType.bundle, DrupalConstants.storageKey);
    this.flagService.changeFlags(result.flags);
  }

  save() {
    this.storage.set('flags_'+environment.checkInType.bundle, this.flags);
    this.flagService.changeFlags(this.flags);
    this.modalController.dismiss(true);
  }
}
