import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { LoadingController, AlertController, NavController } from '@ionic/angular';
import { MessagesService } from './messages.service';
import { of, throwError, BehaviorSubject } from 'rxjs';
import { StorageService } from './storage/storage.service';
import { AvailableResult, BiometryType, NativeBiometric } from 'capacitor-native-biometric';
import { FlagIonic } from './drupal7/models/flag';
import { LoginCredentials, SystemConnection, User, ViewOptions } from './drupal7/models';
import { LoginCredentialsClass } from './drupal7/models/user';
import { UserService, FlagService, EntityService, ViewService } from './drupal7/drupal7-services.module';
import { Router } from '@angular/router';
import { UserFlagging } from '../models/models';
import { DrupalConstants } from './drupal7/public_api';

@Injectable({
  providedIn: 'root'
})
export class UserServiceCustom {

  requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  userSession = new BehaviorSubject<SystemConnection>(null);
  biometrics = new BehaviorSubject<any>(null);
  currentSession = this.userSession.asObservable();
  currentBiometrics = this.biometrics.asObservable();

  userFlaggings = new BehaviorSubject<UserFlagging>(null);
  currentUserFlagging = this.userFlaggings.asObservable();

  constructor(
    private http: HttpClient,
    private flag: FlagService,
    private userService: UserService,
    private viewService: ViewService,
    private entityService: EntityService,
    private router: Router,
    private messagesService: MessagesService,
    public storageService: StorageService,
    public nav: NavController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController) {
      this.getSession();
      // setTimeout(() => {
      //   this.getUserFlaggings();
      // }, 1200);
     }

    changeSession(session: any) {
      this.userSession.next(session);
    }

    changeBiometrics(biometrics: boolean) {
      this.biometrics.next(biometrics);
    }

    async getSession(opts?: {showLoading?: boolean}) {
      if (opts?.showLoading) {
        this.messagesService.showLoading('Getting Account Details...', false, 1500);
      }

      const session: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
      if (session && session.user.uid) {
        const userProfile = await this.userService.getUserById(session.user.uid);
        session.user = Object.assign(session.user, userProfile);
        this.setRoles(session);
        await this.setFlagAccess(session, environment.checkInType.entityType, environment.checkInType.bundle);
      }
      this.storageService.set(DrupalConstants.storageKey, session);
      this.changeSession(session);
    }

    async getBiometrics() {
      this.changeBiometrics(await this.biometricHasCredentials());
    }

    async getUserFlaggings() {
      const userFlaggings = await this.storageService.get('user_flaggings');
      if (userFlaggings) {
        this.changeUserFlaggings(userFlaggings);
      } else {
        this.refreshUserFlaggings();
      }
    }
    changeUserFlaggings(flagging: any) {
      this.userFlaggings.next(flagging);
    }

    async refreshUserFlaggings() {
      const session: SystemConnection = await this.storageService.get(DrupalConstants.storageKey);
      const options: ViewOptions = {
        display_id: 'api_mywcbc_user_flags',
        args: [session.user.uid]
      }
      this.viewService.getView('mywcbc/user/flags', options).then(res => {
        if (res?.results.length) {
          this.storageService.set('user_flaggings', res.results[0]);
          this.changeUserFlaggings(res.results[0]);
        }
      });
    }

    async loginForm(storageKey: string, entityType: string, bundle: string, navigateURL?: string) {
      const alert = await this.alertCtrl.create({
        header: 'Login to continue',
        inputs: [{
            name: 'name',
            type: 'text',
            id: 'name',
            placeholder: 'Username'
        }, {
            name: 'password',
            type: 'password',
            id: 'password',
            placeholder: 'Password'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'danger',
          },
          {
            text: 'Login',
          }
        ]
      });
      await alert.present();
      return await alert.onDidDismiss().then(async (res: any) => {
        console.log(res);
        if (res.role !== 'cancel') {
          const loginBody = new LoginCredentialsClass(res.data.values.name, res.data.values.password);
          let result: SystemConnection;
          await this.login(storageKey, loginBody, true, entityType, bundle).then(async (data: SystemConnection) => {
            if (navigateURL) {
              this.nav.navigateForward(navigateURL);
            }
            return result = data;
          }, (err) => {
            this.messagesService.presentAlert('Invalid', 'Error logging in. Error Code: ' + err.message);
          });
          return result;
        }
      }, err => throwError(err).toPromise());
    }

    async biometricVerification(){
      const result = await NativeBiometric.isAvailable();
      if(!result.isAvailable) {return;}

      const verified = await NativeBiometric.verifyIdentity({
        reason: environment.biometricReason,
      })
        .then(() => true)
        .catch(() => false);

      if(!verified) {return;}

      const credentials = await NativeBiometric.getCredentials({
        server: environment.appSite,
      });
      const res = {result, credentials};
      return res;
    }

    async login(storageKey: string, body: LoginCredentials,
        showLoading: boolean, entityType: string, bundle: string): Promise<SystemConnection> {
      if (showLoading) {
        this.messagesService.showLoading('Logging in...', false, 1500);
      }
      return this.userService.login(body).then(async (loggedInUser: SystemConnection) => {
        this.setRoles(loggedInUser);
        await this.setFlagAccess(loggedInUser, entityType, bundle);
        this.changeSession(loggedInUser);
        this.storageService.set(storageKey, loggedInUser);
        return loggedInUser;
      });
    }

    async biometricAskCredentials(biometricType: BiometryType) {
      const alert = await this.alertCtrl.create({
        header: 'Verify to continue',
        inputs: [{
            name: 'name',
            type: 'text',
            id: 'name',
            placeholder: 'Username'
        }, {
            name: 'pass',
            type: 'password',
            id: 'pass',
            placeholder: 'Password'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'danger',
          },
          {
            text: 'Submit',
            role: 'submit'
          }
        ]
      });
      await alert.present();
      return await alert.onDidDismiss().then(async (res: any) => {
        if (res.role !== 'cancel') {
          return await this.http.post<any>(environment.user.user_authenticate, JSON.stringify(res.data.values), this.requestOptions).toPromise().then(uid => {
            if (!uid) {return;}
            const body = new LoginCredentialsClass(res.data.values.name, res.data.values.pass);
            return this.biometricSetCredentials(body, biometricType);
          }).catch(error => {
            console.log(error);
            return error;
          });
        }
      }, err => err);
    }

    biometricSetCredentials(loginCredentials: LoginCredentials, biometricType: BiometryType) {
      return NativeBiometric.setCredentials({
        username: loginCredentials.username,
        password: loginCredentials.password,
        server: environment.appSite,
      }).then(() => {
        // Some Android devices have multiple biometric options
        if (biometricType === BiometryType.MULTIPLE) {
          this.storageService.set(`login_BiometryType_${BiometryType.FINGERPRINT}`, true);
          this.storageService.set(`login_BiometryType_${BiometryType.FACE_AUTHENTICATION}`, true);
          this.storageService.set(`login_BiometryType_${BiometryType.IRIS_AUTHENTICATION}`, true);
        } else {
          this.storageService.set('login_BiometryType_' + biometricType, true);
        }
        this.changeBiometrics(true);
      }, err => err);
    }

    async biometricHasCredentials() {
      return await NativeBiometric.getCredentials({
        server: environment.appSite,
      }).then(res => true, err => false);
    }

    async biometricGetType() {
      const result: AvailableResult = await NativeBiometric.isAvailable().then(res => res).catch(err => err);
      if(!result.isAvailable) {return;}
      console.log(result);
      return result.biometryType;
    }

    async biometricDeleteCredentials() {
      return NativeBiometric.deleteCredentials({
        server: environment.appSite,
      }).then(() => {
        this.changeBiometrics(false);
        return true;
      }, () => false);
    }

    setRoles(session: SystemConnection) {
      session.user.role_types = {};
      Object.keys(environment.user.roleTypes).map(key => {
        session.user.role_types[key] = false;
        Object.values(session.user.roles).map(role => {
          if (!session.user.role_types[key]) {
            session.user.role_types[key] = environment.user.roleTypes[key].indexOf(role) !== -1 ? true : false;
          }
        });
      });
    }

    setFlagTypes(flags: FlagIonic[]) {
      const res: any = {};
      Object.keys(environment.flag.flagTypes).map(key => {
        res[key] = [];
        Object.values(flags).map(flag => {
          if (environment.flag.flagTypes[key].indexOf(flag.name) !== -1) {
            res[key].push(flag);
          }
        });
        return res;
      });
      this.storageService.set('flags_by_type', res);
      return res;
    }

    setFlagsDisabled(flags: FlagIonic[]) {
      Object.keys(flags).map(key => {
        if(key === 'conference_check_in') {
          flags[key].disabled = [{name: 'delegate_paid', value: false}, {name: 'not_attending', value: true}];
        }
        if(key === 'not_attending') {
          flags[key].disabled = [{name: 'conference_check_in', value: true}];
        }
        if (key === 'delegate_paid') {
          flags[key].disabled = [{name: 'not_attending', value: true}, {name: 'conference_check_in', value: true}];
        }
        if (environment.flag.flagTypes.disabledIfPaid.indexOf(key) !== -1) {
          flags[key].disabled = [{name: 'delegate_paid', value: true}];
        }
      });
      return flags;
    }

    async setFlagAccess(session: SystemConnection, entityType: string, bundle: string, storageKey?: string) {
      const flagAccess = await this.flag.getAllFlags();
      const currentFlags: FlagIonic[] = await this.storageService.get('flags_' + bundle);
      session.user.flag_access = {flag: {}, unflag: {}};
      const flagsArray = [];
      const userFlagsArray = [];
      flagAccess.map((flag) => {
        if (bundle) {
          if (flag.types.indexOf(bundle) !== -1) {
            this.setFlagsDisabled(flagAccess);
            // Set the flag settings that were previously set by the user
            if (currentFlags) {
              const storedFlag = currentFlags.filter(f => f.fid === flag.fid)[0];
              if (storedFlag) {
                flag.flag_on_scan = storedFlag.flag_on_scan;
                flag.status = storedFlag.status;
                flag.view = storedFlag.view;
              }
            }
            flagsArray.push(flag);
          }
        } else {
          flagsArray.push(flag);
        }
        if (flag.entity_type === 'user') {
          userFlagsArray.push(flag);
        }
        Object.keys(session.user.roles).map(rid => {
          session.user.flag_access.flag[flag.name] = flag.roles.flag[rid] ? true : false;
          session.user.flag_access.unflag[flag.name] = flag.roles.unflag[rid] ? true : false;
          return session;
        });
      });
      this.setFlagTypes(flagAccess);
      if (bundle) {
        this.storageService.set('flags_'+bundle, flagsArray);
      } else {
        this.storageService.set('flags', flagsArray);
      }
      this.storageService.set('flags_user', userFlagsArray);
      if (storageKey) {
        this.storageService.set(storageKey, session);
        this.changeSession(session);
      }
      return {flags: flagsArray, session};
    }

    register(body: any) {
      this.messagesService.showLoading('Registering your account...', false, 500);
      console.log(body);
      return this.entityService.createEntity('user/register', body).then( data => {
          console.log(data);
          return data;
        }),
        catchError(err => {
          console.error('Error Message: ', err);
          const message = err.statusText.substring(17).replace('The name', 'The username')
          .replace(' Have you forgotten your password?', '');
          this.messagesService.presentAlert('Error', message);
          return of (false);
        });
    }

    async delete(uid: number, session: any) {
      this.messagesService.presentToast('Deleting the account for ' + session.user.name, 2500);
      return this.userService.deleteUser(uid).then(res => {
        console.log(res);
        return res;
      });
    }

    async cancel(uid: number, session: any) {
      this.messagesService.presentToast('Deleting the account for ' + session.user.name, 2500);
      return this.userService.cancelUser(uid).then(res => {
        console.log(res);
        this.messagesService.presentAlert('Account removed for ' + session.user.name, 'Your account has been cancelled.');
        this.logout();
        return res;
      });
    }

    updatePassword(uid: number, field: any, token: string) {
      return this.userService.userPasswordResetToken(uid, field, token).then(res => {
        this.messagesService.presentToast('User updated', 2000);
        return res;
      }, error => {
        console.log(error);
        this.messagesService.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
      });
    }

    async logout() {
      this.messagesService.showLoading('Logging out', false, 2500);
      return this.userService.logout().then(res => {
        console.log(res);
        this.storageService.clear();
        this.changeSession(null);
        this.router.navigateByUrl('/login', {replaceUrl: true});
        return res;
      }, err => {
        this.storageService.clear();
        this.router.navigateByUrl('/login', {replaceUrl: true});
        this.changeSession(null);
      });
    }
  }

