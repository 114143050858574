import { Component, inject, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TableAssignment } from 'src/app/services/table-assignments.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-seat-change',
  templateUrl: './seat-change.component.html',
  styleUrls: ['./seat-change.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SeatChangeModalComponent implements OnInit {

  private firebaseService = inject(FirebaseService);
  private modalCtrl = inject(ModalController);

  @Input() person: TableAssignment;
  @Input() tables: string[] = [];
  @Input() seats: string[] = [];
  @Input() saveToFirebase: boolean = true;

  selectedTable: string = '';
  selectedSeat: string = '';

  constructor() {}

  ngOnInit() {
    this.selectedTable = this.person.table;
    this.selectedSeat = this.person.seat_number;
  }

  async saveChanges() {
    if (this.selectedTable && this.selectedSeat) {
      const id = `${this.person.foreign_key}_${this.person.id.toString()}`;
      if (this.saveToFirebase) {
        const updated = this.firebaseService.updateDocument(id, this.selectedTable, this.selectedSeat);
        if (updated) {
          this.modalCtrl.dismiss({table: this.selectedTable, seat: this.selectedSeat }, 'submit');
        }
      } else {
        this.modalCtrl.dismiss({table: this.selectedTable, seat: this.selectedSeat }, 'submit');
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}