// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `small {
  margin: 2rem auto;
  display: block;
}

ion-avatar {
  margin-inline: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  flex-flow: column;
  gap: 5px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/account/drupal/my-account/my-account.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;AACJ;;AAEE;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,QAAA;EACA,kBAAA;AACJ","sourcesContent":["small {\n    margin: 2rem auto;\n    display: block;\n  }\n\n  ion-avatar {\n    margin-inline: 20px;\n    margin-top: 2px;\n    margin-bottom: 2px;\n    display: flex;\n    flex-flow: column;\n    gap: 5px;\n    position: relative;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
