import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../services/user.service';
import { MessagesService } from '../services/messages.service';
import { IonSearchbar, IonicModule, ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule, KeyValue } from '@angular/common';
import { EntityServiceCustom, EntitySetupOptions } from '../services/entity.service';
import { QrCodeScanService } from '../services/codescan/codescan.service';
import { APICommerceOrder, FlagIonic, FormData, Pager, SystemConnection, ViewOptions } from '../services/drupal7/models';
import { FlagServiceCustom } from '../services/flag.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../system/header/header.component';
import { FiltersService } from '../services/filters.service';
import { QRCodeModule } from 'angularx-qrcode';
import { RegistrationCardComponent } from '../components/registration-card/registration-card.component';
import { PagerComponent } from '../system/pager/pager.component';
import { PagerSummaryComponent } from '../system/pager/pager-summary.component';
import { PaymentPage } from '../payment/payment.page';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.page.html',
  styleUrls: ['./check-in.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, HeaderComponent, QRCodeModule, RegistrationCardComponent, PagerSummaryComponent, PagerComponent]
})
export class CheckInComponent implements OnInit {

  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  userSession: SystemConnection;

  flags: FlagIonic[];
  headers: Array<string>;
  data: FormData[];
  noResultsData = true;
  leftPage: boolean = false;
  inputData: string | number;
  scannedInput: string | number;
  pager: Pager;
  sortDirection = 0;
  sortKey = null;
  bulkEdit = false;
  editObj = {};
  resultsCount = 10;
  itemsPerPage = [5, 10, 20, 30, 40, 60];
  focusInput = false;
  numberFlagged: number;

  inputOrder: number;
  inputNid: number;

  constructor(
    private entityService: EntityServiceCustom,
    private flagService: FlagServiceCustom,
    private platform: Platform,
    private codeScan: QrCodeScanService,
    private modalCtrl: ModalController,
    private filtersService: FiltersService,
    public router: Router,
    public storage: Storage,
    private user: UserServiceCustom,
    public message: MessagesService
    ) {
      this.user.currentSession.subscribe(user => {
        if (user) {
          this.userSession = user;
        }
      });
      this.flagService.currentFlags.subscribe(flags => {
        this.flags = flags;
      });
      this.filtersService.currentFilters.subscribe(filters => {
      });
      document.addEventListener('keypress', (evt: KeyboardEvent) => {
        this.scan(evt);
      });
   }


   @HostListener("window:beforeunload")
      ngOnDestroy() {
        console.log("check in component destroyed");
        this.leftPage = true;
        document.removeEventListener('keypress', (evt: KeyboardEvent) => {
      }, true);
    }

   async ngOnInit() {}

   async scan(event) {
    if (!this.leftPage) {
    const res = await this.codeScan.scan(event);
    if (res) {
      this.inputOrder = res.order_id;
      this.message.showLoading('Loading order ' + this.inputOrder, false, 1500);
      this.get('', '', true);
    }
  }
  }

  async searchOrder(orderID: number) {
    console.log('search order');
    this.inputOrder = orderID;
    this.message.showLoading('Loading order ' + this.inputOrder, false, 1500);
    this.get();
  }

  async searchNid(nid: number) {
    console.log('sarch nid');
    this.inputNid = nid;
    this.message.showLoading('Loading delegate id ' + this.inputNid, false, 1500);
    this.get();
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;

  async scanQRCode() {
    if (this.platform.is('capacitor')) {
      if (!await this.storage.get('defaultScanType')) {
        const result = await this.codeScan.scanQRCode();
        if (result) {
          const qrCode = await this.codeScan.parseScan(result);
          this.inputOrder = qrCode.order_id;
          this.message.showLoading('Searching for ' + qrCode.order_id, false, 1500);
          this.get('', '', true);
        }
      } else {
        this.focusInput = !this.focusInput;
        this.scannedInput = '';
        this.searchInput.setFocus();
      }
    } else if (!await this.storage.get('defaultScanType')) {
      const result = await this.codeScan.startBarcodeScan();
      if (result) {
        const qrCode = await this.codeScan.parseScan(result);
        console.log(qrCode);
        this.inputOrder = qrCode.order_id;
        this.message.showLoading('Searching for ' + qrCode.order_id, false, 1500);
        this.get('', '', true);
      }
    } else {
      this.focusInput = !this.focusInput;
      this.scannedInput = '';
      this.searchInput.setFocus();
    }
  }

  async parseScan(res: any) {

    if (res) {
      if (res.order_id) {
        console.log('this is the order id');
        this.scannedInput = res.order_id;
        this.message.showLoading('Checking the status of order ' + res.order_id, false, 800);
        // this.message.presentToast('Checking the status of order ' + res.order_id, 500);
        const order = await this.reloadOrder(res);
        
        if (order.state === 'invoiced') {
          // this.loadOrder(res.order_id);
        } else if (order.state !== 'canceled') {
          // this.pay(res);
        }
      } else if (res.id) {
        console.log('this is the delegate id');
        // this.search(res.id, true, true);
      }
      // if (res.uid) {
      //   console.log('this is the user id');
      //   console.log(res.uid);
      // }
    }
  }

  async reloadOrder(order: APICommerceOrder) {
    this.message.showLoading('Refreshing the order... ', false, 2000);
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [order.order_id],
    };
    const foundOrder = await this.entityService.getViewWithOptions(`registration/commerce-order`, options);
    if (foundOrder?.results.length && foundOrder?.results[0]?.order_id === order.order_id) {
      return foundOrder.results[0];
    }
  }

  async loadOrder(orderID: number) {
    this.message.showLoading('Loading delegates from order ' + orderID, false, 1500);
    this.inputOrder = orderID;
    this.get();
  }

  async get(id: string = '', page: string = '', autoFlag: boolean = false) {
    const options: ViewOptions = {
      display_id: 'api_registration_delegate',
      filters: {
        id,
        page,
        items_per_page: this.resultsCount
      }
    };
    const setupOptions: EntitySetupOptions = {
      autoFlag,
      uniqueOrders: true,
      qrCodes: true,
    };
    if (this.inputOrder) {
      options.filters.order_id = this.inputOrder;
    }
    if (this.inputNid) {
      options.filters.nid = this.inputNid;
    }
    const foundRegistrations = await this.entityService.getViewWithOptions(`registration`, options, setupOptions);
    if (foundRegistrations) {
      this.pager = foundRegistrations.pager;
      this.focusInput = false;
      this.noResultsData = false;
      this.data = foundRegistrations.results;
    } else {
      this.noResultsData = true;
    }
    this.inputOrder = null;
    this.inputNid = null;
  }

  async pay(orderID: number) {
    this.message.showLoading('Fetching the order', false, 1000);
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [orderID],
    };
    const foundOrder = await this.entityService.getViewWithOptions(`registration/commerce-order`, options);
    console.log(foundOrder);
    if (foundOrder?.results.length && foundOrder?.results[0]?.order_id === orderID) {
      const modal = await this.modalCtrl.create({
        component: PaymentPage,
        cssClass: 'fullscreen',
        componentProps: {
          order: foundOrder.results[0],
          session: this.userSession,
        }
      });
      await modal.present();
      const res = await modal.onDidDismiss();
      console.log(res);
      if (res.role === 'submit') {  
        this.loadOrder(orderID);
        // this.inputData = '';
        // this.data = null;
        // this.message.showLoading('Loading delegates from order ' + orderId, false, 2000);
        // this.loadOrderAndFlagAsCheckedIn();
      }
    }
  }


  search(input: any, showLoading: boolean = true, autoflag: boolean = false) {
    if (input !== '' && input !== undefined) {
      if (showLoading) {
        this.message.showLoading('Searching for ' + input, false, 1500);
      }
      this.get(input, '', autoflag);
    }
  }

  resetSearch() {
    this.inputData = '';
    this.scannedInput = '';
    this.data = null;
    this.message.showLoading('Resetting', false, 300);
    // this.get();
  }

  checkForDuplicate(entity: any, i: number) {
    const index = this.data.map((delegate) => delegate.nid).indexOf(entity.nid);
    if (index !== i) {
      entity.shadowCopy = true;
      return true;
    } else {
      return false;
    }
  }


}