import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule, LoadingController, ModalController } from "@ionic/angular";
import { DrupalFormControlObject, EntityService, Pager, ViewOptions } from "../../services/drupal7/public_api";
import { FormsService } from "../../services/drupal-forms/forms.service";
import { Church, DelegateRegistration } from "src/app/models/models";
import { EntityServiceCustom } from "src/app/services/entity.service";
import { PagerComponent } from "src/app/system/pager/pager.component";
import { PagerSummaryComponent } from "src/app/system/pager/pager-summary.component";

@Component({
    selector: 'app-entity-reference-linked-church',
    styleUrls: ['./entity_reference_linked_church.component.scss'],
    templateUrl: './entity_reference_linked_church.component.html',
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, PagerComponent, PagerSummaryComponent]
})
export class EntityReferenceLinkedChurchComponent implements OnInit {
    @Input() entity: DelegateRegistration;
    @Input() field: DrupalFormControlObject;

    fieldOptions: any[] = [];
    pager: Pager;
    proposedChurch: Church;
    existingLinkedChurch: Church;
    loading: HTMLIonLoadingElement;

    inputData: string | number;

    constructor(
        public formsService: FormsService,
        private entityService: EntityService,
        private entityServiceCustom: EntityServiceCustom,
        private loadingCtrl: LoadingController,
        private modalCtrl: ModalController) {}

    ngOnInit(): void {
        console.log('field');
        this.setFieldClassType();
        if (!this.field) {
            this.field = {
                field_name: 'linked_church',
                label: 'Linked Church',
                type: 'entityreference',
                ionic_type: 'list',
                status: true,
                access: true
            }
        }
    }

    reset() {
        this.fieldOptions = [];
        this.proposedChurch = null;
        this.existingLinkedChurch = null;
    }

    setFieldClassType() {
        this.reset();
        if (this.entity.church?.length) {
            this.existingLinkedChurch = this.entity.church[0];
        }
    }

    async search(page: string = '') {
        this.proposedChurch = null;
        const options: ViewOptions = {
          display_id: 'api_available_churches',
          convert_options: true,
          filters: {
            id: this.inputData,
          }
        };
        if (page) {
            options.filters.page = page;
        }
        const url = `available/${this.field.field_name}`;
        await this.entityServiceCustom.getViewWithOptions(url, options).then(res => {
          this.fieldOptions = res.results;
          this.pager = res.pager;
          console.log(res);
        });
      }


    setValue(church: Church) {
        this.proposedChurch = church;
    }

    cancel() {
        this.modalCtrl.dismiss({}, 'cancel');
    }

    async submit() {
        this.loading = await this.loadingCtrl.create({
            message: 'Submitting...'
        });
        await this.loading.present();
        const body = {linked_church: this.proposedChurch.nid};
        const updatedLinkedChurch = await this.entityService.updateEntity('node', body, this.entity.nid);
        this.loading.dismiss();
        this.reset();
        this.modalCtrl.dismiss(updatedLinkedChurch, 'submit');
    }

}