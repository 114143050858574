import { NetworkService, NetworkStatus } from './services/network/network.service';
import { Component } from '@angular/core';

import { IonicModule, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment.prod';
import { MenuComponent } from './system/menu/menu.component';
import { KioskService } from './services/kiosk.service';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, MenuComponent],
})
export class AppComponent {

  siteName = environment.siteName;
  constructor(
    private platform: Platform,
    public router: Router,
    private storageService: StorageService,
    private kioskService: KioskService,
    private network: NetworkService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.network.onNetworkChange().subscribe(async (status: NetworkStatus) => {
        if (status === NetworkStatus.online) {
        }
      });
    });

    this.kioskService.onKioskChange().subscribe(res => {
      if (res) {
        this.router.navigate(['/self-check-in'], {replaceUrl: true});
      }
    });

  }
}
