import { Injectable } from '@angular/core';
import { MainService } from '../drupal7-services.module';
import { PostalAddress, SystemConnection, ElavonCreditCardTransaction,
  ElavonProcess, ElavonSubmitInfo, APICommerceOrder, Payment, CommercePaymentTransaction } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ElavonService extends MainService {
  override entityType = 'commerce_elavon';
  instance_id = 'elavon_direct|commerce_payment_elavon_direct';

  /**
   * implement create resource
   *
   * @param elavonTransaction payment transaction entity to create
   * @return created payment transaction details
   */
    createPaymentTransaction(elavonTransaction: ElavonProcess): Promise<CommercePaymentTransaction> {
      return this.post(elavonTransaction);
    }


  elavonPaymentTransactionProcessPrepare(billingData: PostalAddress, cardDetails: ElavonCreditCardTransaction, payment: Payment,
    order: APICommerceOrder, session: SystemConnection): ElavonProcess {
    const formattedAmount = parseInt(payment.amount.toString().replace('.', ''), 10);
    const charge = {amount: formattedAmount, currency_code: payment.currency_code};
    const expDate = cardDetails.exp_date ? cardDetails.exp_date : cardDetails.exp_month.substring(5, 7) + cardDetails.exp_year.substring(2, 4);

    const info: ElavonSubmitInfo = {
      ssl_card_number: +cardDetails.card_number,
      ssl_exp_date: expDate,
      ssl_cvv2cvc2: cardDetails.code,
      ssl_cvv2cvc2_indicator: 1,

      ssl_amount: payment.amount / 100,
      ssl_avs_address: billingData.thoroughfare,
      ssl_address2: billingData.premise,
      ssl_city: billingData.locality,
      ssl_state: billingData.administrative_area,
      ssl_avs_zip: billingData.postal_code,
      ssl_first_name: billingData.first_name,
      ssl_last_name: billingData.last_name,
      ssl_salestax: 0,
      ssl_invoice_number: +order.order_number,

      ssl_transaction_type: 'ccsale',
    };

    const body: ElavonProcess = {
      instance_id: this.instance_id,
      info,
      order,
      charge,
      uid: session.user.uid,
      card_details: cardDetails
    };

    return body;
  }

  /**
   * 
   * @param billingData the Postal Address
   * @param cardDetails the card details
   * @param payment the commerce payment
   * @param order the commerce order
   * @param session the logged in user
   * @returns 
   */
  elavonPaymentTransactionProcess(billingData: PostalAddress, cardDetails: ElavonCreditCardTransaction, payment: Payment,
    order: APICommerceOrder, session: SystemConnection): Promise<any> {
    const body = this.elavonPaymentTransactionProcessPrepare(billingData, cardDetails, payment, order, session);
    console.log(body);
    // return new Promise((resolve, reject) => {
    //   resolve(body);
    //   reject(false);
    // });
    return this.post(body);
  }
}

