import { Routes } from '@angular/router';
import { PrintBadgesComponent } from './badges/delegates/badges.component';
import { SmsInboxComponent } from './sms-inbox/sms-inbox.component';
import { SelfCheckInComponent } from './self-check-in/self-check-in.component';
import { CheckInComponent } from './check-in/check-in.page';
import { inject } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { MyAccountPage } from './account/my-account/my-account.page';
import { LoginPage } from './account/login/login.page';
import { SeatingListComponent } from './seating/seating-list/seating-list.component';
import { PrintChurchMemberBadgesComponent } from './badges/church-members/badges.component';
import { TableAssignmentsComponent } from './table-assignments/table-assignments.component';


export const routes: Routes = [
  { path: '', canActivate: [() => inject(AuthGuard).isLoggedIn()],
    children: [
      { path: 'check-in', component: CheckInComponent, canActivate: [() => inject(AuthGuard).redirectSeatViewer()], canDeactivate: [() => inject(AuthGuard).canDeactivate()]},
      { path: '', redirectTo: 'check-in', pathMatch: 'full'},
    ]},
    { path: 'badges', component: PrintBadgesComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'church-badges', component: PrintChurchMemberBadgesComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'sms-inbox', component: SmsInboxComponent},
    { path: 'self-check-in', component: SelfCheckInComponent},
    { path: 'table-assignments', component: TableAssignmentsComponent},
    { path: 'seating', component: SeatingListComponent, canActivate: [() => inject(AuthGuard).isLoggedIn()]},
    { path: 'login', canActivate: [() => inject(AuthGuard).init()], component: LoginPage},
    { path: 'my-account', component: MyAccountPage, canActivate: [() => inject(AuthGuard).isLoggedIn()]}
  
];

