/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
export const baseURL = 'https://slconferenceasia.com/';

export const environment = {
  production: true,
  siteName: 'SLC Asia Registration',
  biometricReason: 'Login to Spiritual Leadership Asia Conference',
  appSite: 'https://slc-asia-registration.web.app/',
  version: '2.1.0',
  firebaseConfig: {
    apiKey: 'AIzaSyAV7ZgShe-_pBopsEaSRQSE0cUYYA5QmzQ',
    authDomain: 'slc-asia-registration.firebaseapp.com',
    projectId: 'slc-asia-registration',
    storageBucket: 'slc-asia-registration.appspot.com',
    messagingSenderId: '442198804404',
    appId: '1:442198804404:web:7b72f4768fdc4887df54e1',
    measurementId: 'G-010C4DV22D'
  },
  user: {
    user_authenticate: baseURL + 'api/my_custom_api/user_authenticate',
    churchMemberRole: '',
    regularUserRole: '7',
    roleTypes: {
      UnverifiedRoles: ['unverified user'],
      VerifiedRoles: ['verified user'],
      ChurchRoles: ['church member'],
      RegularRoles: ['regular user'],
      AuthorizedRoles: ['badge admin', 'app', 'administrator', 'group manager', 'conference admin', 'volunteer'],
      AdminRoles: ['app', 'administrator', 'group manager', 'conference admin', 'badge admin'],
    },
  },
  flag: {
    badgePrinted: {
      fid: 34,
      entity_type: 'node',
      global: 1,
      name: 'badge_printed',
      label: 'Badge Printed',
      title: 'Badge Printed'
    },
    studentTypes: {
      off_campus_student: {
        types: ['off_campus_student'],
        value: 'Off Campus Student'
      }
    },
    flagTypes: {
      badgeFlags: ['badge_printed'],
      confirmFlags: ['delegate_paid', 'not_attending'],
      notAttendingFlags: ['not_attending'],
      disabledIfPaid: [],
      checkInOutFlags: ['checked_in'],
      selfCheckInOutFlags: ['checked_in'],
      priceFlags: ['ten_dollars_off', 'twenty_dollars_off', 'forty_dollars_off', 'ten_percent_off', 'fifty_percent_off', 'free_registration_delegate', 'free_dorms_delegate', 'free_childcare', 'free_activity', 'charge_dorm_fee', 'delegate_sponsored', 'wcbc_alumni_discount', 'spouse_of_alumni', 'early_registration', 'early_registration_alumni', 'special_group_discount', 'church_planter', 'free_registration']
    },
    fieldNameCheck: ''
  },
  mimemail: {
    apiURL: baseURL + 'api/send-email.json',
    key: 'slc',
    to: 'slcsupport@lancasterbaptist.org',
    cc: '',
    bcc: '',
    from_name: '',
    from_mail: '',
    reply_to: '',
    subject: '',
    body: '',
    plaintext: '',
    attachments: '',
    langcode: ''
  },
  appSettings: {
    AppUser: {username: 'app', password: 'slc!7media' },
    AppUserUID: 1844,
    linkDevicePass: 426598,
    SeatViewerUID: 10519,
    resetDevicePass: 'slcaReset'
  },
  checkInType: {
    entityType: 'node',
    bundle: 'delegate_registration'
  },
  badgeTemplate: '../../../assets/imgs/slc-asia-badge-template_slca.png',
  badgeType1: ['professional_businessman'],
  badgeType2: [],
  delegateTypes: {
    delegate: {
      types: ['church_member', 'homemaker', 'support_staff', 'ministry_employee'],
      value: 'Delegate'
    },
    pastor: {
      types: ['filipino_pastor', 'pastor'],
      value: 'Pastor'
    },
    bible_college_student: {
      types: ['bible_college_student', 'wcbc_student'],
      value: 'Bible College Student'
    },
    conference_host: {
      types: [13092, 6684, 13094, 10959, 7603, 6671, 7706, 9923, 7188, 6685],
      value: 'Conference Host'
    },
    event_staff: {
      types: [6697, 6698, 14408, 14409, 15072, 15073, 7189],
      value: 'Event Staff'
    },
  },
  badgeGroups: {
    'Bethany Baptist Church': 'A',
    'Iloilo Baptist Church': 'B',
    'La Loma Baptist Church': 'C',
    'Mt. Calvary Baptist Church': 'D',
    'International A': 'E',
    'International B': 'F',
    'Asian Baptist Clearinghouse': 'G',
    'Berean Bible Baptist Church': 'H'
  },
  badgeRequiresReprint: ['contact_first_name', 'contact_last_name', 'country', 'country_value', 'delegate_type', 'delegate_type_value'],
  arrayFields: ['childcare_information', 'author', 'church', 'spouse_childcare', 'seat_reservation', 'number_of_items'],
  numberToStringFields: ['phone_country_code']
};

