import { Component, inject, OnInit } from '@angular/core';
import { TwilioService } from '../services/twilio.service';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DrupalJsonApiParams } from '../services/jsonapi';
import { UserService } from '../services/drupal10/user/user.service';

@Component({
  selector: 'app-sms-inbox',
  templateUrl: './sms-inbox.component.html',
  styleUrls: ['./sms-inbox.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SmsInboxComponent implements OnInit {

  private twilio = inject(TwilioService);
  private userService = inject(UserService);

  constructor() { }

  ngOnInit() {}

  async test() {

   const login = await this.userService.login({name: 'volunteer1', pass: 'volunteer'});

   console.log(login);
    // const apiParams = new DrupalJsonApiParams();

    // apiParams.addFilter('title', 'Lancaster', 'CONTAINS');

    // const urlencodedQueryString = apiParams.getQueryString();
    // const queryString = apiParams.getQueryString({ encode: false });

    // console.log(urlencodedQueryString);
    // console.log(queryString);

  }

  // test() {
  //   this.twilio.get().then(res => {
  //     console.log(res);
  //   });
  // }

}
