import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import { StorageService } from './storage/storage.service';
import { AlertController } from '@ionic/angular';
import { FlagAction, FlagIonic } from './drupal7/models/flag';
import { CommerceOrderStatus } from './drupal7/models/commerce';
import { FlagService } from './drupal7/drupal7-services.module';
import { BehaviorSubject } from 'rxjs';
import { DelegateRegistration } from '../models/models';
import { SystemConnection } from './drupal7/public_api';
import { UserServiceCustom } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FlagServiceCustom {

  flags = new BehaviorSubject<any>(null);
  currentFlags = this.flags.asObservable();
  flagTypes = new BehaviorSubject<any>(null);
  currentFlagTypes = this.flagTypes.asObservable();

  userSession: SystemConnection;

  constructor(
    private alertCtrl: AlertController,
    private flagService: FlagService,
    private userService: UserServiceCustom,
    private message: MessagesService,
    private storageService: StorageService,
    public storage: Storage) {
      this.getFlags();
      this.userService.currentSession.subscribe(session => {
        if (session) {
          this.userSession = session;
        }
      });

    }

    async getFlags() {
      this.changeFlags(await this.storage.get('flags_'+environment.checkInType.bundle));
      this.changeFlagTypes(await this.storage.get('flags_by_type'));
    }
    
    changeFlags(flags: FlagIonic[]) {
      this.flags.next(flags);
    }

    changeFlagTypes(flagTypes: any) {
      this.flagTypes.next(flagTypes);
    }

    getFlaggingName(entity: DelegateRegistration) {
      const firstName = entity.contact_first_name ? entity.contact_first_name : entity.contact_first_name;
      const lastName = entity.contact_last_name ? entity.contact_last_name : entity.contact_last_name;
      const fullName = `${firstName} ${lastName}`;
      return {
        firstName,
        lastName,
        fullName
      }
    }

    async autoflag(entities: DelegateRegistration[]) {
      const flags: FlagIonic[] = await this.storage.get('flags_'+environment.checkInType.bundle);
        for (const flag of flags) {
          if (this.userSession.user.flag_access.flag[flag.name] && flag.flag_on_scan && flag.status) {
            for (const entity of entities) {
              if (!entity['flag_'+flag.name]) {
                const entityName = this.getFlaggingName(entity);
                const flagMessage = `Flagged ${flag.label} for ${entityName.fullName}`;
                const unflagMessage = `Unflagged ${flag.label} for ${entityName.fullName}`;
                const newFlagStatus = await this.flagEntity(entity, flag, entity['flag_'+flag.name], this.userSession, flagMessage, unflagMessage);
                entity['flag_'+flag.name] = newFlagStatus;
                // await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
              }
            }
          }
        }
    }

    async bulkFlag(entities: DelegateRegistration[], flag: FlagIonic, flagAction: 'flag' | 'unflag', session: SystemConnection, skipPermissionCheck?: boolean) {
      const alert = await this.alertCtrl.create({
        header: 'Confirm ' + flag.label,
        message: 'Are you sure you want to ' + flagAction + ' ' + flag.label + ' for ' + entities.length + ' entities?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: 'Confirm',
            role: 'submit',
            cssClass: 'warning',
          }
        ]
      });
      await alert.present();
      return await alert.onDidDismiss().then(async (res: any) => {
        if (res.role === 'submit') {
          const body: FlagAction[] = [];
          for (const entity of entities) {
            const flagging: FlagAction = {
              flag_name: flag.name.replace('flag_', ''),
              entity_id: entity.id
            };
            if (entity['flag_'+flag.name] !== undefined) {
                flagging.action = flagAction;
            }
            if (session?.user?.uid !== undefined) {
                flagging.uid = session.user.uid;
            }
            if (skipPermissionCheck !== undefined) {
                flagging.skip_permission_check = skipPermissionCheck;
            }
            body.push(flagging);
          }
          console.log(body);
          return this.flagService.bulkFlag(body).then(flaggedEntities => {
            console.log(flaggedEntities);
            const message = flagAction + 'ged ' + flag.label + ' for ' + entities.length + ' entities';
            this.message.presentToast(message, 2000);
            return flaggedEntities;
          });
        } else {
          return false;
        }
      });
    }


  async flagEntity(entity: DelegateRegistration, flag: FlagIonic, flagAction: boolean, session: SystemConnection,
    flagToastMessage: string, unflagToastMessage: string, storageKey?: string, confirmed: boolean = true, skipPermissionCheck?: boolean): Promise<boolean> {

      if (environment.flag.flagTypes.confirmFlags.indexOf(flag.name) > -1) {
        confirmed = await this.confirmFlag(entity, flag, flagAction);
      }
      if (confirmed) {
        const action = flagAction === true ? 'unflag' : 'flag';
        const entityName = this.getFlaggingName(entity);
    
        this.message.showLoading(`${flagAction ? `Unflagging ${entityName.fullName}` : `Flagging ${entityName.fullName}`}`, false, 150);
        const flagging: FlagAction = {
          flag_name: flag.name.replace('flag_', ''),
          entity_id: entity.id
        };
        if (action !== undefined) {
            flagging.action = action;
        }
        if (session?.user?.uid !== undefined) {
            flagging.uid = session.user.uid;
        }
        if (skipPermissionCheck !== undefined) {
            flagging.skip_permission_check = skipPermissionCheck;
        }
        return this.flagService.flag(flagging).then(data => {
          if (data[0] === true) {
            const flaggingAction = action === 'flag' ? true : false;
            entity['flag_'+flag.name] = flaggingAction;
            if (storageKey) {
              this.storageService.storeEntity(storageKey, entity, 'nid');
            }
            if (flaggingAction) {
              this.message.presentToast(flagToastMessage, 2000);
            }
            if (!flaggingAction) {
              this.message.presentToast(unflagToastMessage, 2000);
            }
          }
          return true;
        })
        .catch((err: any) => {
          console.error('Error Message: ', err);
          this.message.presentToast(err.status + err.statusText, 2000, 0, 'bottom', 'danger');
          entity['flag_'+flag.name] = flagAction;
          return false;
        });
    } else {
      console.log(flagAction);
      return false;
    }
  }

  async confirmFlag(entity: DelegateRegistration, flag: FlagIonic, flagValue: boolean) {
    const action = flagValue ? 'unflag' : 'flag';
    const entityName = this.getFlaggingName(entity);
    const alert = await this.alertCtrl.create({
      header: 'Confirm ' + flag.label,
      message: `Are you sure you want to ${action} ${entityName.fullName} as ${flag.label}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Confirm',
          role: 'submit',
          cssClass: 'warning',
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (res: any) => {
      if (res.role === 'submit') {
        return true;
      } else {
        return false;
      }
    });
  }

  unflagBadgePrinted(entity: DelegateRegistration, key: string, newVal: any) {
    if (environment.badgeRequiresReprint.indexOf(key) !== -1) {
      const flag: FlagIonic = environment.flag.badgePrinted;
      if (key === environment.flag.fieldNameCheck) {
        let sameType = false;
        Object.keys(environment.flag.studentTypes).map(keys => {
          const value = environment.flag.studentTypes[keys];
          if (value.types.indexOf(entity[key]) !== -1 && value.types.indexOf(newVal) !== -1) {
            sameType = true;
          }
        });
        if (!sameType) {
          if (entity['flag_'+flag.name]) {
            this.message.presentToast('This change requires the badge to be reprinted. Unflagging as printed.', 3000, 0, 'top', 'danger');
            this.setupFlagMessageAndFlag(entity, flag, entity['flag_'+flag.name]);
          }
        }
      } else {
        if (entity['flag_'+flag.name]) {
          this.message.presentToast('This change requires the badge to be reprinted. Unflagging as printed.', 3000, 0, 'top', 'danger');
          this.setupFlagMessageAndFlag(entity, flag, entity['flag_'+flag.name]);
        }
      }
    }
  }

  async setupFlagMessageAndFlag(entity: DelegateRegistration, flag: FlagIonic, flagValue: boolean) {
    const entityName = this.getFlaggingName(entity);
    const flagMessage = `Flagged ${flag.label} for ${entityName.fullName}`;
    const unflagMessage = `Unflagged ${flag.label} for ${entityName.firstName} ${entityName.lastName}`;
    const session = await this.storage.get('session');
    const newFlagStatus = await this.flagEntity(entity, flag, flagValue, session, flagMessage, unflagMessage);
    console.log(newFlagStatus);
    entity['flag_'+flag.name] = newFlagStatus;
  }

  async getActiveFlags() {
    const result: object = {};
    const allFlags: FlagIonic[] = [];
    for await (const flag of allFlags) {
      result[flag.category] = allFlags.filter(o => o.category === flag.category && o.status);
    }
    const arr = Object.values(result).sort((a, b) => a[1] - b[1]);
    console.log(arr);
    return arr;
  }

  checkFlagDisabled(flag: FlagIonic, entity: DelegateRegistration): boolean {
    if (entity['flag_'+flag.name] && environment.flag.flagTypes.notAttendingFlags.find(f => f === flag.name)) {
      return true;
    }
    if (environment.flag.flagTypes.disabledIfPaid.find(f => f === flag.name)) {
      return true;
    }
    if (environment.flag.flagTypes.priceFlags.find(f => f === flag.name) && entity.flag_delegate_paid) {
      return true;
    }
  }

  checkFlagHidden(flag: FlagIonic, entity: DelegateRegistration, session: SystemConnection): boolean {
    if (entity['flag_'+flag.name] === undefined || !flag.status || !session.user.flag_access.flag[flag.name]) {
      return true;
    }
    if (entity.referenced_orders) {
      const hasOpenOrder = entity.referenced_orders?.find((o) => o.state !== CommerceOrderStatus.invoiced);
      if (environment.flag.flagTypes.checkInOutFlags.find(f => f === flag.name) && hasOpenOrder) {
        return true;
      }
    }
  }

  getPageTitleandDesc(objKey: string) {
    let pageDescription = '';
    switch (objKey) {
      case 'badgeFlags':
        pageDescription = 'Check the flags to be automatically flagged when scanned with a code scanner on the badge page.';
        break;
      case 'priceFlags':
        pageDescription = 'Check the flags to enable or disable flags from being shown on the payment page.';
        break;
      default:
        pageDescription = 'Check the flags to be automatically flagged when scanned with a code scanner or enable or disable flags from being shown on the check in page.';
        break;
    }
    return pageDescription;
  }
}

