import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { format, parseISO } from 'date-fns';
import { ViewService } from './drupal7/drupal7-services.module';
import { SystemConnection } from './drupal7/public_api';

const TWILIO_ACCOUNT_SID = 'AC98a775b2f4b0de7d45b9ddaeea260cb5';
const TWILIO_AUTH_TOKEN = 'dff73f2ef4600888adc544880799d4a9';
const TWILIO_PHONE_NUMBER = '+16619464663'
const TWILIO_SLC_PHONE_NUMBER = '+16615797753'
// const client = new Twilio(TWILIO_ACCOUNT_SID, TWILIO_AUTH_TOKEN);

@Injectable({
  providedIn: 'root'
})
export class TwilioService {

    accountSid = TWILIO_ACCOUNT_SID;
    authToken = TWILIO_AUTH_TOKEN;
    fromNumber = TWILIO_SLC_PHONE_NUMBER;
    userSession: SystemConnection

    constructor(
      private viewService: ViewService,
      private http: HttpClient) {
      }
  

    getMessages(): Observable<any> {
      const headersCSRF = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.authToken
      };
      const options = {headers: new HttpHeaders(headersCSRF)};

      const url = `https://api.twilio.com/2010-04-01/Accounts/${this.accountSid}/Messages.json?From=%2B${this.fromNumber}`;

      return this.http.get(url, options).pipe(
      map(res => {
        console.log(res);
        return res;
      }),
      catchError(err => {
        console.log(err);
        return throwError(err);
      }));
    }

    async getPhoneNumbers() {

      const res = await this.viewService.getView('phone-numbers');

      console.log(res);
    }

  //   async get() {
  
  //     client.messages.list({ limit: 120, to: this.fromNumber }).then((messages) => {
  //       console.log(messages);
  //         const result = messages.reduce((accumulator, currentMessage) => {
  //             currentMessage['name'] = '';
              
  //             currentMessage['dateCreatedTimestamp'] = format(parseISO(currentMessage.dateCreated.toDateString()), 'MMMM DD, YYYY hh:mm A');
  //             const cleanNumbers = this.cleanPhoneNumbers({});
  
  //             const result = cleanNumbers.find(({ address }) => '+1' + address === currentMessage.from);
  //             if (result !== undefined) {
  //                 currentMessage['name'] = result.title;
  //                 currentMessage['order'] = result.order_id;
  //             }
  //             if (!accumulator[currentMessage.from]) {
  //                 accumulator[currentMessage.from] = currentMessage;
  //             }
  //             return accumulator;
  //         }, {});
  //         return result;
  //     });
  // }

    cleanPhoneNumbers(list) {
      list.map(x => {
          x.address = x.address.replace(/[() -.]/g, '');
          return x;
      });
      return list;
  }
  
}
