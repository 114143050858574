import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';
import { StorageService } from './storage/storage.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class KioskService {

  private status = new BehaviorSubject(false);

  constructor(private toastController: ToastController, private router: Router, private alertCtrl: AlertController, private storage: StorageService) {
    this.init();
  }

  async init() {
    const currentState = await this.storage.get('kioskStatus');
    if (currentState === null) {
      this.updateKioskStatus(false);
    } else {
      this.updateKioskStatus(currentState);
    }
  }

  onKioskChange(): Observable<boolean> {
    return this.status.asObservable();
  }

  async getCurrentKioskStatus(): Promise<boolean> {
    const status = await this.storage.get('kioskStatus');
    const state = status ? status : false;
    return state;
  }

  updateKioskStatus(status: boolean) {
    this.storage.set('kioskStatus', status);
    this.status.next(status);

    const connection = status === false ? 'disabled' : 'enabled';
    const toast = this.toastController.create({
      message: `Kiosk Mode ${connection}`,
      duration: 3000,
      position: 'bottom'
    });
    toast.then(res => res.present());
  }

  async setKioskPasscode() {
    const alert = await this.alertCtrl.create({
      header: 'Passcode',
      message: 'What passcode would you like to use?',
      buttons: [
        {
          text: 'Create Passcode',
          role: 'choose',
          handler: () => {
            this.setKioskPasscodeManual();
          }
        },
        {
          text: 'Use Master Passcode',
          role: 'master',
          handler: () => {
            this.storage.set('kioskPass', environment.appSettings.linkDevicePass);
            this.updateKioskStatus(true);
          }
        }
      ]
    });
    await alert.present();
  }

  async setKioskPasscodeManual() {
    const alert = await this.alertCtrl.create({
      header: 'Create a passcode',
      message: 'Enter a passcode to setup Kiosk Mode.',
      cssClass: 'scan-settings',
      inputs: [
        {
          name: 'pass',
          placeholder: 'Passcode',
          type: 'tel',
          id: 'pass',
          cssClass: 'masked-input',
          attributes: {
            maxlength: 6
          }
        },
        {
          name: 'pass2',
          placeholder: 'Confirm Passcode',
          type: 'tel',
          id: 'pass2',
          cssClass: 'masked-input',
          attributes: {
            maxlength: 6
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'scan-settings-button',
        },
        {
          text: 'Set Passcode',
          role: 'submit',
          cssClass: 'scan-settings-button',
          handler: async form => {
            console.log(form);
            if (!form.pass || !form.pass2) {
              const alert = await this.alertCtrl.create({
                header: 'Invalid',
                message: 'Please set a passcode',
                buttons: [{
                  text: 'OK',
                  role: 'ok',
                  handler: () => {
                    this.setKioskPasscode();
                  }
                }]
              });
              await alert.present(); 
            } else if (form.pass !== form.pass2) {
              const alert = await this.alertCtrl.create({
                header: 'Passcode mismatch',
                message: 'The passcodes do not match',
                buttons: [{
                  text: 'OK',
                  role: 'ok',
                  handler: () => {
                    this.setKioskPasscode();
                  }
                }]
              });
              await alert.present(); 
            } else if (form.pass === form.pass2) {
              this.storage.set('kioskPass', +form.pass);
              this.updateKioskStatus(true);
            }
          }
        }
      ]

    });
    await alert.present();
  }


  async exitKioskMode(pass: number) {
    const kioskPassword = await this.storage.get('kioskPass');
    if (pass === environment.appSettings.linkDevicePass || pass === kioskPassword) {
      this.updateKioskStatus(false);
      this.storage.remove('kioskPass');
      this.router.navigate(['/'], {replaceUrl: true});
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Password incorrect',
        message: 'The password you entered is incorrect',
        buttons: [{
          text: 'OK',
          role: 'cancel',
        }]
      });
      await alert.present();
    }

  }


}
